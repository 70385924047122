import styled from 'styled-components';
import { pureBlack } from 'styles/colors';

export const PromotionData = styled.div`
  padding: 25px 0 13px;
  margin: 0 0 0 18px;
`;

export const NoHistorySection = styled.h2`
  padding: 25px 0 13px;
  margin: 0 0 0 18px;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: ${pureBlack};
  padding-bottom: 10px;
`;
