const EmployeePermissions = {
  voyager_dedicated_space_breakeven_view: 'voyager_dedicated_space_breakeven_view',
  voyager_dedicated_space_breakeven_edit: 'voyager_dedicated_space_breakeven_edit',
  voyager_dedicated_space_breakeven_publish: 'voyager_dedicated_space_breakeven_publish',
  voyager_dedicated_space_price_view: 'voyager_dedicated_space_price_view',
  voyager_dedicated_space_price_edit: 'voyager_dedicated_space_price_edit',
  voyager_dedicated_space_price_publish: 'voyager_dedicated_space_price_publish',
  voyager_on_demand_price_view: 'voyager_on_demand_price_view',
  voyager_on_demand_price_edit: 'voyager_on_demand_price_edit',
  voyager_on_demand_price_publish: 'voyager_on_demand_price_publish',
  voyager_dedicated_space_discount_view: 'voyager_dedicated_space_discount_view',
  voyager_dedicated_space_discount_edit: 'voyager_dedicated_space_discount_edit',
  voyager_dedicated_space_discount_publish: 'voyager_dedicated_space_discount_publish',
  voyager_dedicated_space_discount_curve_manage: 'voyager_dedicated_space_discount_curve_manage',
  voyager_dedicated_space_discount_curve_apply: 'voyager_dedicated_space_discount_curve_apply',
  voyager_system_admin: 'voyager_system_admin',
  voyager_promotions_view: 'voyager_promotions_view',
  voyager_promotions_manage: 'voyager_promotions_manage',
};

export const EmployeeRoles = {
  voyager_dedicated_space_breakeven_user: 'voyager_dedicated_space_breakeven_user',
  voyager_dedicated_space_breakeven_administrator:
    'voyager_dedicated_space_breakeven_administrator',
  voyager_dedicated_space_price_user: 'voyager_dedicated_space_price_user',
  voyager_dedicated_space_price_administrator: 'voyager_dedicated_space_price_administrator',
  voyager_on_demand_price_user: 'voyager_on_demand_price_user',
  voyager_on_demand_price_administrator: 'voyager_on_demand_price_administrator',
  voyager_dedicated_space_discount_user: 'voyager_dedicated_space_discount_user',
  voyager_dedicated_space_discount_administrator: 'voyager_dedicated_space_discount_administrator',
  voyager_system_administrator: 'voyager_system_administrator',
  voyager_promotions_user: 'voyager_promotions_user',
  voyager_promotions_administrator: 'voyager_promotions_administrator',
};

export default EmployeePermissions;
