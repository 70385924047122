import * as React from 'react';
import { ReactElement } from 'react';
import { NavLink } from 'react-router-dom';
import cn from 'classnames';
import { Button, ButtonSize, ButtonTheme } from '@wework/ray2';
import { v4 as uuidV4 } from 'uuid';
import { trackAnalytics } from '../../utils/analytics/helpers';
import {
  AppDescription,
  ApplicationCard,
  AppTitle,
  SectionDescription,
} from './applicationCard.styled';
import { routerHistory } from '../../utils/store/configureStore';

interface ButtonInfo {
  label: string;
  route: string;
}

interface ItemButtonInfo {
  sectionDesc: string;
  buttons: Array<ButtonInfo>;
}

function ApplicationCardComponent(
  iconClass: string,
  disabled: boolean,
  title: string,
  subtitle: string,
  buttonSections: ItemButtonInfo[],
  description?: string,
): ReactElement {
  const getLinkButton = (buttonSectionsParam: ItemButtonInfo[]) => (
    <>
      {buttonSectionsParam.map(eachButtonSection => {
        const sectionBtns: ButtonInfo[] = eachButtonSection.buttons;
        const mainBtn: ButtonInfo = sectionBtns[0];
        const restBtns: ButtonInfo[] = sectionBtns.slice(1, sectionBtns.length);

        return (
          <div className="card-button" key={uuidV4()}>
            <SectionDescription className={cn({ 'align-desc': !eachButtonSection.sectionDesc })}>
              {eachButtonSection.sectionDesc}
            </SectionDescription>
            <NavLink
              to={{ pathname: mainBtn.route }}
              onClick={() =>
                trackAnalytics('Homepage Application Card Clicked', {
                  workflow: 'Homepage Navigation',
                  object_type: 'link',
                  object_name: iconClass,
                  object_value: mainBtn.route,
                })
              }
            >
              <Button size={ButtonSize.SMALL}>Manage</Button>
            </NavLink>
            {restBtns.length > 0 &&
              restBtns.map(addtnlButton => (
                <Button
                  key={addtnlButton.route}
                  className="card-additional-button"
                  size={ButtonSize.SMALL}
                  theme={ButtonTheme.OUTLINE}
                  onClick={() => reRouteHelper(addtnlButton)}
                >
                  {addtnlButton.label}
                </Button>
              ))}
          </div>
        );
      })}
    </>
  );

  const reRouteHelper = (attr: ButtonInfo) => {
    trackAnalytics('Homepage Application Card Clicked', {
      workflow: 'Homepage Navigation',
      object_type: 'link',
      object_name: iconClass,
      object_value: attr.route,
    });
    routerHistory.push({
      pathname: attr.route,
    });
  };

  return (
    <ApplicationCard className={cn(iconClass, { disabled })}>
      <AppTitle>{`${title} ${subtitle}`}</AppTitle>
      {description && <AppDescription>{description}</AppDescription>}
      {getLinkButton(buttonSections)}
    </ApplicationCard>
  );
}

export default ApplicationCardComponent;
