import * as React from 'react';
import { ReactElement } from 'react';
import { Dock } from 'react-dock';
import {
  ClosePanelButton,
  PanelBody,
  PanelFilterList,
  PanelHeader,
  PanelTitle,
  SidePanelWrapper,
} from '../../../../styles/app.styled';
import { PromotionFilterProps } from './promotionTableFilterBar';
import PromotionEnabledFilter from './promotionEnabledFilter';
import PromotionProductFilter from './promotionProductFilter';
import PromotionStartEndDateFilter from './promotionStartEndDateFilter';
import PromotionQuickFilter from './promotionQuickFilter';
import PromotionLocationFilter from './promotionsLocationFilter';

interface PromotionFilterSidePanelProps extends PromotionFilterProps {
  isOpen: boolean;
  close: () => void;
}

function PromotionFilterSidePanel(props: PromotionFilterSidePanelProps): ReactElement {
  return (
    <SidePanelWrapper>
      <Dock
        position="right"
        isVisible={props.isOpen}
        dimMode="none"
        fluid={false}
        size={400}
        zIndex={100}
      >
        <ClosePanelButton type="button" onClick={props.close} />
        <PanelHeader>
          <PanelTitle>Filters</PanelTitle>
        </PanelHeader>
        <PanelBody className={'no-footer'}>
          <PanelFilterList>
            <PromotionQuickFilter {...props} />
            <PromotionEnabledFilter {...props} />
            <PromotionStartEndDateFilter {...props} />
            <PromotionProductFilter {...props} />
            <PromotionLocationFilter {...props} />
          </PanelFilterList>
        </PanelBody>
      </Dock>
    </SidePanelWrapper>
  );
}

export default PromotionFilterSidePanel;
