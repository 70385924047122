import * as React from 'react';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import DropdownTreeSelect, { TreeNodeProps } from 'react-dropdown-tree-select';
import 'react-dropdown-tree-select/dist/styles.css';
import isUUID from 'validator/lib/isUUID';
import { useDispatch, useSelector } from 'react-redux';
import { difference, groupBy, isEqual } from 'lodash';
import { productsLoadingSelector, productsSelector } from '../../store/promotion.selector';
import { FETCH_PROMOTION_PRODUCTS } from '../../store/promotion.ducks';
import { isArrayEqual, toTitleCase } from '../../../../utils/helpers';
import { ChecklistWrapper } from './promotion.styled';
import { trackAnalytics } from '../../../../utils/analytics/helpers';

interface PromotionProductsChecklistProps {
  productLimitations: string[];
  addProductLimitations: (productIds: string[]) => void;
  removeProductLimitations: (productIds: string[]) => void;
  readOnly?: boolean;
  defaultAllSelected?: boolean;
}

function PromotionProductsChecklist({
  productLimitations,
  addProductLimitations,
  removeProductLimitations,
  readOnly = false,
  defaultAllSelected = false,
}: PromotionProductsChecklistProps): ReactElement {
  const dispatch = useDispatch();
  const fetchProducts = useCallback(() => dispatch({ type: FETCH_PROMOTION_PRODUCTS }), [dispatch]);

  const products = useSelector(productsSelector);
  const productsLoading = useSelector(productsLoadingSelector);

  const productsByBusinessLines = groupBy(products, 'businessLine');

  // TODO BC - figure out a way to remove productsChecklist and productIdsAndBuisnessLine
  const [productsChecklist, setProductsChecklist] = useState<TreeNodeProps[]>([]);
  const [productIdsAndBuisnessLine, setProductIdsAndBuisnessLine] = useState<any>({});

  const trackProductLimitationDropDown = () =>
    trackAnalytics(`Promotion - Product Limitation DropDown Clicked`, {
      workflow: `Promotion Product Limitation`,
      object_type: 'dropdown',
      object_name: `Product Limitation`,
    });

  useEffect(() => {
    let productIdsAndBusinessLineTemp = {};
    const newCheckList = Object.keys(productsByBusinessLines).map(businessLine => {
      const businessLineProducts = productsByBusinessLines[businessLine];
      const businessLineProductIds = businessLineProducts.map(product => product.id);
      const notSelectedProductIds = difference(businessLineProductIds, productLimitations);
      productIdsAndBusinessLineTemp = {
        ...productIdsAndBusinessLineTemp,
        [businessLine]: {
          businessLineProductIds: [...businessLineProductIds],
          notSelectedProductIds: [...notSelectedProductIds],
        },
      };
      const businessLineCheckbox = {
        label: toTitleCase(businessLine),
        value: businessLine,
        checked: notSelectedProductIds.length === 0,
        expanded: notSelectedProductIds.length === 0,
      };

      const children = [
        ...businessLineProducts.map(product => ({
          label: product.name,
          value: product.id,
          checked: productLimitations.includes(product.id),
        })),
      ];

      return {
        ...businessLineCheckbox,
        expanded: children.length !== 0,
        children,
      };
    });
    if (!isArrayEqual(newCheckList, productsChecklist)) {
      setProductsChecklist([...newCheckList]);
    }
    if (!isEqual(productIdsAndBusinessLineTemp, productIdsAndBuisnessLine)) {
      setProductIdsAndBuisnessLine({ ...productIdsAndBusinessLineTemp });
    }
  }, [
    productLimitations,
    products,
    productsByBusinessLines,
    productsChecklist,
    productIdsAndBuisnessLine,
  ]);

  const selectAllProducts = () => {
    if (defaultAllSelected) {
      addProductLimitations(products.map(product => product.id));
    }
  };

  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);
  useEffect(selectAllProducts, [products.length]);

  const onChange = (currentNode: TreeNodeProps) => {
    if (currentNode.checked) {
      const productIds = isUUID(currentNode.value)
        ? [currentNode.value]
        : [...productIdsAndBuisnessLine[currentNode.value]?.notSelectedProductIds];
      addProductLimitations(productIds);
    } else {
      const productIds = isUUID(currentNode.value)
        ? [currentNode.value]
        : [...productIdsAndBuisnessLine[currentNode.value]?.businessLineProductIds];
      removeProductLimitations(productIds);
    }
  };

  return productsLoading ? (
    <span>Loading products...</span>
  ) : (
    <ChecklistWrapper>
      <DropdownTreeSelect
        data={productsChecklist}
        onChange={onChange}
        readOnly={readOnly}
        clearSearchOnChange
        keepTreeOnSearch
        keepChildrenOnSearch
        showPartiallySelected
        texts={{
          placeholder: 'Search...',
        }}
        onFocus={trackProductLimitationDropDown}
      />
    </ChecklistWrapper>
  );
}

export default PromotionProductsChecklist;
