import { all, fork } from 'redux-saga/effects';
import { uploadFileSaga } from 'sharedStore/modules/uploadFile/uploadFile.saga';
import onDemandPricingItemSaga from 'app/pricing/onDemandPricing/store/modules/onDemand/onDemandPricing.saga';
import priceableItemSaga from '../../app/pricing/standardPricing/store/modules/priceableItems/priceableItems.saga';
import regionSaga from '../../sharedStore/modules/regions/regions.saga';
import marketSaga from '../../sharedStore/modules/markets/markets.saga';
import territorySaga from '../../sharedStore/modules/territories/territories.saga';
import locationSaga from '../../sharedStore/modules/locations/locations.saga';
import authSaga from '../../sharedStore/modules/auth/auth.saga';
import discountItemSaga from '../../app/discounts/store/modules/discountItems/discountItems.saga';
import publishPriceSaga from '../../app/pricing/standardPricing/store/modules/publishPrice/publishPrice.saga';
import standardPricingItemSaga from '../../app/pricing/standardPricing/store/modules/standardPricingItems/standardPricingItems.saga';
import breakevenItemSaga from '../../app/breakeven/store/modules/breakevenItems/breakevenItems.saga';
import { inventoryReconciliationSaga } from '../../app/adminPanel/store/modules/inventoryReconciliation/inventoryReconciliation.saga';
import { inventoryReconciliationByLocationSaga } from '../../app/adminPanel/store/modules/inventoryReconciliationByLocation/inventoryReconciliationByLocation.saga';
import locationReconciliationSaga from '../../app/adminPanel/store/modules/locationReconciliation/locationReconciliation.saga';
import resetBreakEvenSaga from '../../app/adminPanel/store/modules/resetBreakEven/resetBreakEven.saga';
import snowflakeSyncInitializationSaga from '../../app/adminPanel/store/modules/snowflakeSyncInitialization/snowflakeSyncInitialization.saga';
import spacemanPricesConsolidationSaga from '../../app/adminPanel/store/modules/spacemanPricesConsolidation/spacemanPricesConsolidation.saga';
import priceRePublishSaga from '../../app/adminPanel/store/modules/priceSyncAttempts/priceSyncAttempts.saga';
import discountRePublishSaga from '../../app/adminPanel/store/modules/discountSyncAttempts/discountSyncAttempts.saga';
import geoHierarchyReconciliationSaga from '../../app/adminPanel/store/modules/geoHierarchyReconciliation/geoHierarchyReconciliation.saga';
import { jobStatusSaga } from '../../app/adminPanel/store/modules/jobStatus/jobStatus.saga';
import actionItemSaga from '../../app/actionItems/store/modules/actionItems.saga';
import generateLinkSaga from '../../sharedStore/modules/generateLink/generateLink.saga';
import standardPricingFiltersSaga from '../../app/pricing/standardPricing/store/modules/standardPricingFilters/standardPricingFilters.saga';
import filtersSaga from '../../app/discounts/store/modules/filters/filters.saga';
import promotionItemSaga from '../../app/promotion/store/promotion.saga';

const rootSaga = function* mainSaga(): any {
  yield all([
    fork(regionSaga),
    fork(locationSaga),
    fork(marketSaga),
    fork(priceableItemSaga),
    fork(territorySaga),
    fork(authSaga),
    fork(discountItemSaga),
    fork(publishPriceSaga),
    fork(standardPricingItemSaga),
    fork(breakevenItemSaga),
    fork(uploadFileSaga),
    fork(inventoryReconciliationSaga),
    fork(inventoryReconciliationByLocationSaga),
    fork(locationReconciliationSaga),
    fork(geoHierarchyReconciliationSaga),
    fork(resetBreakEvenSaga),
    fork(snowflakeSyncInitializationSaga),
    fork(spacemanPricesConsolidationSaga),
    fork(priceRePublishSaga),
    fork(discountRePublishSaga),
    fork(onDemandPricingItemSaga),
    fork(jobStatusSaga),
    fork(actionItemSaga),
    fork(generateLinkSaga),
    fork(standardPricingFiltersSaga),
    fork(filtersSaga),
    fork(promotionItemSaga),
  ]);
};

export default rootSaga;
