import * as React from 'react';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { get, isEqual, isNil, isObject } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import { EditableCellStyled } from '../breakevenTableAGGrid.styled';
import {
  breakevenItemsSelectorStore,
  editModeSelectorBreakeven,
} from '../../../store/modules/breakevenItems/breakevenItems.selector';
import { errorHandlerActive } from '../../../../../utils/errorHandling/helpers';
import { CREATE_BREAKEVEN_WORKING_DATA } from '../../../store/modules/breakevenItems/breakevenItems.ducks';
import { typeMap } from '../../constants';
import { getBooleanValue } from '../../../../../utils/helpers';

function EditableCellCheckBoxRenderer(props: ICellRendererParams): ReactElement {
  // STATE VAR
  const [displayValue, setDisplayValue] = useState<string>();
  // Used for visual cue for binary checkbox value.
  const [defaultDisplay, setDefaultDisplay] = useState<string>();
  const [displayType, setDisplayType] = useState<string>('');

  const [editableFlag, setEditableFlag] = useState<boolean>(false);
  const [infoFlag, setInfoFlag] = useState<boolean>(false);
  const [overrideFlag, setOverrideFlag] = useState<boolean>(false);

  // STATE PROPS
  const editMode = useSelector(editModeSelectorBreakeven);
  const allItems = useSelector(breakevenItemsSelectorStore) ?? [];

  // DISPATCH PROPS
  const dispatch = useDispatch();
  const createBreakevenWorkingPrice = useCallback(
    (payload: any) => dispatch({ type: CREATE_BREAKEVEN_WORKING_DATA, payload }),
    [dispatch],
  );

  useEffect(() => {
    const displayVal = isObject(props.value) ? get(props.value, 'value', 'false') : props.value;

    setEditableFlag(props.data?.type === 'override');
    setDisplayValue(displayVal?.toString());

    if (isNil(displayVal)) {
      const currItemBreakeven = allItems?.find(
        previousItem => previousItem.location.id === props.data?.location?.id,
      );
      const defDisplay = get(currItemBreakeven?.current, props.colDef?.field ?? '', 'false');
      setDefaultDisplay(defDisplay?.toString());
    }

    const type = props.value?.type;
    setDisplayType(type ? get(typeMap, type, '-') : '-');
    setInfoFlag(props.data?.type === 'toBePublished');
  }, [props, allItems]);

  const cleanAndSetVal = (event: any) => {
    const field = props.colDef?.field ?? null;

    const newValue = event.target.checked.toString();
    if (!newValue) {
      return;
    }

    const prevDisplayValue = props.value;

    if (!isEqual(newValue, prevDisplayValue)) {
      // 1) Setting local display value.
      setDisplayValue(newValue);

      // 2) Set the BE with new data
      if (field !== null) {
        createBreakevenWorkingPrice({
          geoLocationUUID: props.data?.location?.id,
          workingBreakEvenOverrideInput: {
            [field]: newValue === 'true',
          },
        });
      } else {
        errorHandlerActive(new Error('Fatal error: coldDef field is empty.'));
      }
    }
  };

  useEffect(() => {
    setOverrideFlag(infoFlag && displayType !== 'No Change' && displayType !== '');
  }, [infoFlag, displayType]);

  const checkBoxValue = () =>
    isNil(displayValue) ? defaultDisplay === 'true' : displayValue === 'true';

  return editMode && editableFlag ? (
    <input type="checkbox" checked={checkBoxValue()} onChange={event => cleanAndSetVal(event)} />
  ) : (
    <EditableCellStyled>
      {!displayValue && infoFlag ? (
        <span className="info">No Change</span>
      ) : (
        <>
          <span className={cn({ override: overrideFlag })}>
            {isNil(displayValue) ? '' : getBooleanValue(displayValue === 'true')}
          </span>
          <span className="info">{infoFlag ? displayType : ''}</span>
        </>
      )}
    </EditableCellStyled>
  );
}

export default EditableCellCheckBoxRenderer;
