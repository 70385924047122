import { ColSpanParams, GetQuickFilterTextParams, GridOptions, RowNode } from 'ag-grid-community';
import { find } from 'lodash';
import store from 'index';
import { currentMultipleLocationDetailsSelector } from 'sharedStore/modules/locations/locations.selector';
import {
  dateAtLocationZoneToStringVF,
  getMainMenuItemsWithoutReset,
  getMaxPageInSkuGroup,
} from 'utils/helpers';
import tableCellPhysicalAttributes from './renderers/physicalAttributesRenderer';
import DiscountDetailsTrigger from './renderers/discountsDetailsTriggerRenderer';
import {
  avgAggregateFunc,
  discountCurveVG,
  discountPercentageVG,
  discountSteadyVG,
  discountValueVG,
  getCurrencyFormattedPrice,
  getlowestNetPricevalue,
  getRowId,
  isOccupiedVF,
  numberVF,
  numberVFCurrency,
  overrideDiscountCellClass,
  percentageVF,
} from './discountAGGrid.helpers';
import DiscountCellRenderer from './renderers/discountCellRender';
import OverridesScheduleRender from './renderers/overrideScheduleRender';
import EmptyTableComponentRenderer from '../../../../sharedComponents/tableComponent/tableEmpty';
import TableLoadingRenderer from '../../../../sharedComponents/tableComponent/tableLoadingRenderer';
import LocationDetailsRenderer from '../../../pricing/standardPricing/components/standardPricingView/standardPricingTable/renderers/locationDetailsRenderer';
import { Location } from '../../../../generated/voyager/graphql';

export const defaultColDef = {
  flex: 1,
  minWidth: 120,
  resizable: true,
  suppressMovable: true,
  menuTabs: ['generalMenuTab'],
  getQuickFilterText(params: GetQuickFilterTextParams): string {
    const field = params.colDef.field;
    // just search by the reservable name or location name in the grid
    if (field === 'officeName') {
      return params.value;
    } else if (field === 'location.id') {
      const state = store.getState();
      const locationDetails = currentMultipleLocationDetailsSelector(state);
      const location = find(locationDetails, ['id', params.value]) ?? ({} as Location);
      return location.name;
    }
    return '';
  },
};

export const columnDefs = (editPermission: boolean) => [
  {
    headerName: 'Overview',
    headerClass: 'bg-color-light',
    children: [
      {
        headerName: '',
        checkboxSelection: editPermission,
        headerCheckboxSelection: editPermission,
        headerCheckboxSelectionFilteredOnly: true,
        width: 40,
        minWidth: 40,
        maxWidth: 40,
        colId: 'rowSelector',
        suppressMenu: true,
        lockPosition: true,
        resizable: false,
      },
      {
        headerName: '',
        width: 30,
        maxWidth: 30,
        minWidth: 30,
        cellRenderer: DiscountDetailsTrigger,
        cellClass: 'rh-center no-padding',
        suppressMenu: true,
        resizable: false,
        lockPosition: true,
      },
      {
        headerName: '',
        minWidth: 40,
        width: 50,
        cellRenderer: OverridesScheduleRender,
        suppressMenu: true,
        lockPosition: true,
        resizable: false,
      },
      {
        field: 'location.id',
        rowGroup: true,
        filter: true,
        sortable: true,
        hide: true,
      },
      {
        headerName: 'Location',
        showRowGroup: 'location.id',
        cellRenderer: 'agGroupCellRenderer',
        cellRendererParams: {
          innerRenderer: LocationDetailsRenderer,
          suppressCount: true,
        },
        colSpan: (params: ColSpanParams): number => {
          const field = params?.node?.field;
          if (field === 'location.id') {
            return 2;
          }
          return 1;
        },
        minWidth: 200,
        width: 200,
        colId: 'locationName',
      },
      {
        field: 'sku',
        rowGroup: true,
        filter: true,
        sortable: true,
        sort: 'asc',
        comparator: (_: any, _2: any, skuGroup1: RowNode, skuGroup2: RowNode) =>
          getMaxPageInSkuGroup(skuGroup1) - getMaxPageInSkuGroup(skuGroup2),
        hide: true,
      },
      {
        headerName: 'Sku',
        showRowGroup: 'sku',
        cellRenderer: 'agGroupCellRenderer',
        minWidth: 110,
        width: 110,
        colId: 'sku',
      },
      {
        field: 'page',
        sort: 'asc',
        hide: true,
      },
      {
        headerName: 'Reservable Name',
        field: 'officeName',
        filter: true,
        sortable: true,
        minWidth: 140,
        width: 140,
      },
    ],
  },
  {
    headerName: 'Data',
    headerClass: 'bg-color-dark',
    children: [
      {
        headerName: 'Physical Attrib.',
        cellRenderer: tableCellPhysicalAttributes,
        minWidth: 110,
        width: 110,
        colId: 'physicalAttributes',
      },
      {
        // Need this for download grid multiple column physical attributes.
        cellRenderer: tableCellPhysicalAttributes,
        colId: 'physicalAttributes2',
        hide: true,
      },
      {
        headerName: 'Market Price',
        field: 'marketPrice',
        sortable: true,
        aggFunc: avgAggregateFunc,
        cellClass: 'background-gray',
        minWidth: 140,
        width: 140,
        headerTooltip: 'Market Price',
        valueFormatter: getCurrencyFormattedPrice,
      },
      {
        headerName: 'Lowest Net Price',
        field: 'lowestNetPrice',
        aggFunc: avgAggregateFunc,
        valueFormatter: getlowestNetPricevalue,
        minWidth: 140,
        width: 140,
        headerTooltip: 'Lowest Net Price',
      },
      {
        headerName: 'Location SKU Occupancy',
        field: 'locationSKUOccupancy',
        sortable: true,
        valueFormatter: percentageVF,
        aggFunc: avgAggregateFunc,
        cellClass: 'background-gray',
        minWidth: 150,
        width: 150,
        headerTooltip: 'Location SKU Occ.',
      },
      {
        headerName: 'Market SKU Occupancy',
        field: 'marketSKUOccupancy',
        sortable: true,
        valueFormatter: percentageVF,
        aggFunc: avgAggregateFunc,
        minWidth: 150,
        width: 150,
        headerTooltip: 'Market SKU Occ.',
      },
      {
        headerName: 'Occupied',
        field: 'isOccupied',
        valueFormatter: isOccupiedVF,
        cellClass: 'background-gray',
        minWidth: 110,
        width: 110,
      },
      {
        headerName: 'Months Vacant',
        field: 'monthsVacant',
        sortable: true,
        valueFormatter: numberVF,
        minWidth: 120,
        width: 120,
      },
      {
        headerName: 'Reserv. Gross Price',
        field: 'reservationGrossPrice',
        sortable: true,
        valueFormatter: numberVFCurrency,
        aggFunc: avgAggregateFunc,
        cellClass: 'background-gray',
        minWidth: 150,
        width: 150,
        headerTooltip: 'Reservation Gross Price',
      },
      {
        headerName: 'LAST PUBLISHED',
        field: 'lastPublished',
        sortable: true,
        minWidth: 140,
        width: 140,
        valueFormatter: dateAtLocationZoneToStringVF,
      },
      {
        headerName: 'EXP DATE',
        field: 'expDate',
        sortable: true,
        minWidth: 120,
        width: 120,
        valueFormatter: dateAtLocationZoneToStringVF,
      },
    ],
  },
  {
    headerName: 'Discount',
    headerClass: 'header-group bg-color-light',
    children: [
      // FIXED ALWAYS DISPLAYED COLUMNS
      // BASE discounts
      {
        headerName: 'Base Discount',
        headerClass: 'header-subgroup',
        children: [
          {
            headerName: 'M2M',
            headerClass: 'header-subgroup-child',
            sortable: true,
            field: 'm2MDiscount',
            valueGetter: discountPercentageVG,
            valueFormatter: percentageVF,
            aggFunc: avgAggregateFunc,
            cellClassRules: overrideDiscountCellClass,
            cellClass: 'background-gray',
            cellRenderer: DiscountCellRenderer,
            minWidth: 100,
            width: 100,
          },
          {
            headerName: '6M',
            headerClass: 'header-subgroup-child',
            sortable: true,
            field: 'sixMDiscount',
            valueGetter: discountPercentageVG,
            valueFormatter: percentageVF,
            aggFunc: avgAggregateFunc,
            cellClassRules: overrideDiscountCellClass,
            cellClass: 'background-gray',
            cellRenderer: DiscountCellRenderer,
            minWidth: 100,
            width: 100,
          },
          {
            headerName: '12M',
            headerClass: 'header-subgroup-child',
            sortable: true,
            field: 'twelveMDiscount',
            valueGetter: discountPercentageVG,
            valueFormatter: percentageVF,
            aggFunc: avgAggregateFunc,
            cellClassRules: overrideDiscountCellClass,
            cellClass: 'background-gray',
            cellRenderer: DiscountCellRenderer,
            minWidth: 100,
            width: 100,
          },
          {
            headerName: '24M',
            headerClass: 'header-subgroup-child',
            sortable: true,
            field: 'twentyFourMDiscount',
            valueGetter: discountPercentageVG,
            valueFormatter: percentageVF,
            aggFunc: avgAggregateFunc,
            cellClassRules: overrideDiscountCellClass,
            cellClass: 'background-gray',
            cellRenderer: DiscountCellRenderer,
            minWidth: 100,
            width: 100,
          },
          {
            headerName: '36M',
            headerClass: 'header-subgroup-child',
            sortable: true,
            field: 'thirtySixMDiscount',
            valueGetter: discountPercentageVG,
            valueFormatter: percentageVF,
            aggFunc: avgAggregateFunc,
            cellClassRules: overrideDiscountCellClass,
            cellClass: 'background-gray',
            cellRenderer: DiscountCellRenderer,
            minWidth: 100,
            width: 100,
          },
        ],
      },
      // HIDDEN COLUMNS
      // Discount value
      {
        headerName: 'Discounts Value',
        headerClass: 'header-subgroup',
        children: [
          {
            headerName: 'M2M',
            headerClass: 'header-subgroup-child',
            sortable: true,
            hide: true,
            field: 'm2MDiscount',
            valueGetter: discountValueVG,
            valueFormatter: numberVFCurrency,
            aggFunc: avgAggregateFunc,
            minWidth: 110,
            width: 110,
            colId: 'm2MDiscountDV',
          },
          {
            headerName: '6M',
            headerClass: 'header-subgroup-child',
            sortable: true,
            hide: true,
            field: 'sixMDiscount',
            valueGetter: discountValueVG,
            valueFormatter: numberVFCurrency,
            aggFunc: avgAggregateFunc,
            minWidth: 110,
            width: 110,
            colId: 'sixMDiscountDV',
          },
          {
            headerName: '12M',
            headerClass: 'header-subgroup-child',
            sortable: true,
            hide: true,
            field: 'twelveMDiscount',
            valueGetter: discountValueVG,
            valueFormatter: numberVFCurrency,
            aggFunc: avgAggregateFunc,
            minWidth: 110,
            width: 110,
            colId: 'twelveMDiscountDV',
          },
          {
            headerName: '24M',
            headerClass: 'header-subgroup-child',
            sortable: true,
            hide: true,
            field: 'twentyFourMDiscount',
            valueGetter: discountValueVG,
            valueFormatter: numberVFCurrency,
            aggFunc: avgAggregateFunc,
            minWidth: 110,
            width: 110,
            colId: 'twentyFourMDiscountDV',
          },
          {
            headerName: '36M',
            headerClass: 'header-subgroup-child',
            sortable: true,
            hide: true,
            field: 'thirtySixMDiscount',
            valueGetter: discountValueVG,
            valueFormatter: numberVFCurrency,
            aggFunc: avgAggregateFunc,
            minWidth: 110,
            width: 110,
            colId: 'thirtySixMDiscountDV',
          },
        ],
      },
      // Net Price: steady state
      {
        headerName: 'Net Price: Steady State',
        headerClass: 'header-subgroup',
        children: [
          {
            headerName: 'M2M',
            headerClass: 'header-subgroup-child',
            sortable: true,
            hide: true,
            field: 'm2MDiscount',
            valueGetter: discountSteadyVG,
            valueFormatter: numberVFCurrency,
            aggFunc: avgAggregateFunc,
            cellClass: 'background-gray',
            minWidth: 110,
            width: 110,
            colId: 'm2MDiscountNP',
          },
          {
            headerName: '6M',
            headerClass: 'header-subgroup-child',
            sortable: true,
            hide: true,
            field: 'sixMDiscount',
            valueGetter: discountSteadyVG,
            valueFormatter: numberVFCurrency,
            aggFunc: avgAggregateFunc,
            cellClass: 'background-gray',
            minWidth: 110,
            width: 110,
            colId: 'sixMDiscountNP',
          },
          {
            headerName: '12M',
            headerClass: 'header-subgroup-child',
            sortable: true,
            hide: true,
            field: 'twelveMDiscount',
            valueGetter: discountSteadyVG,
            valueFormatter: numberVFCurrency,
            aggFunc: avgAggregateFunc,
            cellClass: 'background-gray',
            minWidth: 110,
            width: 110,
            colId: 'twelveMDiscountNP',
          },
          {
            headerName: '24M',
            headerClass: 'header-subgroup-child',
            sortable: true,
            hide: true,
            field: 'twentyFourMDiscount',
            valueGetter: discountSteadyVG,
            valueFormatter: numberVFCurrency,
            aggFunc: avgAggregateFunc,
            cellClass: 'background-gray',
            minWidth: 110,
            width: 110,
            colId: 'twentyFourMDiscountNP',
          },
          {
            headerName: '36M',
            headerClass: 'header-subgroup-child',
            sortable: true,
            hide: true,
            field: 'thirtySixMDiscount',
            valueGetter: discountSteadyVG,
            valueFormatter: numberVFCurrency,
            aggFunc: avgAggregateFunc,
            cellClass: 'background-gray',
            minWidth: 110,
            width: 110,
            colId: 'thirtySixMDiscountNP',
          },
        ],
      },
      {
        headerName: 'Discount Curves',
        minWidth: 110,
        width: 110,
        field: 'curvesApplied',
        valueGetter: discountCurveVG,
        resizable: false,
      },
    ],
  },
];

export const gridOptions: GridOptions = {
  rowSelection: 'multiple',
  defaultColDef,
  suppressMaxRenderedRowRestriction: true,
  suppressAnimationFrame: true,
  suppressContextMenu: true,
  suppressCellFocus: true,
  suppressRowClickSelection: true,
  suppressRowHoverHighlight: true,
  suppressAggFuncInHeader: true,
  groupDisplayType: 'custom',
  rowHeight: 56,
  groupSelectsChildren: true,
  groupSelectsFiltered: true,
  suppressDragLeaveHidesColumns: true,
  getRowId,
  loadingOverlayComponent: TableLoadingRenderer,
  noRowsOverlayComponent: EmptyTableComponentRenderer,
  showOpenedGroup: true,
  groupDefaultExpanded: 2,
  getMainMenuItems: getMainMenuItemsWithoutReset,
  debounceVerticalScrollbar: true,
  suppressColumnVirtualisation: true,
};
