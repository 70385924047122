import { ValueGetterParams } from 'ag-grid-community';
import * as React from 'react';
import { ReactElement } from 'react';
import { HashLink } from 'react-router-hash-link';
import { trackAnalytics } from '../../../../../utils/analytics/helpers';
import ROUTES from '../../../../../utils/routes/routes';
import TextLink from '../../../../../sharedComponents/styledText/styledText';

const PromotionCodeNameRenderer = (params: ValueGetterParams): ReactElement => (
  <ul color="green">
    <li>
      {params.data.codes
        .slice(0, 2)
        .map((p: any) => p.code)
        .join(', ')}
    </li>
    {params.data.codes.length > 2 && (
      <li>
        <TextLink
          title={'Multiple codes exist - click to view all'}
          onClick={() =>
            trackAnalytics(`Promotion - View More Clicked`, {
              workflow: `Promotion - view more`,
              object_type: 'button',
              object_name: `View More`,
            })
          }
        >
          <HashLink
            to={`${ROUTES.PROMOTION_VIEW_PAGE}/${params.data.id}#promotion-codes`}
          >{`View More (${params.data.totalCodes})`}</HashLink>
        </TextLink>
      </li>
    )}
  </ul>
);

export default PromotionCodeNameRenderer;
