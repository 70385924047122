import * as React from 'react';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import { get, isEmpty, partition } from 'lodash';
import { withRequestedAuthz } from '@wework/we-auth-react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { useSelector } from 'react-redux';
import 'ag-grid-enterprise';
import { ColumnApi, GridApi, GridReadyEvent } from 'ag-grid-community';
import {
  currentLocationsByIdsLoadingState,
  currentMultipleLocationDetailsSelector,
} from '../../../../sharedStore/modules/locations/locations.selector';
import { columnDefs, gridOptions } from './discountsTableAGGrid.settings';
import {
  batchFetchStateSelectorDiscountStore,
  currentSelectedPriceableItemSelector,
  discountItemsOverrides,
  discountItemsSubsetSelectorStore,
  sidePanelParamSelector,
} from '../../store/modules/discountItems/discountItems.selector';
import './discountAGGridThemeOverrides.css';
import {
  filterHelperObjectArrayDiscount,
  transformDiscountItemToTableViewArr,
} from '../discountItems.helpers';
import { usePrevious } from '../../../pricing/standardPricing/components/helpers';
import TableFilterBarDiscounts from '../tableFilterBar/tableFilterBar';
import { filtersSelector } from '../../store/modules/filters/filters.selector';
import {
  GridDiscountPagedItem,
  Override,
  TableViewDiscountColumn,
  TableViewDiscountItem,
} from '../../store/entities/discountItems';
import DetailSidePanel from '../../../../sharedComponents/sidePanelComponent/detailSidePanel';
import {
  currentDisplayableItemSelector,
  detailPanelParamSelector,
} from '../../../../sharedStore/modules/detailPanel/detailPanel.selector';
import {
  ViewingCSVDownload,
  ViewingTableCount,
} from '../../../pricing/standardPricing/components/pricingTable/pricingTable.styled';
import DiscountsDetailSidePanel from '../sidePanelComponent/discountsDetailSidePanel/discountsDetailSidePanel';
import { trackAnalytics } from '../../../../utils/analytics/helpers';
import TextLink from '../../../../sharedComponents/styledText/styledText';
import { discountsTableDownloadConfig, hiddenColumns } from './discountsTableDownload.config';
import { AuthzProps } from '../../../../utils/constants';
import { AgGridWrapper, GridCustomWrapper, MarginWrapper } from '../../../../styles/app.styled';
import EmployeePermissions from '../../../../utils/store/permissions';
import { generateUrlLoadingSelector } from '../../../../sharedStore/modules/generateLink/generateLink.selector';

interface DiscountsTableProps extends AuthzProps {
  onSelectionChanged: any;
}

function DiscountsTable({
  onSelectionChanged,
  requestedPermissions,
}: DiscountsTableProps): ReactElement {
  const [tableViewDiscountItems, setTableViewDiscountItems] = useState<TableViewDiscountItem[]>([]);
  const [expandDiscountView, setExpandDiscountView] = useState<boolean>(false);
  const [displayedRowCount, setDisplayedRowCount] = useState<number>(0);

  // ag grid apis
  const [columnApi, setColumnApi] = useState<ColumnApi | null>(null);
  const [gridApi, setGridApi] = useState<GridApi | null>(null);

  const allDiscountItems = useSelector(discountItemsSubsetSelectorStore) ?? [];
  const [prevAllDiscountItems, setPrevAllDiscountItems] = useState<GridDiscountPagedItem[]>([]);
  const locationDetails = useSelector(currentMultipleLocationDetailsSelector);
  const filters = useSelector(filtersSelector);
  const detailsPanelVisible = useSelector(sidePanelParamSelector);
  const priceableItemId = useSelector(currentSelectedPriceableItemSelector);
  const itemDetailsPanelVisible = useSelector(detailPanelParamSelector);
  const currentDisplayableItem = useSelector(currentDisplayableItemSelector);
  const appliedOverrides = useSelector(discountItemsOverrides);
  const prevAppliedOverrides: Override[] = usePrevious(appliedOverrides) ?? [];
  const batchFetch = useSelector(batchFetchStateSelectorDiscountStore);
  const preBatchFetch = usePrevious(batchFetch);
  const generateUrlLoading = useSelector(generateUrlLoadingSelector);
  const locationsByIdsLoading = useSelector(currentLocationsByIdsLoadingState);

  const locationsOrPriceableItemsLoading =
    batchFetch || generateUrlLoading || locationsByIdsLoading;

  const columnDefinitions = useMemo(
    () =>
      columnDefs(
        get(requestedPermissions, EmployeePermissions.voyager_dedicated_space_discount_edit, false),
      ),
    [requestedPermissions],
  );

  const setItemsCounter = () => {
    if (gridApi) {
      let rowN = 0;
      gridApi.forEachNodeAfterFilter(node => {
        rowN += node.group ? 0 : 1;
      });
      setDisplayedRowCount(rowN);
    }
  };

  useEffect(() => {
    // Track only when there was batch fetch just ended. Prev batch fetch will be true and current will be false.
    if (!batchFetch && preBatchFetch === true) {
      trackAnalytics('Discount - Number of reservables shown', {
        workflow: 'Discount - show total number of reservables',
        object_type: 'Number',
        object_name: 'Reservables Compared',
        sumOfReservables: displayedRowCount,
      });
    }
  }, [batchFetch, preBatchFetch, displayedRowCount]);
  /**
   * Toggle for button click expanded discount view.
   */
  const expandDiscountViewToggle = () => {
    // eslint-disable-next-line no-unused-expressions
    columnApi?.setColumnsVisible(hiddenColumns, !expandDiscountView);
    setExpandDiscountView(!expandDiscountView);
  };

  /**
   * Transform data and load it in component if data change.
   */
  const transformDataHelper = () => {
    if (!gridApi) {
      return;
    }
    const changedItems = allDiscountItems.filter(o => !prevAllDiscountItems?.includes(o));
    const transformedData = transformDiscountItemToTableViewArr(changedItems, locationDetails);
    const filteredTransformedData = filterHelperObjectArrayDiscount(transformedData, filters);

    // Partition depending on item with the same id being present in the grid
    const updatedAndNewData = partition(filteredTransformedData, item =>
      prevAllDiscountItems?.find(previousItem => previousItem.id === item.id),
    );

    // updatedAndNewData[0] contains items that were already in the grid, but were changed;
    // updatedAndNewData[1] contains completely new items.
    const filteredUpdatedData = updatedAndNewData[0];
    const filteredNewData = updatedAndNewData[1];

    // After applying transactions refreshing the cells forcefully for changed nodes row.
    setTimeout(
      () =>
        gridApi.applyTransactionAsync(
          { add: filteredNewData, update: filteredUpdatedData },
          event =>
            // We have ValueGetter in the discount cells and we need to manually refresh
            // those cells to hide the future overrides.
            gridApi.refreshCells({
              suppressFlash: true,
              force: true,
              rowNodes: event.update,
            }),
        ),
      0,
    );

    setTableViewDiscountItems([
      ...tableViewDiscountItems.map(
        item => filteredUpdatedData.find(newItem => newItem.id === item.id) ?? item,
      ),
      ...filteredNewData,
    ]);
    setDisplayedRowCount(displayedRowCount + filteredNewData.length);
    setPrevAllDiscountItems(allDiscountItems);
  };
  useEffect(transformDataHelper, [allDiscountItems, gridApi]);

  /**
   * Applying filter in the grid
   */
  const filterDataHelper = () => {
    if (gridApi) {
      const transformedData = transformDiscountItemToTableViewArr(
        allDiscountItems,
        locationDetails,
      );
      const filteredData = filterHelperObjectArrayDiscount(transformedData, filters);
      gridApi.setRowData(filteredData);
      gridApi.deselectAll();
      setItemsCounter();
    }
  };
  useEffect(filterDataHelper, [filters]);

  /**
   * Calculate the data to update in the grid
   */
  const calculateNewDiscountColumnValue = (
    discountColumn: TableViewDiscountColumn,
    newOverride: Override,
    termTypeLabel: string,
  ) => {
    const newDiscountValue = newOverride.baseDiscounts?.find(
      d => d.termTypeLabel === termTypeLabel,
    )?.value;

    return newDiscountValue !== undefined
      ? {
          ...discountColumn,
          base: newDiscountValue / 100,
          isBaseDiscountOverridden: true,
        }
      : discountColumn;
  };

  /**
   * Updates the grid whenever there is a new override
   */
  // TODO: BC when publish is successful and clearing the overrides data after re-fetch the grid is not refreshing
  //  due to this logic. Modify this and remove the refresh page in saga of publish discount override.
  const updateOverridesGrid = () => {
    if (!gridApi) {
      return;
    }

    const deletedOverrides = prevAppliedOverrides.filter(
      previousOverride => !appliedOverrides.find(override => override.id === previousOverride.id),
    );
    const changedItems = allDiscountItems.filter(
      item =>
        !!appliedOverrides.find(o => o.id === item.id) ||
        !!deletedOverrides.find(o => o.id === item.id),
    );

    // Resetting rowNode data to original.
    // Avoids confusion in batch update as we need to reset the previous override data.
    const transformedData = transformDiscountItemToTableViewArr(changedItems, locationDetails).map(
      item => {
        const newOverride = appliedOverrides.find(o => o.id === item.id);

        // apply all new override changes in the grid and refresh deleted overrides from the original data
        return newOverride
          ? {
              ...item,
              m2MDiscount: calculateNewDiscountColumnValue(item.m2MDiscount, newOverride, 'M2M'),
              sixMDiscount: calculateNewDiscountColumnValue(item.sixMDiscount, newOverride, '6M'),
              twelveMDiscount: calculateNewDiscountColumnValue(
                item.twelveMDiscount,
                newOverride,
                '12M',
              ),
              twentyFourMDiscount: calculateNewDiscountColumnValue(
                item.twentyFourMDiscount,
                newOverride,
                '24M',
              ),
              thirtySixMDiscount: calculateNewDiscountColumnValue(
                item.thirtySixMDiscount,
                newOverride,
                '36M',
              ),
            }
          : item;
      },
    );

    setTimeout(() => gridApi.applyTransactionAsync({ update: transformedData }), 0);
  };
  useEffect(updateOverridesGrid, [appliedOverrides, gridApi, allDiscountItems]);

  /**
   * If allItems is empty then show NoRowsOverlay component
   */
  useEffect(() => {
    if (gridApi && !locationsOrPriceableItemsLoading && isEmpty(allDiscountItems)) {
      gridApi.showNoRowsOverlay();
    }
  });

  /**
   * OnGridReady is an AgGrid event that receives a param with access to the api.
   * @param params Parameter passed from AG grid.
   */
  const onGridReady = (params: GridReadyEvent): void => {
    setColumnApi(params?.columnApi);
    setGridApi(params?.api);
  };

  return (
    <>
      <TableFilterBarDiscounts
        expandDiscountViewToggle={expandDiscountViewToggle}
        expandDiscountView={expandDiscountView}
        agGridApi={gridApi}
      />
      <GridCustomWrapper>
        <MarginWrapper>
          <ViewingTableCount>
            <p>
              {locationsOrPriceableItemsLoading
                ? 'Loading...'
                : `Viewing ${displayedRowCount} Reservables`}
            </p>
          </ViewingTableCount>
          <ViewingCSVDownload>
            {locationsOrPriceableItemsLoading ? (
              ''
            ) : (
              <TextLink
                onClick={() =>
                  gridApi?.exportDataAsCsv(
                    discountsTableDownloadConfig(locationDetails, allDiscountItems),
                  )
                }
              >
                Download Grid
              </TextLink>
            )}
          </ViewingCSVDownload>
        </MarginWrapper>
        <AgGridWrapper className="ag-theme-alpine discounts-grid">
          <AgGridReact
            gridOptions={gridOptions}
            columnDefs={columnDefinitions}
            onFilterChanged={setItemsCounter}
            onGridReady={onGridReady}
            onSelectionChanged={onSelectionChanged}
          />
        </AgGridWrapper>
      </GridCustomWrapper>
      <DetailSidePanel
        isVisible={itemDetailsPanelVisible}
        currentDisplayableItem={currentDisplayableItem}
      />
      {priceableItemId && (
        <DiscountsDetailSidePanel
          isVisible={detailsPanelVisible}
          priceableItemId={priceableItemId}
        />
      )}
    </>
  );
}

export default withRequestedAuthz<DiscountsTableProps>({
  permissions: [EmployeePermissions.voyager_dedicated_space_discount_edit],
})(DiscountsTable);
