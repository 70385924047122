import { RowNode } from 'ag-grid-community';
import { PageInfo } from '../../../../generated/voyager/graphql';

export interface PriceSyncAttemptSubset {
  isLoading: boolean;
  allPriceSyncAttempt: PriceSyncAttempt[];
  priceSyncAttemptFilter: PriceSyncAttemptFilter;
  selectedNodes: RowNode[];
  pageInfo: PageInfo;
}

export interface PriceSyncAttempt {
  id: string;
  priceUuid: string;
  locationUuid: string;
  currencyIsoCode: string;
  reservableUuid: string;
  physicalType: string;
  productType: string;
  listPrice: string;
  scheduledFor: string;
  status: string;
  retryCount: string;
  lastMessage: string;
  createdAt: string;
  updatedAt: string;
}

export interface PriceSyncAttemptFilter {
  priceUuid: string[] | null;
  reservableUuid: string[] | null;
  status: string[] | null;
  startCreatedAt: string | null;
  endCreatedAt: string | null;
  startScheduledFor: string | null;
  endScheduledFor: string | null;
}

export const defaultFilter: PriceSyncAttemptFilter = {
  startCreatedAt: null,
  endCreatedAt: null,
  startScheduledFor: null,
  endScheduledFor: null,
  status: null,
  priceUuid: null,
  reservableUuid: null,
};

export const defaultPageInfo: PageInfo = {
  page: 0,
  size: 15,
};
