import * as React from 'react';
import { ReactElement, useState } from 'react';
import cn from 'classnames';
import { difference } from 'lodash';
import { useSelector } from 'react-redux';
import {
  ActiveFilterValues,
  FilterBody,
  FilterHeader,
  FilterItemOption,
  PanelFilterItem,
} from '../../../../styles/app.styled';
import { PromotionFilterProps } from './promotionTableFilterBar';
import PromotionLocationsChecklist from '../shared/promotionLocationsChecklist';
import { geoHierarchiesSelector } from '../../store/promotion.selector';
import { trackAnalytics } from '../../../../utils/analytics/helpers';

function PromotionLocationFilter({ filter, setFilter }: PromotionFilterProps): ReactElement {
  const [accordionOpen, setAccordionOpen] = useState<boolean>(true);

  const geoHierarchies = useSelector(geoHierarchiesSelector);

  const trackLocationFilterPromotions = (geoHierarchyIds: string[]) =>
    trackAnalytics(`Promotion - Location Filter Promotion used`, {
      workflow: `Promotion - Filter promotion`,
      object_type: 'dropdown',
      object_name: `Location Filter promotion`,
      object_value: geoHierarchyIds,
    });

  return (
    <PanelFilterItem className={cn({ open: accordionOpen, closed: !accordionOpen })}>
      <FilterHeader className="filter-header">
        <button type="button" onClick={() => setAccordionOpen(!accordionOpen)}>
          <span>{'Location Limitations'}</span>
          <i className="toggle-accordion-icon" />
        </button>
        {!accordionOpen && (
          <ActiveFilterValues>
            {filter.geoHierarchyLimitations
              ?.map(id => geoHierarchies.find(geoHierarchy => geoHierarchy.id === id)?.name ?? id)
              ?.join(', ')}
          </ActiveFilterValues>
        )}
      </FilterHeader>
      <FilterBody className="filter-body">
        <FilterItemOption>
          <PromotionLocationsChecklist
            geoHierarchyLimitations={filter.geoHierarchyLimitations ?? []}
            removeGeoHierarchyLimitations={geoHierarchyIds => {
              const newGeoHierarchyLimitations = difference(
                filter.geoHierarchyLimitations,
                geoHierarchyIds,
              );
              setFilter({
                ...filter,
                geoHierarchyLimitations:
                  newGeoHierarchyLimitations.length > 0 ? newGeoHierarchyLimitations : undefined,
              });
              trackLocationFilterPromotions(newGeoHierarchyLimitations);
            }}
            addGeoHierarchyLimitations={geoHierarchyIds => {
              const geoHierarchyLimitations = (filter.geoHierarchyLimitations || []).concat(
                geoHierarchyIds,
              );
              setFilter({
                ...filter,
                geoHierarchyLimitations,
              });
              trackLocationFilterPromotions(geoHierarchyLimitations);
            }}
          />
        </FilterItemOption>
      </FilterBody>
    </PanelFilterItem>
  );
}

export default PromotionLocationFilter;
