import * as React from 'react';
import { ReactElement, useCallback } from 'react';
import { Confirm } from 'semantic-ui-react';
import { useDispatch } from 'react-redux';
import { UPDATE_PROMOTIONS_ENABLED } from 'app/promotion/store/promotion.ducks';
import {
  MutationUpdatePromotionsEnabledArgs,
  PromotionsEnabledInput,
} from 'generated/voyager/graphql';
import { DataWithCallback } from 'utils/sharedTypes';
import { trackAnalytics } from '../../../../utils/analytics/helpers';

interface ConfirmProps {
  closeModal: () => void;
  isOpen: boolean;
  promotionsEnabledInput: PromotionsEnabledInput;
}

function PromotionsEnabledConfirm(props: ConfirmProps): ReactElement {
  const dispatch = useDispatch();
  const updatePromotionsEnabled = useCallback(
    (payload: DataWithCallback<MutationUpdatePromotionsEnabledArgs>) =>
      dispatch({ type: UPDATE_PROMOTIONS_ENABLED, payload }),
    [dispatch],
  );

  const trackEnableTogglePromotionChanged = () =>
    trackAnalytics(`Promotion - Enable Toggle Changed`, {
      workflow: `Promotion - Enable Toggle Changed`,
      object_type: 'toggle',
      object_name: `Enable Toggle`,
    });

  const onConfirm = () => {
    updatePromotionsEnabled({
      data: {
        promotionsEnabledInput: props.promotionsEnabledInput,
      },
      successCallback: props.closeModal,
    });
  };

  return (
    <Confirm
      open={props.isOpen}
      onCancel={props.closeModal}
      onConfirm={() => {
        onConfirm();
        trackEnableTogglePromotionChanged();
      }}
      cancelButton={{
        content: 'Cancel',
        className: 'cancel-confirmation',
      }}
      closeIcon
      confirmButton={{
        content: 'Confirm',
      }}
      header="Change Promotion Status"
      content={
        <div>
          <p>Are you sure you want to change status of promotion?</p>
        </div>
      }
    />
  );
}

export default PromotionsEnabledConfirm;
