import { get, isEmpty, uniqBy } from 'lodash';
import { Action, handleActions } from 'redux-actions';
import { createRDXConstant } from '../../../utils/store/store.constants';
import { PromotionItemsSubset, PromotionTableInput } from './entities/promotionItem';
import initialState from '../../../utils/store/initialState';
import { promotionItemsSubsetSelector } from './promotion.selector';
import {
  GeoHierarchy,
  Product,
  Promotion,
  PromotionsQuery,
} from '../../../generated/voyager/graphql';
import { promotionCodesToTableInput, promotionToTableInput } from './promotions.helper';

export const FETCH_PROMOTION_ITEMS = createRDXConstant('FETCH_PROMOTION_ITEMS');
export const FETCH_PROMOTION_ITEMS_SUCCESS = createRDXConstant('FETCH_PROMOTION_ITEMS_SUCCESS');

export const FETCH_PROMOTION_BY_ID = createRDXConstant('FETCH_PROMOTION_BY_ID');
export const FETCH_PROMOTION_BY_ID_SUCCESS = createRDXConstant('FETCH_PROMOTION_BY_ID_SUCCESS');
export const FETCH_PROMOTION_WITH_CHANGELOG = createRDXConstant('FETCH_PROMOTION_WITH_CHANGELOG');
export const FETCH_PROMOTION_WITH_CHANGELOG_SUCCESS = createRDXConstant(
  'FETCH_PROMOTION_WITH_CHANGELOG_SUCCESS',
);
export const SET_SELECTED_PROMOTION = createRDXConstant('SET_SELECTED_PROMOTION');

export const FETCH_PROMOTION_PRODUCTS = createRDXConstant('FETCH_PROMOTION_PRODUCTS');
export const FETCH_PROMOTION_PRODUCTS_SUCCESS = createRDXConstant(
  'FETCH_PROMOTION_PRODUCTS_SUCCESS',
);

export const FETCH_PROMOTION_LOCATIONS = createRDXConstant('FETCH_PROMOTION_LOCATIONS');
export const FETCH_PROMOTION_LOCATIONS_SUCCESS = createRDXConstant(
  'FETCH_PROMOTION_LOCATIONS_SUCCESS',
);

export const CREATE_PROMOTION = createRDXConstant('CREATE_PROMOTION');
export const CREATE_PROMOTION_SUCCESS = createRDXConstant('CREATE_PROMOTION_SUCCESS');
export const UPDATE_PROMOTION = createRDXConstant('UPDATE_PROMOTION');
export const UPDATE_PROMOTION_SUCCESS = createRDXConstant('UPDATE_PROMOTION_SUCCESS');
export const UPDATE_PROMOTIONS_ENABLED = createRDXConstant('UPDATE_PROMOTIONS_ENABLED');
export const UPDATE_PROMOTIONS_ENABLED_SUCCESS = createRDXConstant(
  'UPDATE_PROMOTIONS_ENABLED_SUCCESS',
);

export const SET_PROMOTION_WITH_CHANGELOG = createRDXConstant('SET_PROMOTION_WITH_CHANGELOG');

export const promotionItemsReducer = handleActions<PromotionItemsSubset, any>(
  {
    [FETCH_PROMOTION_ITEMS]: (state: PromotionItemsSubset) => ({
      ...state,
      fetchAllPromotionsLoading: true,
    }),

    [FETCH_PROMOTION_ITEMS_SUCCESS]: (
      state: PromotionItemsSubset,
      action: Action<{ data: PromotionsQuery }>,
    ) => {
      let newPromotionItems = get(action, 'data.promotions', [] as Promotion[]);
      newPromotionItems = uniqBy(newPromotionItems, 'id');
      return {
        ...state,
        fetchAllPromotionsLoading: false,
        allPromotionItems: newPromotionItems,
      };
    },
    [FETCH_PROMOTION_PRODUCTS]: (state: PromotionItemsSubset) => ({
      ...state,
      productsLoading: true,
    }),
    [FETCH_PROMOTION_PRODUCTS_SUCCESS]: (
      state: PromotionItemsSubset,
      action: Action<Product[] | undefined>,
    ) => ({
      ...state,
      products: action.payload || [],
      productsLoading: false,
    }),
    [FETCH_PROMOTION_LOCATIONS]: (state: PromotionItemsSubset) => ({
      ...state,
      geoHierarchiesLoading: true,
    }),
    [FETCH_PROMOTION_LOCATIONS_SUCCESS]: (
      state: PromotionItemsSubset,
      action: Action<GeoHierarchy[] | undefined>,
    ) => ({
      ...state,
      geoHierarchies: action.payload || [],
      geoHierarchiesLoading: false,
    }),
    [FETCH_PROMOTION_BY_ID]: (state: PromotionItemsSubset) => ({
      ...state,
      fetchPromotionLoading: true,
    }),
    [FETCH_PROMOTION_BY_ID_SUCCESS]: (
      state: PromotionItemsSubset,
      action: Action<{ promotion: Promotion; appendCodes?: boolean } | undefined>,
    ) => {
      if (!action.payload) {
        return {
          ...state,
          fetchPromotionLoading: false,
          noMoreCodes: true,
        };
      }

      const { promotion, appendCodes } = action.payload;
      const selectedPromotion =
        state.selectedPromotion && state.selectedPromotion.id === promotion.id
          ? {
              ...state.selectedPromotion,
              manualCodeCreation: appendCodes
                ? [
                    ...(state.selectedPromotion.manualCodeCreation ?? []),
                    ...promotionCodesToTableInput(promotion.codes),
                  ]
                : promotionCodesToTableInput(promotion.codes),
            }
          : promotionToTableInput(promotion);
      return {
        ...state,
        selectedPromotion,
        fetchPromotionLoading: false,
        noMoreCodes: promotion.codes.length === 0,
      };
    },
    [FETCH_PROMOTION_WITH_CHANGELOG]: (state: PromotionItemsSubset) => ({
      ...state,
    }),
    [FETCH_PROMOTION_WITH_CHANGELOG_SUCCESS]: (
      state: PromotionItemsSubset,
      action: Action<Promotion | undefined>,
    ) => ({
      ...state,
      promotionWithChangelog: action.payload,
    }),
    [SET_SELECTED_PROMOTION]: (
      state: PromotionItemsSubset,
      action: Action<PromotionTableInput | undefined>,
    ) => ({
      ...state,
      selectedPromotion: action.payload,
    }),
    [CREATE_PROMOTION]: (state: PromotionItemsSubset) => ({
      ...state,
      savePromotionLoading: true,
    }),
    [CREATE_PROMOTION_SUCCESS]: (
      state: PromotionItemsSubset,
      action: Action<Promotion | undefined>,
    ) => {
      const allPromotionItems = promotionItemsSubsetSelector(state) ?? [];

      return {
        ...state,
        savePromotionLoading: false,
        allPromotionItems: action.payload
          ? [action.payload, ...allPromotionItems]
          : allPromotionItems,
      };
    },
    [UPDATE_PROMOTION]: (state: PromotionItemsSubset) => ({
      ...state,
      savePromotionLoading: true,
    }),
    [UPDATE_PROMOTION_SUCCESS]: (
      state: PromotionItemsSubset,
      action: Action<Promotion | undefined>,
    ) => {
      const allPromotionItems = promotionItemsSubsetSelector(state) ?? [];
      const updatedPromotion = action.payload;

      return {
        ...state,
        savePromotionLoading: false,
        allPromotionItems: updatedPromotion
          ? allPromotionItems.map(item =>
              item.id === updatedPromotion.id ? updatedPromotion : item,
            )
          : allPromotionItems,
      };
    },
    [UPDATE_PROMOTIONS_ENABLED]: (state: PromotionItemsSubset) => ({
      ...state,
    }),
    [UPDATE_PROMOTIONS_ENABLED_SUCCESS]: (
      state: PromotionItemsSubset,
      action: Action<Promotion[] | undefined>,
    ) => {
      const allPromotionItems = promotionItemsSubsetSelector(state) ?? [];
      const updatedPromotions = action.payload;

      return {
        ...state,
        allPromotionItems: isEmpty(updatedPromotions)
          ? allPromotionItems
          : allPromotionItems.map(
              item => updatedPromotions?.find(promo => promo.id === item.id) ?? item,
            ),
      };
    },
    [SET_PROMOTION_WITH_CHANGELOG]: (
      state: PromotionItemsSubset,
      action: Action<Promotion | undefined>,
    ) => ({
      ...state,
      promotionWithChangelog: action.payload,
    }),
  },
  initialState.promotionItems,
);
