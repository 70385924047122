import { GridOptions } from 'ag-grid-community';
import { ColDef, ColGroupDef, ValueGetterParams } from 'ag-grid-community/dist/lib/entities/colDef';
import { GetRowIdFunc } from 'ag-grid-community/dist/lib/entities/gridOptions';
import { GetRowIdParams } from 'ag-grid-community/dist/lib/entities/iCallbackParams';
import EmptyTableComponentRenderer from 'sharedComponents/tableComponent/tableEmpty';
import TableLoadingRenderer from 'sharedComponents/tableComponent/tableLoadingRenderer';
import OnDemandPriceCellRenderer from './renderers/onDemandPriceCellRenderer';
import PriceCellRenderer from './renderers/priceCellRenderer';
import OpenPriceHistoryRenderer from './renderers/onDemandOpenPriceHistoryRender';
import { dateAtLocationZoneToStringVF } from '../../../../utils/helpers';
import { PriceHierarchy, Product } from '../../../../generated/voyager/graphql';

export const defaultColDef = {
  flex: 1,
  resizable: true,
  suppressMovable: true,
  suppressMenu: true,
};

const getRowId: GetRowIdFunc = (data: GetRowIdParams): string => data.data?.id;

const dynamicColumnDefs = (products: Product[]): (ColDef | ColGroupDef)[] =>
  products.map((product, idx) => ({
    headerName: product.name,
    headerClass: 'bg-color-dark',
    children: [
      {
        field: 'Current Price',
        colId: `${product.id}|Current Prices`,
        cellClass: `${(idx + 1) % 2 > 0 ? 'background-gray' : ''}`,
        minWidth: 220,
        maxWidth: 220,
        cellRenderer: PriceCellRenderer,
      },
      {
        field: 'Working Price',
        cellClass: `${(idx + 1) % 2 > 0 ? 'background-gray' : ''}`,
        colId: `${product.id}|Working Prices`,
        minWidth: 220,
        maxWidth: 220,
        cellRenderer: OnDemandPriceCellRenderer,
      },
      {
        field: 'Last Published',
        cellClass: `${(idx + 1) % 2 > 0 ? 'background-gray' : ''}`,
        colId: `${product.id}|Published At`,
        minWidth: 125,
        maxWidth: 125,
        valueGetter: (params: ValueGetterParams) =>
          params.data.currentPrices.find((price: PriceHierarchy) => price.productId === product.id)
            ?.validFrom ?? null,
        valueFormatter: dateAtLocationZoneToStringVF,
      },
    ],
  }));

export const columnDefs = (
  onDemandProducts: Product[],
  editPermissions: boolean,
): (ColDef | ColGroupDef)[] => [
  {
    headerName: 'Overview',
    headerClass: 'bg-color-light',
    children: [
      {
        colId: 'selectionCheckbox',
        headerName: '',
        checkboxSelection: editPermissions,
        headerCheckboxSelection: editPermissions,
        headerCheckboxSelectionFilteredOnly: true,
        minWidth: 40,
        maxWidth: 40,
        suppressMenu: true,
        resizable: false,
        lockPosition: true,
      },
      {
        cellRenderer: OpenPriceHistoryRenderer,
        maxWidth: 60,
        minWidth: 50,
        cellClass: 'rh-center no-padding',
        suppressMenu: true,
        resizable: false,
        lockPosition: true,
      },
      // Single Group Column (Custom)
      {
        minWidth: 300,
        // group column name
        headerName: 'Location',
        colId: 'name',
        // use the group cell render provided by the grid
        cellRenderer: 'agGroupCellRenderer',
        // informs the grid to display row groups under this column
        showRowGroup: true,
        valueGetter: params => params?.node?.data.name,
        cellRendererParams: {
          suppressCount: true,
        },
      },
      {
        headerName: 'timeZone',
        colId: 'timeZone',
        hide: true,
        valueGetter: params => params?.node?.data.timeZone,
      },
      {
        headerName: 'currencyIsoCode',
        colId: 'currencyIsoCode',
        hide: true,
        valueGetter: params => params?.node?.data.currencyIsoCode,
      },
    ],
  },
  ...dynamicColumnDefs(onDemandProducts),
];

// There is a issue here if we don't use the rememberGroupStateWhenNewData option
// but this option is deprecated.
export const gridOptions: GridOptions = {
  defaultColDef,
  rowHeight: 56,
  treeData: true,
  getRowId,
  rowSelection: 'multiple',
  loadingOverlayComponent: TableLoadingRenderer,
  noRowsOverlayComponent: EmptyTableComponentRenderer,
  suppressMaxRenderedRowRestriction: true,
  suppressAnimationFrame: true,
  suppressContextMenu: true,
  suppressCellFocus: true,
  suppressRowClickSelection: true,
  suppressRowHoverHighlight: false,
  suppressAggFuncInHeader: true,
  suppressDragLeaveHidesColumns: true,
  groupDisplayType: 'custom',
  showOpenedGroup: true,
  // rememberGroupStateWhenNewData: true,
  suppressScrollOnNewData: true,
  debounceVerticalScrollbar: true,
  suppressColumnVirtualisation: true,
};
