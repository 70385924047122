import * as React from 'react';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import DropdownTreeSelect, { TreeNodeProps } from 'react-dropdown-tree-select';
import 'react-dropdown-tree-select/dist/styles.css';
import { useDispatch, useSelector } from 'react-redux';
import { buildCheckbox, findNode, getChildrenArray } from 'app/promotion/store/promotions.helper';
import {
  geoHierarchiesLoadingSelector,
  geoHierarchiesSelector,
} from '../../store/promotion.selector';
import { FETCH_PROMOTION_LOCATIONS } from '../../store/promotion.ducks';
import { isArrayEqual } from '../../../../utils/helpers';
import { ChecklistWrapper } from './promotion.styled';
import { trackAnalytics } from '../../../../utils/analytics/helpers';

interface PromotionLocationsChecklistProps {
  geoHierarchyLimitations: string[];
  addGeoHierarchyLimitations: (geoHierarchyIds: string[]) => void;
  removeGeoHierarchyLimitations: (geoHierarchyIds: string[]) => void;
  readOnly?: boolean;
}

function PromotionLocationsChecklist({
  geoHierarchyLimitations,
  addGeoHierarchyLimitations,
  removeGeoHierarchyLimitations,
  readOnly = false,
}: PromotionLocationsChecklistProps): ReactElement {
  const dispatch = useDispatch();
  const fetchGeoHierarchies = useCallback(
    () => dispatch({ type: FETCH_PROMOTION_LOCATIONS }),
    [dispatch],
  );

  const geoHierarchies = useSelector(geoHierarchiesSelector);
  const geoHierarchiesLoading = useSelector(geoHierarchiesLoadingSelector);

  const [geoHierarchiesChecklist, setGeoHierarchiesChecklist] = useState<TreeNodeProps[]>([]);

  const trackLocationLimitationDropDown = () =>
    trackAnalytics(`Promotion - Location Limitation DropDown Clicked`, {
      workflow: `Promotion Location Limitation`,
      object_type: 'dropdown',
      object_name: `Location Limitation`,
    });

  useEffect(() => {
    const newCheckList = geoHierarchies
      .filter(geoHierarchy => geoHierarchy.parentId === null)
      .map(root => buildCheckbox(geoHierarchies, root, geoHierarchyLimitations));

    if (!isArrayEqual(newCheckList, geoHierarchiesChecklist)) {
      setGeoHierarchiesChecklist([...newCheckList]);
    }
  }, [geoHierarchyLimitations, geoHierarchies, geoHierarchiesChecklist]);

  useEffect(() => {
    fetchGeoHierarchies();
  }, [fetchGeoHierarchies]);

  const onChange = (checkedNode: TreeNodeProps) => {
    const currentNode = findNode(checkedNode.value, geoHierarchiesChecklist);
    if (!currentNode) {
      return;
    }

    if (checkedNode.checked) {
      addGeoHierarchyLimitations(getChildrenArray(currentNode, true));
    } else {
      removeGeoHierarchyLimitations(getChildrenArray(currentNode));
    }
  };

  return geoHierarchiesLoading ? (
    <span>Loading locations...</span>
  ) : (
    <ChecklistWrapper>
      <DropdownTreeSelect
        data={geoHierarchiesChecklist}
        onChange={onChange}
        readOnly={readOnly}
        clearSearchOnChange
        keepTreeOnSearch
        keepChildrenOnSearch
        showPartiallySelected
        texts={{
          placeholder: 'Search...',
        }}
        onFocus={trackLocationLimitationDropDown}
      />
    </ChecklistWrapper>
  );
}

export default PromotionLocationsChecklist;
