import React, { ReactElement, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, IconName } from '@wework/ray2';
import { push } from 'connected-react-router';
import { toast } from 'react-toastify';
import { isEmpty } from 'lodash';
import { pluralize } from 'apollo/lib/utils';
import { ActionsBox, BottomPanelWrapper } from '../../../../styles/sharedBottomPanel.styled';
import ROUTES from '../../../../utils/routes/routes';
import {
  fetchPromotionLoadingSelector,
  savePromotionLoadingSelector,
  selectedPromotionSelector,
} from '../../store/promotion.selector';
import { trackAnalytics } from '../../../../utils/analytics/helpers';
import { capitalizeFunc } from '../../../pricing/standardPricing/components/helpers';
import {
  getNumberOfCodesToCreate,
  getNumberOfCodesToProcess,
  getNumberOfCodesToUpdate,
  LARGE_NUMBER_OF_CODES,
  sanitizePromotionInput,
  validatePromotionInput,
} from '../../store/promotions.helper';
import { routerHistory } from '../../../../utils/store/configureStore';
import { DataWithCallback } from '../../../../utils/sharedTypes';
import {
  MutationCreatePromotionArgs,
  MutationUpdatePromotionArgs,
} from '../../../../generated/voyager/graphql';
import { CREATE_PROMOTION, UPDATE_PROMOTION } from '../../store/promotion.ducks';
import InfoTooltip from '../../../../sharedComponents/tooltip/infoTooltip';

interface BottomPanelPromotionActionProps {
  mode: 'create' | 'update';
}

function BottomPanelPromotionAction({ mode }: BottomPanelPromotionActionProps): ReactElement {
  const selectedPromotion = useSelector(selectedPromotionSelector);
  const isLoading = useSelector(fetchPromotionLoadingSelector);
  const isSaving = useSelector(savePromotionLoadingSelector);

  const btnName = mode === 'create' ? 'Add' : 'Update';

  const dispatch = useDispatch();

  const createPromotion = useCallback(
    (payload: DataWithCallback<MutationCreatePromotionArgs>) =>
      dispatch({ type: CREATE_PROMOTION, payload }),
    [dispatch],
  );
  const updatePromotion = useCallback(
    (payload: DataWithCallback<MutationUpdatePromotionArgs>) =>
      dispatch({ type: UPDATE_PROMOTION, payload }),
    [dispatch],
  );

  const validateAndCreatePromotion = useCallback(() => {
    if (!selectedPromotion) {
      return;
    }

    const validationErrors = validatePromotionInput(selectedPromotion, false);

    if (isEmpty(validationErrors)) {
      createPromotion({
        data: {
          promotionInput: sanitizePromotionInput(selectedPromotion),
        },
        successCallback: () => {
          routerHistory.push({
            pathname: ROUTES.PROMOTION_TABLEVIEW,
          });
        },
      });
    } else {
      toast.error(`Cannot create promotion: ${validationErrors.join(', ')}`);
    }
  }, [createPromotion, selectedPromotion]);

  const validateAndUpdatePromotion = useCallback(() => {
    if (!selectedPromotion) {
      return;
    }

    const validationErrors = validatePromotionInput(selectedPromotion, true);

    if (isEmpty(validationErrors)) {
      updatePromotion({
        data: {
          id: selectedPromotion?.id,
          promotionInput: sanitizePromotionInput(selectedPromotion),
        },
        successCallback: () => {
          routerHistory.push({
            pathname: ROUTES.PROMOTION_TABLEVIEW,
          });
        },
      });
    } else {
      toast.error(`Cannot update promotion: ${validationErrors.join(', ')}`);
    }
  }, [selectedPromotion, updatePromotion]);

  const trackBtmPanelPromotionClick = (action: string, type: string) =>
    trackAnalytics(`Promotion - ${action} ${type} Button Click`, {
      workflow: `Promotion ${action}`,
      object_type: 'button',
      object_name: `${type}`,
    });

  const redirectToPromotions = useCallback(
    () => dispatch(push(ROUTES.PROMOTION_TABLEVIEW)),
    [dispatch],
  );

  return (
    <BottomPanelWrapper className="publish-panel">
      <ActionsBox />
      <ActionsBox className="action-box-right">
        {selectedPromotion && (
          <span>{`${pluralize(
            getNumberOfCodesToCreate(selectedPromotion),
            'new code',
          )} will be created`}</span>
        )}
        {mode === 'update' && selectedPromotion && (
          <span>{`, ${pluralize(
            getNumberOfCodesToUpdate(selectedPromotion),
            'code',
          )} will be updated`}</span>
        )}
        <span>
          {selectedPromotion &&
            getNumberOfCodesToProcess(selectedPromotion) >= LARGE_NUMBER_OF_CODES && (
              <InfoTooltip
                iconName={IconName.ALERT_CIRCLE}
                popupTitle={'Large number of codes to process'}
                popupContent={
                  <>
                    <div>
                      The promotion will be processed in background and the changes might take some
                      time to show up in the app (usually up to a minute depending on # of codes).
                    </div>
                    <div>You will receive an email notification when the process is finished.</div>
                  </>
                }
              />
            )}
        </span>
        <Button
          theme={'outline'}
          size={'medium'}
          className={'text-negative border-negative ml-2xs mr-2xs'}
          onClick={() => {
            redirectToPromotions();
            trackBtmPanelPromotionClick(capitalizeFunc(mode), 'Cancel');
          }}
          loading={isSaving || isLoading}
        >
          Cancel
        </Button>
        <Button
          theme={'fill'}
          size={'medium'}
          onClick={() => {
            if (mode === 'create') {
              validateAndCreatePromotion();
            } else {
              validateAndUpdatePromotion();
            }
            trackBtmPanelPromotionClick(capitalizeFunc(mode), btnName);
          }}
          loading={isSaving || isLoading}
        >
          {btnName}
        </Button>
      </ActionsBox>
    </BottomPanelWrapper>
  );
}

export default BottomPanelPromotionAction;
