import styled from 'styled-components';
import { pureBlack, strongerBlue } from '../../styles/colors';

export const ApplicationCard = styled.div`
  position: relative;
  padding: 24px 24px;
  border-radius: 4px;
  border: 1px solid #e1e4ea;
  width: 368px;
  height: 250px;
  &.disabled {
    &:hover {
      border-color: ${strongerBlue};
      > h2 {
        color: ${strongerBlue};
      }
      > .app-icon {
        background-color: ${strongerBlue};
      }
    }
    &::before {
      content: '';
      background: rgba(255, 255, 255, 0.75);
      position: absolute;
      top: -12px;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 2;
    }
  }
  > .card-button {
    margin-top: 10px;
    bottom: 24px;
    .card-additional-button {
      margin-left: 6px;
    }
  }
`;
export const AppTitle = styled.h2`
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: ${pureBlack};
  padding-bottom: 10px;
`;
export const AppDescription = styled.p`
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  color: #535f78;
  margin-bottom: 40px;
`;

export const SectionDescription = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #535f78;
  padding: 15px 0 5px 0;
  &.align-desc {
    padding-top: 31px;
  }
`;
export const AppSubHeading = styled.h2`
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: ${pureBlack};
  margin: 0 0 24px 40px;
  &:not(:first-child) {
    margin-top: 10px;
  }
`;
