import { ValueGetterParams } from 'ag-grid-community';
import * as React from 'react';
import { ReactElement } from 'react';
import { HashLink } from 'react-router-hash-link';
import TextLink from '../../../../../sharedComponents/styledText/styledText';
import { trackAnalytics } from '../../../../../utils/analytics/helpers';
import ROUTES from '../../../../../utils/routes/routes';

const PromotionUsageRenderer = (params: ValueGetterParams): ReactElement => {
  // Case 1: When there is no data.
  if (!params.data) {
    return <></>;
  }

  // Case 2: When there is more than one code.
  // Case 2.1: For promotion codes created in bulk max usages is always the same.
  if (params.data.codes.length > 1) {
    return (
      <>
        <TextLink
          className={'no-margin'}
          title={'Usages may vary between codes'}
          onClick={() =>
            trackAnalytics(`Promotion - View More Clicked`, {
              workflow: `Promotion - view more`,
              object_type: 'button',
              object_name: `View More`,
            })
          }
        >
          <HashLink to={`${ROUTES.PROMOTION_VIEW_PAGE}/${params.data.id}#promotion-codes`}>
            View More
          </HashLink>
        </TextLink>
        {params.data.isBulkPromotion && ` / ${params.data.codes[0].maxUsages ?? '-'}`}
      </>
    );
  }

  // Case 3: When there is only one promo code.
  // Remember there will definitely be at least one code.
  const { usages, maxUsages } = params.data.codes[0];
  return <>{`${usages ?? '-'} / ${maxUsages ?? '-'}`}</>;
};

export default PromotionUsageRenderer;
