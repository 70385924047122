import { ICellRendererParams } from 'ag-grid-community';
import React, { ReactElement } from 'react';
import { get, isEmpty } from 'lodash';
import { Popup } from 'semantic-ui-react';
import { v4 as uuidV4 } from 'uuid';
import {
  ChangelogList,
  ChangelogTitle,
  CustomTableBodyCellCalendar,
} from '../discountAGGrid.styled';
import { ChangelogListItem } from '../../../../pricing/standardPricing/components/pricingTable/pricingTable.styled';
import { roundToAtMost2Digit } from '../../../../pricing/standardPricing/components/helpers';
import { formatDate, toTitleCase } from '../../../../../utils/helpers';
import { trackAnalytics } from '../../../../../utils/analytics/helpers';

function DiscountCellRenderer(props: ICellRendererParams): ReactElement {
  const termName = props.colDef?.field;

  const futureBaseDiscountData = get(props, `data.${termName}.futureBaseDiscount`);
  const futureOverrideDiscountData = get(props, `data.${termName}.futureOverride`);

  function trackChangelog() {
    trackAnalytics('Discount - Changelog Clicks', {
      workflow: 'Discount Show Changelog',
      object_type: 'icon',
      object_name: 'Changelog',
    });
  }

  const scheduledOverrideDiscount = () => (
    <ChangelogListItem key={uuidV4()}>
      {roundToAtMost2Digit((futureOverrideDiscountData?.base ?? 0) * 100)}% on{' '}
      {formatDate(futureOverrideDiscountData?.publishedDate, props?.data?.timeZone)} by{' '}
      {`${toTitleCase(futureOverrideDiscountData?.createdBy)}`}
    </ChangelogListItem>
  );

  const scheduledBaseDiscount = () => (
    <ChangelogListItem key={uuidV4()}>
      {roundToAtMost2Digit((futureBaseDiscountData?.base ?? 0) * 100)}% on{' '}
      {formatDate(futureBaseDiscountData?.publishedDate, props?.data?.timeZone)} by{' '}
      {`${toTitleCase(futureBaseDiscountData?.createdBy)}`}
    </ChangelogListItem>
  );

  return (
    <CustomTableBodyCellCalendar className="single-line">
      {props.valueFormatted}
      {!isEmpty(futureBaseDiscountData) || !isEmpty(futureOverrideDiscountData) ? (
        <Popup
          basic
          className="changelog-popup"
          trigger={
            <button
              className="scheduled-discount-trigger"
              type="button"
              onClick={() => trackChangelog()}
            />
          }
          on={'click'}
          content={
            <Popup.Content>
              {!isEmpty(futureBaseDiscountData) ? (
                <>
                  <ChangelogTitle>Scheduled Base Discount Change</ChangelogTitle>
                  <ChangelogList>{scheduledBaseDiscount()}</ChangelogList>
                </>
              ) : (
                ''
              )}

              {!isEmpty(futureOverrideDiscountData) ? (
                <>
                  <ChangelogTitle>Scheduled Override Change</ChangelogTitle>
                  <ChangelogList>{scheduledOverrideDiscount()}</ChangelogList>
                </>
              ) : (
                ''
              )}
            </Popup.Content>
          }
        />
      ) : (
        <></>
      )}
    </CustomTableBodyCellCalendar>
  );
}

export default DiscountCellRenderer;
