import * as React from 'react';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { withRequestedAuthz } from '@wework/we-auth-react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { useDispatch, useSelector } from 'react-redux';
import 'ag-grid-enterprise';
import {
  Column,
  ColumnApi,
  GridApi,
  GridReadyEvent,
  RowNode,
  SelectionChangedEvent,
} from 'ag-grid-community';
import { get, includes, isEmpty, isEqual, map, partition } from 'lodash';
import { standardPricingFilterHelperObjectArrayDiscount, usePrevious } from '../../helpers';
import { columnDefs, gridOptions } from './standardPricingTableAGGrid.settings';
import {
  standardPricingBatchFetchStoreSelector,
  standardPricingEditModeSelector,
  standardPricingItemsSubsetSelectorStore,
  standardPricingListPriceModeSelector,
  standardPricingSelectedNodesSelector,
} from '../../../store/modules/standardPricingItems/standardPricingItems.selector';
import {
  currentLocationsByIdsLoadingState,
  currentMultipleLocationDetailsSelector,
} from '../../../../../../sharedStore/modules/locations/locations.selector';
import DetailSidePanel from '../../../../../../sharedComponents/sidePanelComponent/detailSidePanel';
import {
  currentDisplayableItemSelector,
  detailPanelParamSelector,
} from '../../../../../../sharedStore/modules/detailPanel/detailPanel.selector';
import './pricingAGGridThemeOverrides.css';
import { ViewingCSVDownload, ViewingTableCount } from '../../pricingTable/pricingTable.styled';
import StandardPricingTableFilterBar from '../standardPricingTableFilterBar/standardPricingTableFilterBar';
import { transformStandardPriceItemsToTableView } from './standardPricingTable.helper';
import {
  DISABLE_EDIT_MODE_STANDARD_PRICING,
  ENABLE_EDIT_MODE_STANDARD_PRICING,
  SET_SELECTED_NODES_STANDARD_PRICING,
} from '../../../store/modules/standardPricingItems/standardPricingItems.ducks';
import { standardPricingFiltersSelector } from '../../../store/modules/standardPricingFilters/standardPricingFilters.selector';
import standardPricingTableDownloadConfig from './standardPricingTableDownload.config';
import { AuthzProps } from '../../../../../../utils/constants';
import {
  AgGridWrapper,
  GridCustomWrapper,
  MarginWrapper,
} from '../../../../../../styles/app.styled';
import EmployeePermissions from '../../../../../../utils/store/permissions';
import TextLink from '../../../../../../sharedComponents/styledText/styledText';
import { StandardPricingPagedItem } from '../../../store/entities/standardPricingItems';
import { generateUrlLoadingSelector } from '../../../../../../sharedStore/modules/generateLink/generateLink.selector';

function PureStandardPricingTable({ requestedPermissions }: AuthzProps): ReactElement {
  // ag grid apis
  const [gridApi, setGridApi] = useState<GridApi>();
  const [columnApi, setColumnApi] = useState<ColumnApi>();
  const [displayedRowCount, setDisplayedRowCount] = useState<number>(0);

  const locationDetails = useSelector(currentMultipleLocationDetailsSelector);
  const itemDetailsPanelVisible = useSelector(detailPanelParamSelector);
  const currentDisplayableItem = useSelector(currentDisplayableItemSelector);
  const editMode = useSelector(standardPricingEditModeSelector);
  const filters = useSelector(standardPricingFiltersSelector);
  const allItems = useSelector(standardPricingItemsSubsetSelectorStore) ?? [];
  const selectedNodes = useSelector(standardPricingSelectedNodesSelector);
  const batchFetch = useSelector(standardPricingBatchFetchStoreSelector);
  const generateUrlLoading = useSelector(generateUrlLoadingSelector);
  const locationsByIdsLoading = useSelector(currentLocationsByIdsLoadingState);
  const isListPriceMode = useSelector(standardPricingListPriceModeSelector);
  const previousIsListPriceMode = usePrevious(isListPriceMode);
  const previousPriceableItems: StandardPricingPagedItem[] = usePrevious(allItems);
  const prevSelectedNodes = usePrevious(selectedNodes);

  const locationsOrPriceableItemsLoading =
    batchFetch || generateUrlLoading || locationsByIdsLoading;

  // Dispatch
  const dispatch = useDispatch();
  const enableEditModeStandardPricingView = useCallback(
    () => dispatch({ type: ENABLE_EDIT_MODE_STANDARD_PRICING }),
    [dispatch],
  );
  const disableEditModeStandardPricingView = useCallback(
    () => dispatch({ type: DISABLE_EDIT_MODE_STANDARD_PRICING }),
    [dispatch],
  );
  const setSelectedNodesStandardPricingView = useCallback(
    (payload: RowNode[]) => dispatch({ type: SET_SELECTED_NODES_STANDARD_PRICING, payload }),
    [dispatch],
  );

  const columnDefinitions = useMemo(
    () =>
      columnDefs(
        get(requestedPermissions, EmployeePermissions.voyager_dedicated_space_price_edit, false),
      ),
    [requestedPermissions],
  );

  const setItemsCounter = () => {
    if (gridApi) {
      let rowN = 0;
      gridApi.forEachNodeAfterFilter(node => {
        rowN += node.group ? 0 : 1;
      });
      setDisplayedRowCount(rowN);
    }
  };

  useEffect(() => {
    if (isListPriceMode && gridApi) {
      gridApi.deselectAll();
    }
  }, [isListPriceMode, gridApi]);

  /**
   * Transform data and load it in component if data change.
   */
  const transformDataHelper = () => {
    // Adding data to grid transactional if we get new elements from BE.
    if (!gridApi || !columnApi) {
      return;
    }

    const changedItems = allItems.filter(item => !previousPriceableItems?.includes(item));
    const transformedData = transformStandardPriceItemsToTableView(changedItems, locationDetails);
    const filteredData = standardPricingFilterHelperObjectArrayDiscount(transformedData, filters);

    // Partition depending on item with the same id being present in the grid
    const updatedAndNewData = partition(filteredData, item =>
      previousPriceableItems?.find(previousItem => previousItem.id === item.id),
    );

    // updatedAndNewData[0] contains items that were already in the grid, but were changed;
    // updatedAndNewData[1] contains completely new items.
    const filteredUpdatedData = updatedAndNewData[0];
    const filteredNewData = updatedAndNewData[1];

    setTimeout(
      () => gridApi.applyTransactionAsync({ add: filteredNewData, update: filteredUpdatedData }),
      0,
    );
    const allColumns = columnApi.getAllColumns();
    const allColumnIds: string[] =
      allColumns && allColumns.length ? allColumns.map((column: Column) => column.getColId()) : [];

    columnApi.autoSizeColumns(allColumnIds.slice(4), true);

    if (!isEmpty(allItems)) {
      setDisplayedRowCount(displayedRowCount + filteredNewData.length);
    }
  };
  useEffect(transformDataHelper, [allItems, gridApi, columnApi]);

  /**
   *  If allItems is empty then show NoRowsOverlay component
   */
  useEffect(() => {
    let noRowsOverlayTimer: any;
    if (gridApi && !locationsOrPriceableItemsLoading && isEmpty(allItems)) {
      noRowsOverlayTimer = setTimeout(() => gridApi.showNoRowsOverlay(), 0);
    }
    return () => noRowsOverlayTimer && clearTimeout(noRowsOverlayTimer);
  });

  /**
   * OnGridReady is an AgGrid event that receives a param with access to the api.
   * @param params Parameter passed from AG grid.
   */
  const onGridReady = (params: GridReadyEvent): void => {
    setGridApi(params?.api);
    setColumnApi(params?.columnApi);
  };

  // On row selection changes.
  const onSelectionChanged = (event: SelectionChangedEvent) => {
    const tempSelectedNodes = event.api.getSelectedNodes();

    if (!isEqual(selectedNodes, tempSelectedNodes)) {
      setSelectedNodesStandardPricingView(tempSelectedNodes);
    }

    if (tempSelectedNodes && tempSelectedNodes.length) {
      if (!editMode) {
        // Only enabling if edit mode is off and there are selected Nodes.
        event.api.ensureColumnVisible('peakDiscount');
        enableEditModeStandardPricingView();
      }
    } else if (editMode) {
      // Only disabling if edit mode is on.
      disableEditModeStandardPricingView();
    }
  };

  /**
   * Applying filter in the grid
   */
  const filterDataHelper = () => {
    if (gridApi) {
      const transformedItems = transformStandardPriceItemsToTableView(allItems, locationDetails);
      const filteredData = standardPricingFilterHelperObjectArrayDiscount(
        transformedItems,
        filters,
      );
      gridApi.setRowData(filteredData);
      gridApi.deselectAll();
      disableEditModeStandardPricingView();
      setItemsCounter();
    }
  };
  useEffect(filterDataHelper, [filters]);

  /**
   * Selecting Nodes if selected nodes exist
   */
  useEffect(() => {
    if (isEmpty(selectedNodes) && !isEmpty(prevSelectedNodes) && gridApi) {
      gridApi.deselectAll();
    } else if (gridApi && selectedNodes.length && !locationsOrPriceableItemsLoading) {
      const idArr = map(selectedNodes, 'id');
      gridApi.forEachNode(rowNode => {
        if (includes(idArr, rowNode.id)) {
          rowNode.setSelected(true);
        }
      });
    }
  }, [selectedNodes, prevSelectedNodes, gridApi, locationsOrPriceableItemsLoading]);

  const onComponentStateChanged = useCallback(() => {
    if (previousIsListPriceMode !== isListPriceMode && gridApi) {
      gridApi.refreshHeader();
      gridApi.refreshCells({
        suppressFlash: true,
        force: true,
        columns: ['selectionCheckbox'],
      });
    }
  }, [gridApi, isListPriceMode, previousIsListPriceMode]);

  return (
    <>
      <StandardPricingTableFilterBar agGridApi={gridApi} agColumnApi={columnApi} />
      <GridCustomWrapper>
        <MarginWrapper>
          <ViewingTableCount>
            <p className="loading-status">
              {locationsOrPriceableItemsLoading
                ? 'Loading...'
                : `Viewing ${displayedRowCount} Reservables`}
            </p>
          </ViewingTableCount>
          {locationsOrPriceableItemsLoading ? (
            ''
          ) : (
            <ViewingCSVDownload>
              <TextLink
                onClick={() =>
                  gridApi?.exportDataAsCsv(standardPricingTableDownloadConfig(locationDetails))
                }
              >
                Download Grid
              </TextLink>
            </ViewingCSVDownload>
          )}
        </MarginWrapper>
        <AgGridWrapper className="ag-theme-alpine standard-pricing-grid">
          <AgGridReact
            gridOptions={gridOptions}
            columnDefs={columnDefinitions}
            onGridReady={onGridReady}
            onSelectionChanged={onSelectionChanged}
            context={{ isListPriceMode }}
            onFilterChanged={setItemsCounter}
            onComponentStateChanged={onComponentStateChanged}
          />
        </AgGridWrapper>
        <DetailSidePanel
          isVisible={itemDetailsPanelVisible}
          currentDisplayableItem={currentDisplayableItem}
        />
      </GridCustomWrapper>
    </>
  );
}

export default withRequestedAuthz<AuthzProps>({
  permissions: [EmployeePermissions.voyager_dedicated_space_price_edit],
})(PureStandardPricingTable);
