import * as React from 'react';
import { ReactElement } from 'react';
import { TextField } from '@wework/ray2';
import TimezonePicker from 'react-timezone';
import { InputMaybe, PromotionBulkCodeInput } from '../../../../generated/voyager/graphql';
import {
  ExampleCodeField,
  CodePreviewBox,
  PromoPageRow,
  PromoPageRowWrapper,
  PromoPageSubTitle,
} from './promotionAction.styled';
import { validateInputNumberValue } from '../../../../utils/helpers';
import { trackAnalytics } from '../../../../utils/analytics/helpers';
import PromotionCodeDateRange from '../shared/promotionCodeDateRange';
import { generateCodePreview } from '../../store/promotions.helper';

interface BulkPromotionCodeProps {
  setBulkCodeCreation: (bulkCodeCreation: InputMaybe<Partial<PromotionBulkCodeInput>>) => void;
  bulkCodeCreation: InputMaybe<Partial<PromotionBulkCodeInput>>;
  mode: 'create' | 'update';
}

function PromotionBulkCodes({
  setBulkCodeCreation,
  bulkCodeCreation,
  mode,
}: BulkPromotionCodeProps): ReactElement {
  const trackTimeZoneSelectorModify = (timeZone: string) =>
    trackAnalytics(`Promotion - Track Time Zone Modify`, {
      workflow: `Promotion Time Zone Modify`,
      object_type: 'dropdown',
      object_name: `Time Zone picker`,
      object_value: timeZone,
    });

  return (
    <PromoPageRowWrapper>
      <PromoPageRow>
        <CodePreviewBox>
          <PromoPageSubTitle>Code Preview:</PromoPageSubTitle>
          <ExampleCodeField>
            {bulkCodeCreation?.generatedPartLength ? (
              `${bulkCodeCreation?.prefix?.toUpperCase() ?? ''}${generateCodePreview(
                bulkCodeCreation?.generatedPartLength,
              )}${bulkCodeCreation?.suffix?.toUpperCase() ?? ''}`
            ) : (
              <i>Please enter Code Length to preview</i>
            )}
          </ExampleCodeField>
        </CodePreviewBox>
        <div>
          <PromoPageSubTitle>{`No. of Codes${mode === 'create' ? '*' : ''}:`}</PromoPageSubTitle>
          <TextField
            type="number"
            value={bulkCodeCreation?.codeAmount ?? ''}
            onChange={event =>
              validateInputNumberValue(
                event,
                'codeAmount',
                {
                  wholeNumberValues: {
                    fields: ['codeAmount'],
                  },
                },
                value =>
                  setBulkCodeCreation({ ...bulkCodeCreation, codeAmount: value || undefined }),
              )
            }
            placeholder={mode === 'create' ? 'Number of codes' : 'Number of new codes to generate'}
          />
        </div>
      </PromoPageRow>
      <PromoPageRow>
        <div>
          <PromoPageSubTitle>Max Usages:</PromoPageSubTitle>
          <TextField
            type="number"
            value={bulkCodeCreation?.maxUsages ?? ''}
            onChange={event =>
              validateInputNumberValue(
                event,
                'maxUsages',
                {
                  wholeNumberValues: {
                    fields: ['maxUsages'],
                  },
                },
                value =>
                  setBulkCodeCreation({ ...bulkCodeCreation, maxUsages: value || undefined }),
              )
            }
            placeholder="Max Usages"
          />
        </div>
        <div>
          <PromoPageSubTitle>{`Code Length${
            mode === 'create' || bulkCodeCreation?.codeAmount ? '*' : ''
          }:`}</PromoPageSubTitle>
          <TextField
            type="number"
            value={bulkCodeCreation?.generatedPartLength ?? ''}
            onChange={event =>
              validateInputNumberValue(
                event,
                'generatedPartLength',
                {
                  wholeNumberValues: {
                    fields: ['generatedPartLength'],
                  },
                },
                value =>
                  setBulkCodeCreation({
                    ...bulkCodeCreation,
                    generatedPartLength: value || undefined,
                  }),
              )
            }
            placeholder="Generated part length"
          />
        </div>
      </PromoPageRow>
      <PromoPageRow>
        <div>
          <PromoPageSubTitle>Prefix:</PromoPageSubTitle>
          <TextField
            value={bulkCodeCreation?.prefix ?? ''}
            onChange={event =>
              setBulkCodeCreation({ ...bulkCodeCreation, prefix: event.target.value })
            }
            placeholder="Prefix"
          />
        </div>
        <div>
          <PromoPageSubTitle>Suffix:</PromoPageSubTitle>
          <TextField
            value={bulkCodeCreation?.suffix ?? ''}
            onChange={event =>
              setBulkCodeCreation({ ...bulkCodeCreation, suffix: event.target.value })
            }
            placeholder="Suffix"
          />
        </div>
      </PromoPageRow>
      <PromoPageRow>
        <div className={'max-width'}>
          <PromoPageSubTitle>Start/End Date:</PromoPageSubTitle>
          <PromotionCodeDateRange
            startDate={bulkCodeCreation?.startDate}
            endDate={bulkCodeCreation?.endDate}
            setStartDate={startDate => setBulkCodeCreation({ ...bulkCodeCreation, startDate })}
            setEndDate={endDate => setBulkCodeCreation({ ...bulkCodeCreation, endDate })}
          />
        </div>
        <div className={'time-zone-picker'}>
          <PromoPageSubTitle>Time Zone*:</PromoPageSubTitle>
          <TimezonePicker
            className={'w-80'}
            value={bulkCodeCreation?.timeZone}
            onChange={(timeZone: string) => {
              setBulkCodeCreation({ ...bulkCodeCreation, timeZone });
              trackTimeZoneSelectorModify(timeZone);
            }}
          />
        </div>
      </PromoPageRow>
    </PromoPageRowWrapper>
  );
}

export default PromotionBulkCodes;
