import * as React from 'react';
import { ReactElement } from 'react';
import { HomeBreadcrumbRegionStyled, MainTitleStyled } from './header.styled';
import BreadcrumbComponent from '../breadcrumb/breadcrumbComponent';
import { AppTitle } from '../homeDashboard/applicationCard.styled';

interface HeaderComponentProps {
  title: string;
  infoElement?: ReactElement;
  controlElement?: ReactElement;
}

function HeaderComponent({
  title,
  infoElement,
  controlElement,
}: HeaderComponentProps): ReactElement {
  return (
    <>
      <HomeBreadcrumbRegionStyled>
        <BreadcrumbComponent />
      </HomeBreadcrumbRegionStyled>
      <MainTitleStyled>
        <div>
          <AppTitle>{title}</AppTitle>
          {infoElement}
        </div>
        <div>{controlElement}</div>
      </MainTitleStyled>
    </>
  );
}

export default HeaderComponent;
