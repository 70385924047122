import * as React from 'react';
import { ReactElement } from 'react';
import { withRequestedAuthz } from '@wework/we-auth-react';
import { get } from 'lodash';
import ApplicationCardComponent from './applicationCard';
import ROUTES from '../../utils/routes/routes';
import { AuthzProps } from '../../utils/constants';
import { ApplicationCardItem, ApplicationsDashboardList } from './homeDashboard.styled';
import EmployeePermissions from '../../utils/store/permissions';

function HomeDashboardComponent({ requestedPermissions }: AuthzProps): ReactElement {
  const discountBtn = [
    {
      label: 'Manage Discounts',
      route: ROUTES.DISCOUNT,
    },
  ];

  const promotionsBtn = [
    {
      label: 'Manage Promotions',
      route: ROUTES.PROMOTION_TABLEVIEW,
    },
  ];

  // Checking for batch discount uploader permission.
  if (
    get(requestedPermissions, EmployeePermissions.voyager_dedicated_space_discount_publish, false)
  ) {
    discountBtn.push({
      label: 'Upload',
      route: ROUTES.UPLOAD_DISCOUNTS_FROM_FILE,
    });
  }

  if (
    get(
      requestedPermissions,
      EmployeePermissions.voyager_dedicated_space_discount_curve_manage,
      false,
    )
  ) {
    discountBtn.push({
      label: 'Curves',
      route: ROUTES.DISCOUNT_CURVE_MANAGEMENT,
    });
  }

  const pricingBtn = [
    {
      label: 'Manage Prices',
      route: ROUTES.PRICING,
    },
  ];

  if (get(requestedPermissions, EmployeePermissions.voyager_dedicated_space_price_publish, false)) {
    pricingBtn.push({
      label: 'Upload',
      route: ROUTES.UPLOAD_PRICING_FROM_FILE,
    });
  }

  if (
    get(requestedPermissions, EmployeePermissions.voyager_dedicated_space_breakeven_view, false)
  ) {
    pricingBtn.push({
      label: 'Breakeven Price',
      route: ROUTES.BREAKEVEN,
    });
  }

  const onDemandPricingBtn = [
    {
      label: 'Manage On Demand Prices',
      route: ROUTES.ON_DEMAND_PRICING,
    },
  ];

  const applicationCardsDedicatedSpace = [
    {
      name: 'Pricing Management',
      content: ApplicationCardComponent(
        'pricing',
        !get(requestedPermissions, EmployeePermissions.voyager_dedicated_space_price_view, false),
        'Pricing',
        'Management',
        [
          {
            sectionDesc: 'Dedicated Space',
            buttons: [...pricingBtn],
          },
          {
            sectionDesc: 'On Demand',
            buttons: [...onDemandPricingBtn],
          },
        ],
      ),
    },
    {
      name: 'Discounts Management',
      content: ApplicationCardComponent(
        'discounts',
        !get(
          requestedPermissions,
          EmployeePermissions.voyager_dedicated_space_discount_view,
          false,
        ),
        'Discounts',
        'Management',
        [
          {
            sectionDesc: 'Dedicated Space',
            buttons: [...discountBtn],
          },
        ],
      ),
    },
    {
      name: 'Promotions Management',
      content: ApplicationCardComponent(
        'promotions',
        !get(requestedPermissions, EmployeePermissions.voyager_promotions_view, false),
        'Promotions',
        'Management',
        [
          {
            sectionDesc: '',
            buttons: [...promotionsBtn],
          },
        ],
      ),
    },
  ];

  return (
    <>
      <ApplicationsDashboardList key="dedicated-list">
        {applicationCardsDedicatedSpace.map((application: any) => (
          <ApplicationCardItem key={application.name}>{application.content}</ApplicationCardItem>
        ))}
      </ApplicationsDashboardList>
    </>
  );
}

export default withRequestedAuthz<AuthzProps>({
  permissions: [
    EmployeePermissions.voyager_dedicated_space_breakeven_view,
    EmployeePermissions.voyager_dedicated_space_price_view,
    EmployeePermissions.voyager_dedicated_space_discount_view,
    EmployeePermissions.voyager_dedicated_space_discount_curve_manage,
    EmployeePermissions.voyager_dedicated_space_price_publish,
    EmployeePermissions.voyager_dedicated_space_discount_publish,
    EmployeePermissions.voyager_on_demand_price_view,
    EmployeePermissions.voyager_promotions_view,
  ],
})(HomeDashboardComponent);
