import * as React from 'react';
import { ReactElement, useState } from 'react';
import cn from 'classnames';
import { difference } from 'lodash';
import { useSelector } from 'react-redux';
import {
  ActiveFilterValues,
  FilterBody,
  FilterHeader,
  FilterItemOption,
  PanelFilterItem,
} from '../../../../styles/app.styled';
import { PromotionFilterProps } from './promotionTableFilterBar';
import PromotionProductsChecklist from '../shared/promotionProductsChecklist';
import { productsSelector } from '../../store/promotion.selector';
import { trackAnalytics } from '../../../../utils/analytics/helpers';

function PromotionProductFilter({ filter, setFilter }: PromotionFilterProps): ReactElement {
  const [accordionOpen, setAccordionOpen] = useState<boolean>(true);

  const products = useSelector(productsSelector);

  const trackProductFilterPromotions = (productIds: string[]) =>
    trackAnalytics(`Promotion - Product Filter Promotion used`, {
      workflow: `Promotion - Filter promotion`,
      object_type: 'dropdown',
      object_name: `Product Filter promotion`,
      object_value: productIds,
    });

  return (
    <PanelFilterItem className={cn({ open: accordionOpen, closed: !accordionOpen })}>
      <FilterHeader className="filter-header">
        <button type="button" onClick={() => setAccordionOpen(!accordionOpen)}>
          <span>{'Product Limitations'}</span>
          <i className="toggle-accordion-icon" />
        </button>
        {!accordionOpen && (
          <ActiveFilterValues>
            {filter.productLimitations
              ?.map(id => products.find(product => product.id === id)?.name ?? id)
              ?.join(', ')}
          </ActiveFilterValues>
        )}
      </FilterHeader>
      <FilterBody className="filter-body">
        <FilterItemOption>
          <PromotionProductsChecklist
            productLimitations={filter.productLimitations ?? []}
            removeProductLimitations={productIds => {
              const newProductLimitations = difference(filter.productLimitations, productIds);
              setFilter({
                ...filter,
                productLimitations:
                  newProductLimitations.length > 0 ? newProductLimitations : undefined,
              });
              trackProductFilterPromotions(newProductLimitations);
            }}
            addProductLimitations={productIds => {
              const productLimitations = (filter.productLimitations || []).concat(productIds);
              setFilter({
                ...filter,
                productLimitations,
              });
              trackProductFilterPromotions(productLimitations);
            }}
          />
        </FilterItemOption>
      </FilterBody>
    </PanelFilterItem>
  );
}

export default PromotionProductFilter;
