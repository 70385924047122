import { ColDef, ColGroupDef, GridOptions } from 'ag-grid-community';
import TableLoadingRenderer from '../../../../sharedComponents/tableComponent/tableLoadingRenderer';
import { getMainMenuItemsWithoutReset } from '../../../../utils/helpers';
import EditHistoryGroupRenderer from './renderers/editHistoryGroupRenderer';
import { PromotionChangeType } from '../../../../generated/voyager/graphql';
import ChangelogValueRenderer from './renderers/changelogValueRenderer';

export const gridOptions: GridOptions = {
  getRowId: params => params.data.id,
  defaultColDef: {
    flex: 1,
  },
  suppressMaxRenderedRowRestriction: true,
  suppressAnimationFrame: true,
  suppressContextMenu: true,
  suppressCellFocus: true,
  suppressRowClickSelection: true,
  suppressRowHoverHighlight: true,
  suppressAggFuncInHeader: true,
  suppressDragLeaveHidesColumns: true,
  loadingOverlayComponent: TableLoadingRenderer,
  groupDisplayType: 'groupRows',
  groupRowRendererParams: {
    innerRenderer: EditHistoryGroupRenderer,
  },
  groupDefaultExpanded: -1,
  getMainMenuItems: getMainMenuItemsWithoutReset,
};

export const columnDefs = (isBulkPromotion: boolean): (ColDef | ColGroupDef)[] => [
  {
    field: 'transactionId',
    rowGroup: true,
    hide: true,
  },
  {
    headerName: 'Entity',
    field: 'entityId',
    valueGetter: params => {
      if (params.data.changeType === PromotionChangeType.PROMOTION) {
        return 'Promotion';
      } else if (isBulkPromotion) {
        return 'All codes';
      }
      return `Code ${params.data.entityId}`;
    },
  },
  {
    headerName: 'Updated Field',
    field: 'updatedField',
    cellClass: 'background-gray',
  },
  {
    headerName: 'Old Value',
    field: 'oldValue',
    wrapText: true,
    autoHeight: true,
    cellRenderer: ChangelogValueRenderer,
  },
  {
    headerName: 'New Value',
    field: 'newValue',
    cellClass: 'background-gray',
    wrapText: true,
    autoHeight: true,
    cellRenderer: ChangelogValueRenderer,
  },
];
