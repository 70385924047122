import * as React from 'react';
import { ReactElement, useState } from 'react';
import { Icon, IconName } from '@wework/ray2';
import { Popup } from 'semantic-ui-react';
import { InfoTooltipContent, InfoTooltipTitle } from './infoTooltip.styled';
import { TooltipPosition } from '../../utils/sharedTypes';

interface InfoTooltipProps {
  popupTitle?: string;
  popupContent: ReactElement;
  tooltipPosition?: TooltipPosition;
  popupClassName?: string;
  iconName?: IconName;
}

function InfoTooltip({
  popupTitle,
  popupContent,
  tooltipPosition,
  popupClassName,
  iconName,
}: InfoTooltipProps): ReactElement {
  const [hovered, setHovered] = useState<any>(false);

  return (
    <Popup
      basic
      position={tooltipPosition}
      trigger={
        <Icon
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          icon={iconName ?? IconName.ALERT_CIRCLE}
          className={`${hovered ? 'text-primary ' : 'text-gray-70 '} ml-3xs`}
        />
      }
      on="hover"
      className={`info-tooltip ${popupClassName}`}
    >
      <Popup.Content>
        <InfoTooltipContent>
          {popupTitle && <InfoTooltipTitle>{popupTitle}:</InfoTooltipTitle>}
          {popupContent}
        </InfoTooltipContent>
      </Popup.Content>
    </Popup>
  );
}

export default InfoTooltip;
