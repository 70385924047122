import { gql } from '@apollo/client';

const promotionFragment = gql`
  fragment promotion on Promotion {
    id
    name
    description
    enabled
    approver
    createdBy
    createdAt
    totalCodes
    isBulkPromotion
    codes(filter: $codeFilter, pageInfo: $codePageInfo, sortInfos: $codeSortInfos) {
      id
      code
      startDate
      endDate
      timeZone
      enabled
      usages
      maxUsages
      createdBy
      createdAt
    }
  }
`;

export default promotionFragment;
