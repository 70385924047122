import 'ag-grid-enterprise';
import * as React from 'react';
import { ReactElement, useCallback, useEffect } from 'react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { useDispatch } from 'react-redux';
import { withRequestedAuthz } from '@wework/we-auth-react';
import { get } from 'lodash';
import { push } from 'connected-react-router';
import { IconName, Toggle } from '@wework/ray2';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import EmployeePermissions from 'utils/store/permissions';
import { AuthzProps } from 'utils/constants';
import ROUTES from 'utils/routes/routes';
import { MainRegionStyled, OverflowHack } from '../../../styles/app.styled';
import HeaderComponent from '../../../sharedComponents/header/header';
import PromotionAction from '../components/promotionAction/promotionAction';
import BottomPanelPromotionAction from '../components/promotionAction/bottomPanelPromotionAction';
import { FETCH_PROMOTION_BY_ID, SET_SELECTED_PROMOTION } from '../store/promotion.ducks';
import InfoTooltip from '../../../sharedComponents/tooltip/infoTooltip';
import { routerHistory } from '../../../utils/store/configureStore';
import { capitalizeFunc } from '../../pricing/standardPricing/components/helpers';
import { PromotionByIdQueryInput, PromotionTableInput } from '../store/entities/promotionItem';
import { pageInfoTiny } from '../../../utils/store/store.constants';
import { trackAnalytics } from '../../../utils/analytics/helpers';

interface PromotionActionPage extends AuthzProps {
  mode: 'create' | 'update' | 'view';
}

const INITIAL_PROMOTION_INPUT: PromotionTableInput = {
  id: undefined,
  manualCodeCreation: [],
  distribution: [],
  productLimitations: [],
  geoHierarchyLimitations: [],
  enabled: true,
  totalCodes: 0,
  isBulkPromotion: false,
  bulkCodeCreation: {
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  },
};

function PromotionActionPage({ requestedPermissions, mode }: PromotionActionPage): ReactElement {
  const { promotionUUID } = useParams<any>();

  const dispatch = useDispatch();

  const redirectToAccessDenied = useCallback(
    () => dispatch(push(ROUTES.ACCESS_DENIED_PAGE)),
    [dispatch],
  );
  const redirectToViewOrUpdate = useCallback(
    () =>
      dispatch(
        push(
          mode === 'update'
            ? `${ROUTES.PROMOTION_VIEW_PAGE}/${promotionUUID}`
            : `${ROUTES.PROMOTION_UPDATE_PAGE}/${promotionUUID}`,
        ),
      ),
    [dispatch, mode, promotionUUID],
  );

  const fetchPromotionById = useCallback(
    (payload: PromotionByIdQueryInput) => dispatch({ type: FETCH_PROMOTION_BY_ID, payload }),
    [dispatch],
  );

  const setSelectedPromotion = useCallback(
    (payload: PromotionTableInput | undefined) =>
      dispatch({ type: SET_SELECTED_PROMOTION, payload }),
    [dispatch],
  );

  const trackToggleViewUpdate = () =>
    trackAnalytics(`Promotion - View vs Edit promo mode toggle changed`, {
      workflow: `Promotion - View vs Edit promo`,
      object_type: 'toggle',
      object_name: `View vs Edit`,
    });

  useEffect(() => {
    if (
      !(
        mode === 'view' &&
        get(requestedPermissions, EmployeePermissions.voyager_promotions_view, false)
      ) &&
      !get(requestedPermissions, EmployeePermissions.voyager_promotions_manage, false)
    ) {
      redirectToAccessDenied();
    }

    if (promotionUUID) {
      fetchPromotionById({ queryVariables: { id: promotionUUID, codePageInfo: pageInfoTiny } });
    } else if (mode === 'create') {
      setSelectedPromotion(INITIAL_PROMOTION_INPUT);
    } else {
      toast.info('No promotion selected - Redirecting to promotion home', {
        position: toast.POSITION.TOP_CENTER,
      });
      routerHistory.push({ pathname: ROUTES.PROMOTION_TABLEVIEW });
    }
  }, [
    requestedPermissions,
    redirectToAccessDenied,
    fetchPromotionById,
    setSelectedPromotion,
    mode,
    promotionUUID,
  ]);

  useEffect(
    () => () => {
      setSelectedPromotion(undefined);
    },
    [setSelectedPromotion],
  );

  return (
    <>
      <MainRegionStyled>
        <OverflowHack>
          <HeaderComponent
            title={`${capitalizeFunc(mode)} Promotion`}
            infoElement={
              <InfoTooltip
                popupContent={<div>{'Mandatory fields are marked with "*"'}</div>}
                iconName={IconName.CIRCLE_QUESTION}
              />
            }
            controlElement={
              mode === 'create' ? undefined : (
                <>
                  <div>View</div>
                  <Toggle
                    className={'side-margin'}
                    checked={mode === 'update'}
                    disabled={
                      !get(
                        requestedPermissions,
                        EmployeePermissions.voyager_promotions_manage,
                        false,
                      )
                    }
                    onChange={() => {
                      redirectToViewOrUpdate();
                      trackToggleViewUpdate();
                    }}
                  />
                  <div>Update</div>
                  {mode === 'update' && (
                    <InfoTooltip
                      popupContent={
                        <div>
                          Any unsaved changes will be lost when switching from the update to view
                          mode
                        </div>
                      }
                      iconName={IconName.ALERT_CIRCLE}
                    />
                  )}
                </>
              )
            }
          />
          <PromotionAction mode={mode} />
        </OverflowHack>
      </MainRegionStyled>
      {mode !== 'view' && <BottomPanelPromotionAction mode={mode} />}
    </>
  );
}

export default withRequestedAuthz<PromotionActionPage>({
  permissions: [
    EmployeePermissions.voyager_promotions_manage,
    EmployeePermissions.voyager_promotions_view,
  ],
})(PromotionActionPage);
