import { Action, handleActions } from 'redux-actions';
import { findIndex, get } from 'lodash';
import { RowNode } from 'ag-grid-community';
import initialState, {
  initialOnDemandPricingItems,
} from '../../../../../../utils/store/initialState';
import { OnDemandPricingItemsSubset } from '../../entities/onDemandPricing';
import { onDemandPricingAllItemsSubsetSelector } from './onDemandPricing.selector';
import { createRDXConstant } from '../../../../../../utils/store/store.constants';
import {
  GeoHierarchy,
  MutationCancelFuturePriceHierarchyArgs,
  MutationRevertWorkingPriceHierarchyArgs,
  PriceHierarchyInput,
  Product,
  WorkingPriceHierarchy,
} from '../../../../../../generated/voyager/graphql';

// Action Constants
export const FETCH_ON_DEMAND_PRICING_ITEMS = createRDXConstant('FETCH_ON_DEMAND_PRICING_ITEMS');
export const STOP_FETCH_ON_DEMAND_PRICING_ITEMS = createRDXConstant(
  'STOP_FETCH_ON_DEMAND_PRICING_ITEMS',
);
export const FETCH_ON_DEMAND_PRICING_ITEMS_SUCCESS = createRDXConstant(
  'FETCH_ON_DEMAND_PRICING_ITEMS_SUCCESS',
);
export const START_ON_DEMAND_PRICING_FETCH = createRDXConstant('START_ON_DEMAND_PRICING_FETCH');
export const END_ON_DEMAND_PRICING_FETCH = createRDXConstant('END_ON_DEMAND_PRICING_FETCH');
export const CLEAR_ON_DEMAND_PRICING_STORE = createRDXConstant('CLEAR_ON_DEMAND_PRICING_STORE');

export const FETCH_ON_DEMAND_PRODUCTS = createRDXConstant('FETCH_ON_DEMAND_PRODUCTS');
export const END_FETCH_ON_DEMAND_PRODUCTS = createRDXConstant('END_FETCH_ON_DEMAND_PRODUCTS');

export const SET_ON_DEMAND_ITEMS_TO_PUBLISH = createRDXConstant('SET_ON_DEMAND_ITEMS_TO_PUBLISH');
export const SET_ON_DEMAND_PRODUCTS_TO_PUBLISH = createRDXConstant(
  'SET_ON_DEMAND_PRODUCTS_TO_PUBLISH',
);

export const ENABLE_EDIT_MODE_ON_DEMAND = createRDXConstant('ENABLE_EDIT_MODE_ON_DEMAND');
export const DISABLE_EDIT_MODE_ON_DEMAND = createRDXConstant('DISABLE_EDIT_MODE_ON_DEMAND');

export const CREATE_ON_DEMAND_WORKING_PRICE = createRDXConstant('CREATE_ON_DEMAND_WORKING_PRICE');
export const CREATE_ON_DEMAND_WORKING_PRICE_SUCCESS = createRDXConstant(
  'CREATE_ON_DEMAND_WORKING_PRICE_SUCCESS',
);

export const ON_DEMAND_WORKING_PRICE_PUBLISH = createRDXConstant('ON_DEMAND_WORKING_PRICE_PUBLISH');
export const ON_DEMAND_WORKING_PRICE_PUBLISH_SUCCESS = createRDXConstant(
  'ON_DEMAND_WORKING_PRICE_PUBLISH_SUCCESS',
);

export const ON_DEMAND_FUTURE_PRICE_CANCEL = createRDXConstant('ON_DEMAND_FUTURE_PRICE_CANCEL');
export const ON_DEMAND_FUTURE_PRICE_CANCEL_SUCCESS = createRDXConstant(
  'ON_DEMAND_FUTURE_PRICE_CANCEL_SUCCESS',
);
export const ON_DEMAND_FUTURE_PRICE_CANCEL_FAIL = createRDXConstant(
  'ON_DEMAND_FUTURE_PRICE_CANCEL_FAIL',
);

export const ON_DEMAND_WORKING_PRICE_REVERT = createRDXConstant('ON_DEMAND_WORKING_PRICE_REVERT');
export const ON_DEMAND_WORKING_PRICE_REVERT_SUCCESS = createRDXConstant(
  'ON_DEMAND_WORKING_PRICE_REVERT_SUCCESS',
);
export const ON_DEMAND_WORKING_PRICE_REVERT_FAIL = createRDXConstant(
  'ON_DEMAND_WORKING_PRICE_REVERT_FAIL',
);

export const FETCH_CURRENT_ON_DEMAND_CHANGE_LOG = createRDXConstant(
  'FETCH_CURRENT_ON_DEMAND_CHANGE_LOG',
);
export const FETCH_CURRENT_ON_DEMAND_CHANGE_LOG_SUCCESS = createRDXConstant(
  'FETCH_CURRENT_ON_DEMAND_CHANGE_LOG_SUCCESS',
);

export const SET_SELECTED_NODES_ON_DEMAND = createRDXConstant('SET_SELECTED_NODES_ON_DEMAND');
export const RESET_SELECTED_NODES_ON_DEMAND = createRDXConstant('RESET_SELECTED_NODES_ON_DEMAND');

export const OPEN_PRICE_HISTORY_SIDE_PANEL = createRDXConstant('OPEN_PRICE_HISTORY_SIDE_PANEL');
export const CLOSE_PRICE_HISTORY_SIDE_PANEL = createRDXConstant('CLOSE_PRICE_HISTORY_SIDE_PANEL');

export const FETCH_PRICE_HISTORY = createRDXConstant('FETCH_PRICE_HISTORY');
export const END_FETCH_PRICE_HISTORY = createRDXConstant('END_FETCH_PRICE_HISTORY');

const workingPriceOnDemandModifierHelper = (
  allItems: GeoHierarchy[],
  workingPrices: PriceHierarchyInput[],
): GeoHierarchy[] => {
  workingPrices.forEach((eachWorkingPrice: PriceHierarchyInput) => {
    const idxGroup = findIndex(
      allItems,
      (eachItem: GeoHierarchy) => eachItem?.id === eachWorkingPrice.id.geoGroupingId,
    );
    const idx = findIndex(
      allItems[idxGroup].workingPrices,
      (eachItem: WorkingPriceHierarchy) => eachItem?.productId === eachWorkingPrice.id.productId,
    );

    if (idx > -1 && idxGroup > -1) {
      // - Copy working price array.
      const workingPricesArr = [...allItems[idxGroup].workingPrices];
      // - Modify the above duplicate working price array.
      workingPricesArr.splice(idx, 1, {
        ...allItems[idxGroup]?.workingPrices[idx],
        price: eachWorkingPrice.price,
      } as WorkingPriceHierarchy);
      // - place the working price in appropriate item.
      allItems.splice(idxGroup, 1, {
        ...allItems[idxGroup],
        workingPrices: [...workingPricesArr],
      });
    } else if (idxGroup > -1) {
      const workingPricesArr = [
        ...allItems[idxGroup].workingPrices,
        {
          price: eachWorkingPrice.price,
          productId: eachWorkingPrice.id.productId,
        } as WorkingPriceHierarchy,
      ];

      allItems.splice(idxGroup, 1, {
        ...allItems[idxGroup],
        workingPrices: [...workingPricesArr],
      });
    }
  });

  return allItems;
};

// Reducer
export const onDemandPricingItemsReducer = handleActions<OnDemandPricingItemsSubset, any>(
  {
    [FETCH_ON_DEMAND_PRICING_ITEMS]: (state: OnDemandPricingItemsSubset) => ({
      ...state,
    }),
    [STOP_FETCH_ON_DEMAND_PRICING_ITEMS]: (state: OnDemandPricingItemsSubset) => ({
      ...state,
    }),
    [FETCH_ON_DEMAND_PRICING_ITEMS_SUCCESS]: (
      state: OnDemandPricingItemsSubset,
      action: Action<any>,
    ) => ({
      ...state,
      allItems: get(action, 'data.geoHierarchies', []),
    }),
    [START_ON_DEMAND_PRICING_FETCH]: (state: OnDemandPricingItemsSubset) => ({
      ...state,
      isLoadingFetchState: true,
    }),
    [END_ON_DEMAND_PRICING_FETCH]: (state: OnDemandPricingItemsSubset) => ({
      ...state,
      isLoadingFetchState: false,
    }),
    [CLEAR_ON_DEMAND_PRICING_STORE]: (state: OnDemandPricingItemsSubset) => ({
      ...state,
      ...initialOnDemandPricingItems,
    }),
    [FETCH_ON_DEMAND_PRODUCTS]: (state: OnDemandPricingItemsSubset) => ({
      ...state,
      productsLoadingFetchState: true,
    }),
    [END_FETCH_ON_DEMAND_PRODUCTS]: (state: OnDemandPricingItemsSubset, action: Action<any>) => ({
      ...state,
      productsLoadingFetchState: false,
      onDemandProducts: get(action, 'data.products', []),
    }),
    [SET_ON_DEMAND_ITEMS_TO_PUBLISH]: (
      state: OnDemandPricingItemsSubset,
      action: Action<GeoHierarchy[]>,
    ) => ({
      ...state,
      itemsToPublish: action.payload,
    }),
    [SET_ON_DEMAND_PRODUCTS_TO_PUBLISH]: (
      state: OnDemandPricingItemsSubset,
      action: Action<Product[]>,
    ) => ({
      ...state,
      productsToPublish: action.payload,
    }),
    [ENABLE_EDIT_MODE_ON_DEMAND]: (state: OnDemandPricingItemsSubset) => ({
      ...state,
      editMode: true,
    }),
    [DISABLE_EDIT_MODE_ON_DEMAND]: (state: OnDemandPricingItemsSubset) => ({
      ...state,
      editMode: false,
    }),

    [CREATE_ON_DEMAND_WORKING_PRICE]: (state: OnDemandPricingItemsSubset) => ({
      ...state,
    }),

    // Reducer for saving current working price batch.
    [CREATE_ON_DEMAND_WORKING_PRICE_SUCCESS]: (
      state: OnDemandPricingItemsSubset,
      action: Action<PriceHierarchyInput[]>,
    ) => {
      let allOnDemandItems = onDemandPricingAllItemsSubsetSelector(state) ?? [];
      allOnDemandItems = workingPriceOnDemandModifierHelper([...allOnDemandItems], action.payload);

      return {
        ...state,
        allItems: [...allOnDemandItems],
      };
    },

    [ON_DEMAND_WORKING_PRICE_PUBLISH]: (state: OnDemandPricingItemsSubset) => ({
      ...state,
      publishPrices: {
        loading: true,
        loaded: false,
        error: null,
      },
    }),
    [ON_DEMAND_WORKING_PRICE_PUBLISH_SUCCESS]: (state: OnDemandPricingItemsSubset) => ({
      ...state,
      publishPrices: {
        loading: false,
        loaded: true,
        error: null,
      },
    }),

    [ON_DEMAND_FUTURE_PRICE_CANCEL]: (state: OnDemandPricingItemsSubset) => ({
      ...state,
      cancelPricesLoading: true,
    }),
    [ON_DEMAND_FUTURE_PRICE_CANCEL_SUCCESS]: (
      state: OnDemandPricingItemsSubset,
      action: Action<MutationCancelFuturePriceHierarchyArgs>,
    ) => {
      const updatedItems = state.allItems.map(item => {
        const canceledProductIds = action.payload.priceHierarchyIds
          .filter(id => item.id === id.geoGroupingId)
          .map(id => id.productId);

        return canceledProductIds.length
          ? {
              ...item,
              futurePrices: item.futurePrices.filter(
                price => !canceledProductIds.includes(price.productId),
              ),
            }
          : item;
      });

      return {
        ...state,
        cancelPricesLoading: false,
        allItems: updatedItems,
      };
    },
    [ON_DEMAND_FUTURE_PRICE_CANCEL_FAIL]: (state: OnDemandPricingItemsSubset) => ({
      ...state,
      cancelPricesLoading: false,
    }),

    [ON_DEMAND_WORKING_PRICE_REVERT]: (state: OnDemandPricingItemsSubset) => ({
      ...state,
      revertPricesLoading: true,
    }),
    [ON_DEMAND_WORKING_PRICE_REVERT_SUCCESS]: (
      state: OnDemandPricingItemsSubset,
      action: Action<MutationRevertWorkingPriceHierarchyArgs>,
    ) => {
      const updatedItems = state.allItems.map(item => {
        const revertProductIds = action.payload.priceHierarchyIds
          .filter(id => item.id === id.geoGroupingId)
          .map(id => id.productId);

        return revertProductIds.length
          ? {
              ...item,
              workingPrices: item.workingPrices.filter(
                price => !revertProductIds.includes(price.productId),
              ),
            }
          : item;
      });

      return {
        ...state,
        revertPricesLoading: false,
        allItems: updatedItems,
      };
    },
    [ON_DEMAND_WORKING_PRICE_REVERT_FAIL]: (state: OnDemandPricingItemsSubset) => ({
      ...state,
      revertPricesLoading: false,
    }),

    // Reducer for fetching the change log of priceable items.
    [FETCH_CURRENT_ON_DEMAND_CHANGE_LOG]: (state: OnDemandPricingItemsSubset) => ({
      ...state,
      currentChangeLogFetchState: {
        loading: true,
        loaded: false,
        error: null,
      },
    }),
    [FETCH_CURRENT_ON_DEMAND_CHANGE_LOG_SUCCESS]: (
      state: OnDemandPricingItemsSubset,
      action: Action<any>,
    ) => ({
      ...state,
      currentChangeLogFetchState: {
        loading: false,
        loaded: true,
        error: null,
      },
      currentChangeLog: get(action, 'data.geoHierarchies', []),
    }),
    [SET_SELECTED_NODES_ON_DEMAND]: (
      state: OnDemandPricingItemsSubset,
      action: Action<RowNode[]>,
    ) => ({
      ...state,
      selectedNodes: action.payload,
    }),
    [OPEN_PRICE_HISTORY_SIDE_PANEL]: (
      state: OnDemandPricingItemsSubset,
      action: Action<GeoHierarchy | null>,
    ) => ({
      ...state,
      priceHistoryGeo: action.payload,
    }),
    [CLOSE_PRICE_HISTORY_SIDE_PANEL]: (state: OnDemandPricingItemsSubset) => ({
      ...state,
      priceHistoryGeo: null,
    }),
    [FETCH_PRICE_HISTORY]: (state: OnDemandPricingItemsSubset) => ({
      ...state,
      priceHistoryLoadingFetchState: true,
    }),
    [END_FETCH_PRICE_HISTORY]: (state: OnDemandPricingItemsSubset, action: Action<any>) => ({
      ...state,
      priceHistoryLoadingFetchState: false,
      priceHistory: get(action, 'data.geoHierarchies[0].priceHistory', []),
    }),
    [RESET_SELECTED_NODES_ON_DEMAND]: (state: OnDemandPricingItemsSubset) => ({
      ...state,
      selectedNodes: initialState.onDemandPricingItems.selectedNodes,
    }),
  },
  initialState.onDemandPricingItems,
);
