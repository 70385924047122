import * as React from 'react';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import { GridApi, GridReadyEvent } from 'ag-grid-community';
import { useDispatch, useSelector } from 'react-redux';
import { compact, get, partition, uniq } from 'lodash';
import { withRequestedAuthz } from '@wework/we-auth-react';
import { TableFilterColumnsWrap, TableFilterRow } from 'styles/sharedTableFilterBar.styled';
import { columnDefs, gridOptions } from './breakevenTableAGGrid.settings';
import { transformBreakevenItemsToTableView } from './breakevenTable.helper';
import {
  breakevenItemsSelectorStore,
  marketSgAndASelector,
} from '../../store/modules/breakevenItems/breakevenItems.selector';
import DetailSidePanel from '../../../../sharedComponents/sidePanelComponent/detailSidePanel';
import {
  currentDisplayableItemSelector,
  detailPanelParamSelector,
} from '../../../../sharedStore/modules/detailPanel/detailPanel.selector';
import TableFilterBarBreakeven from '../breakevenMenu/tableFilterBar';
import BreakevenViewEditModeSelector from '../breakevenMenu/breakevenTableEditToggle';
import './breakevenAGGridThemeOverrides.css';
import { SET_BREAKEVEN_DATA_TO_PUBLISH } from '../../store/modules/breakevenItems/breakevenItems.ducks';
import { AuthzProps } from '../../../../utils/constants';
import { AgGridWrapper, GridCustomWrapper, MarginWrapper } from '../../../../styles/app.styled';
import EmployeePermissions from '../../../../utils/store/permissions';
import { LocationBreakEvenDetail } from '../../../../generated/voyager/graphql';

function BreakevenTableComponent({ requestedPermissions }: AuthzProps): ReactElement {
  // STATE VAR
  // ag grid apis
  const dispatch = useDispatch();
  const [gridApi, setGridApi] = useState<GridApi>();
  const [displayedRowCount, setDisplayedRowCount] = useState<number>(0);

  // STATE PROPS
  const allItems = useSelector(breakevenItemsSelectorStore) ?? [];
  const itemDetailsPanelVisible = useSelector(detailPanelParamSelector);
  const currentDisplayableItem = useSelector(currentDisplayableItemSelector);
  const [previousBreakevenItems, setPreviousBreakevenItems] = useState<LocationBreakEvenDetail[]>(
    [],
  );
  const marketSgAndAs = useSelector(marketSgAndASelector);

  const setDataToPublish = useCallback(
    (payload: string[]) => dispatch({ type: SET_BREAKEVEN_DATA_TO_PUBLISH, payload }),
    [dispatch],
  );

  /**
   * Transform data and load it in component if data change.
   */
  const transformDataHelper = () => {
    const transformedData = transformBreakevenItemsToTableView(allItems, marketSgAndAs);

    // Partition depending on item with the same id being present in the grid
    const updatedAndNewData = partition(transformedData, item =>
      previousBreakevenItems?.find(previousItem => previousItem.location.id === item.location.id),
    );

    // updatedAndNewData[0] contains items that were already in the grid, but were changed;
    // updatedAndNewData[1] contains completely new items.
    const filteredUpdatedData = updatedAndNewData[0];
    const filteredNewData = updatedAndNewData[1];

    if (gridApi) {
      setTimeout(
        () => gridApi.applyTransactionAsync({ add: filteredNewData, update: filteredUpdatedData }),
        0,
      );
      setPreviousBreakevenItems(allItems);
      setDisplayedRowCount(allItems.length);
    }
  };
  useEffect(transformDataHelper, [gridApi, allItems, marketSgAndAs]);

  /**
   * OnGridReady is an AgGrid event that receives a param with access to the api.
   * @param params Parameter passed from AG grid.
   */
  const onGridReady = (params: GridReadyEvent): void => {
    setGridApi(params?.api);
  };

  const getRowStyle = (params: any) => {
    if (params?.data?.type === 'current') {
      return { background: '#E5F4FF' };
    } else if (params?.data === undefined) {
      return { background: '#F7F7F7' };
    }
    return { background: 'white' };
  };

  const getRowHeight = (params: any) => {
    if (params?.data?.type === 'toBePublished') {
      return 100;
    }
    return 56;
  };

  return (
    <>
      <TableFilterBarBreakeven agGridApi={gridApi} />
      <GridCustomWrapper>
        <MarginWrapper>
          <TableFilterColumnsWrap>
            <TableFilterRow>
              <p>{`Viewing ${displayedRowCount} Locations`}</p>
            </TableFilterRow>
            <TableFilterRow />
            {get(
              requestedPermissions,
              EmployeePermissions.voyager_dedicated_space_breakeven_edit,
              false,
            ) ? (
              <BreakevenViewEditModeSelector />
            ) : (
              ''
            )}
          </TableFilterColumnsWrap>
        </MarginWrapper>
        <AgGridWrapper className="ag-theme-alpine breakeven-grid">
          <AgGridReact
            gridOptions={gridOptions(
              get(
                requestedPermissions,
                EmployeePermissions.voyager_dedicated_space_breakeven_edit,
                false,
              ),
            )}
            columnDefs={columnDefs(
              get(
                requestedPermissions,
                EmployeePermissions.voyager_dedicated_space_breakeven_edit,
                false,
              ),
            )}
            onGridReady={onGridReady}
            getRowHeight={getRowHeight}
            getRowStyle={getRowStyle}
            onSelectionChanged={event => {
              const locationIdsToPublish = event.api.getSelectedNodes().map(node => node.key);
              setDataToPublish(compact(uniq(locationIdsToPublish)));
            }}
          />
        </AgGridWrapper>
      </GridCustomWrapper>
      <DetailSidePanel
        isVisible={itemDetailsPanelVisible}
        currentDisplayableItem={currentDisplayableItem}
      />
    </>
  );
}

export default withRequestedAuthz<AuthzProps>({
  permissions: [EmployeePermissions.voyager_dedicated_space_breakeven_edit],
})(BreakevenTableComponent);
