// Action Constants
import { Action, handleActions } from 'redux-actions';
import { get, uniqBy } from 'lodash';
import { RowNode } from 'ag-grid-community';
import { createRDXConstant } from '../../../../../utils/store/store.constants';
import initialState from '../../../../../utils/store/initialState';
import {
  defaultPageInfo,
  PriceSyncAttempt,
  PriceSyncAttemptFilter,
  PriceSyncAttemptSubset,
} from '../../entities/priceSyncAttempt';
import { PageInfo } from '../../../../../generated/voyager/graphql';

export const RE_PUBLISH_PRICE = createRDXConstant('RE_PUBLISH_PRICE');

export const RE_PUBLISH_PRICE_END = createRDXConstant('RE_PUBLISH_PRICE_END');

export const FETCH_SYNC_ATTEMPTS_WITH_FILTER = createRDXConstant('FETCH_SYNC_ATTEMPTS_WITH_FILTER');

export const FETCH_SYNC_ATTEMPTS_SUCCESS = createRDXConstant('FETCH_SYNC_ATTEMPTS_SUCCESS');

export const PRICE_SYNC_ATTEMPTS_SET_FILTER = createRDXConstant('PRICE_SYNC_ATTEMPTS_SET_FILTER');

export const PRICE_SYNC_ATTEMPTS_SET_PAGE_INFO = createRDXConstant(
  'PRICE_SYNC_ATTEMPTS_SET_PAGE_INFO',
);

export const SET_SELECTED_NODES_PRICE_RE_PUBLISH = createRDXConstant(
  'SET_SELECTED_NODES_PRICE_RE_PUBLISH',
);

// Reducer
export const priceRePublishingReducer = handleActions<PriceSyncAttemptSubset, any>(
  {
    [FETCH_SYNC_ATTEMPTS_WITH_FILTER]: (state: PriceSyncAttemptSubset) => ({
      ...state,
    }),
    [RE_PUBLISH_PRICE_END]: (state: PriceSyncAttemptSubset) => ({
      ...state,
      isLoading: false,
      selectedNodes: [],
    }),
    [RE_PUBLISH_PRICE]: (state: PriceSyncAttemptSubset) => ({
      ...state,
      isLoading: true,
    }),
    [FETCH_SYNC_ATTEMPTS_SUCCESS]: (
      state: PriceSyncAttemptSubset,
      action: Action<{ data: PriceSyncAttempt }>,
    ) => {
      let newPriceSyncAttempt = get(action, 'data', []);
      newPriceSyncAttempt = uniqBy(newPriceSyncAttempt, 'id');

      return {
        ...state,
        allPriceSyncAttempt: newPriceSyncAttempt,
      };
    },
    [PRICE_SYNC_ATTEMPTS_SET_FILTER]: (
      state: PriceSyncAttemptSubset,
      action: Action<PriceSyncAttemptFilter>,
    ) => ({
      ...state,
      priceSyncAttemptFilter: action.payload,
      pageInfo: defaultPageInfo,
    }),
    [PRICE_SYNC_ATTEMPTS_SET_PAGE_INFO]: (
      state: PriceSyncAttemptSubset,
      action: Action<PageInfo>,
    ) => ({
      ...state,
      pageInfo: action.payload,
    }),
    [SET_SELECTED_NODES_PRICE_RE_PUBLISH]: (
      state: PriceSyncAttemptSubset,
      action: Action<RowNode[]>,
    ) => ({
      ...state,
      selectedNodes: action.payload,
    }),
  },
  initialState.priceRePublishing,
);
