import styled from 'styled-components';

export const DateRangeInfo = styled.div`
  display: inline-block;
  width: 230px;
`;

export const TimeZoneWrapper = styled.div`
  > div {
    width: 300px;

    > ul {
      background: white;
    }
  }
`;

export const ChecklistWrapper = styled.div`
  max-width: 300px;
  width: 300px;

  > .react-dropdown-tree-select .dropdown .dropdown-content {
    width: 300px;
  }

  > .react-dropdown-tree-select .dropdown .dropdown-trigger {
    border-radius: 5px;
    width: 300px;

    &.arrow.bottom::after,
    &.arrow.top::after {
      float: right;
      margin-top: 5px;
    }
  }

  .tag-item .search {
    border-bottom: none;
    width: 60px;
  }

  .toggle {
    font-style: unset;
    font-size: 15px;
  }

  .tag {
    border-radius: 5px;
  }

  .tag-remove {
    margin: 0 5px;
    font-size: 15px;
    font-weight: bold;
  }

  .node {
    &.focused {
      background-color: white;
    }
  }
`;
