import { Action, handleActions } from 'redux-actions';
import { get, sortBy, uniqBy } from 'lodash';
import initialState, { initialDiscountItems } from '../../../../../utils/store/initialState';
import {
  DiscountCurveStatus,
  DiscountItemsSubset,
  GridDiscountPagedItem,
  Override,
} from '../../entities/discountItems';
import {
  batchFetchStateSubsetSelectorDiscountCurves,
  discountCurvesItemsSubsetSelector,
  discountItemsOverridesSubset,
  discountItemsSubsetSelector,
} from './discountItems.selector';
import {
  applyCurvesToPriceableItems,
  removeCurvesFromPriceableItems,
  setFutureDiscountsNull,
} from './discountItems.helper';
import { createRDXConstant } from '../../../../../utils/store/store.constants';
import {
  Curve,
  CurvesQuery,
  DiscountCurveResult,
  DiscountHistory,
  DiscountItemsTermTypesQuery,
  FindCurvesQuery,
  Operator,
} from '../../../../../generated/voyager/graphql';

// Action Constants
// Action - Reset the discount part of the store.
export const RESET_DISCOUNT_STORE = createRDXConstant('RESET_DISCOUNT_STORE');

// Action - for fetching priceable Items by batch.
export const FETCH_DISCOUNT_ITEMS = createRDXConstant('FETCH_DISCOUNT_ITEMS');
export const STOP_FETCH_DISCOUNT_ITEMS = createRDXConstant('STOP_FETCH_DISCOUNT_ITEMS');
export const FETCH_DISCOUNT_ITEMS_SUCCESS = createRDXConstant('FETCH_DISCOUNT_ITEMS_SUCCESS');
export const CLEAR_DISCOUNT_ITEMS = createRDXConstant('CLEAR_DISCOUNT_ITEMS');

export const PREV_DISCOUNT_ITEMS = createRDXConstant('PREV_DISCOUNT_ITEMS');
export const CLEAR_PREV_DISCOUNT_ITEMS = createRDXConstant('CLEAR_PREV_DISCOUNT_ITEMS');

// Action - for fetching priceable Items discount term types and max discount.
export const FETCH_DISCOUNT_ITEMS_TERM_TYPES = createRDXConstant('FETCH_DISCOUNT_ITEMS_TERM_TYPES');
export const FETCH_DISCOUNT_ITEMS_TERM_TYPES_SUCCESS = createRDXConstant(
  'FETCH_DISCOUNT_ITEMS_TERM_TYPES_SUCCESS',
);

// Action - for batch fetch tracking.
export const START_DISCOUNT_BATCH_FETCH = createRDXConstant('START_DISCOUNT_BATCH_FETCH');
export const END_DISCOUNT_BATCH_FETCH = createRDXConstant('END_DISCOUNT_BATCH_FETCH');

// Action - for batch fetch tracking.
export const START_DISCOUNT_CURVES_BATCH_FETCH = createRDXConstant(
  'START_DISCOUNT_CURVES_BATCH_FETCH',
);
export const END_DISCOUNT_CURVES_BATCH_FETCH = createRDXConstant('END_DISCOUNT_CURVES_BATCH_FETCH');

// Actions for handling the Side panel.
export const OPEN_DISCOUNT_DETAILS_SIDE_PANEL = createRDXConstant(
  'OPEN_DISCOUNT_DETAILS_SIDE_PANEL',
);
export const CLOSE_DISCOUNT_DETAILS_SIDE_PANEL = createRDXConstant(
  'CLOSE_DISCOUNT_DETAILS_SIDE_PANEL',
);

export const ADD_OVERRIDE_VALUE = createRDXConstant('ADD_OVERRIDE_VALUE');
export const CLEAR_OVERRIDE_VALUE = createRDXConstant('CLEAR_OVERRIDE_VALUE');

export const PUBLISH_WORKING_DISCOUNTS = createRDXConstant('PUBLISH_WORKING_DISCOUNTS');
export const PUBLISH_WORKING_DISCOUNTS_SUCCESS = createRDXConstant(
  'PUBLISH_WORKING_DISCOUNTS_SUCCESS',
);

export const FIND_CURVES = createRDXConstant('FIND_CURVES');
export const FIND_CURVES_SUCCESS = createRDXConstant('FIND_CURVES_SUCCESS');
export const CLEAR_CURVES = createRDXConstant('CLEAR_CURVES');
export const APPLY_CURVE_FILTER = createRDXConstant('APPLY_CURVE_FILTER');
export const RESET_CURVE_FILTER = createRDXConstant('RESET_CURVE_FILTER');

export const APPLY_CURVES = createRDXConstant('APPLY_CURVES');
export const APPLY_CURVES_SUCCESS = createRDXConstant('APPLY_CURVES_SUCCESS');
export const REMOVE_CURVES = createRDXConstant('REMOVE_CURVES');
export const REMOVE_CURVES_SUCCESS = createRDXConstant('REMOVE_CURVES_SUCCESS');

export const CREATE_CURVES = createRDXConstant('CREATE_CURVES');
export const CREATE_CURVES_SUCCESS = createRDXConstant('CREATE_CURVES_SUCCESS');
export const UPDATE_CURVES = createRDXConstant('UPDATE_CURVES');
export const UPDATE_CURVES_SUCCESS = createRDXConstant('UPDATE_CURVES_SUCCESS');

export const DELETE_CURVES = createRDXConstant('DELETE_CURVES');
export const DELETE_CURVES_SUCCESS = createRDXConstant('DELETE_CURVES_SUCCESS');

// Action - for fetching priceable Items by batch.
export const FETCH_DISCOUNT_ITEMS_CURVES = createRDXConstant('FETCH_DISCOUNT_ITEMS_CURVES');
export const STOP_FETCH_DISCOUNT_ITEMS_CURVES = createRDXConstant(
  'STOP_FETCH_DISCOUNT_ITEMS_CURVES',
);
export const FETCH_DISCOUNT_ITEMS_CURVES_SUCCESS = createRDXConstant(
  'FETCH_DISCOUNT_ITEMS_CURVES_SUCCESS',
);
export const CLEAR_DISCOUNT_ITEMS_CURVES = createRDXConstant('CLEAR_DISCOUNT_ITEMS_CURVES');

export const FETCH_USER_OPERATORS = createRDXConstant('FETCH_USER_OPERATORS');
export const FETCH_USER_OPERATORS_SUCCESS = createRDXConstant('FETCH_USER_OPERATORS_SUCCESS');

// Action for fetching feature card elements to explain suggested reason.
export const FETCH_DISCOUNT_HISTORY = createRDXConstant('FETCH_DISCOUNT_HISTORY');
export const FETCH_DISCOUNT_HISTORY_SUCCESS = createRDXConstant('FETCH_DISCOUNT_HISTORY_SUCCESS');

export const SET_IDS_CANCEL_FUTURE_DISCOUNT = createRDXConstant('SET_IDS_CANCEL_FUTURE_DISCOUNT');
export const CANCEL_FUTURE_DISCOUNT = createRDXConstant('CANCEL_FUTURE_DISCOUNT');
export const CANCEL_FUTURE_DISCOUNT_SUCCESS = createRDXConstant('CANCEL_FUTURE_DISCOUNT_SUCCESS');

// Reducer
export const discountItemsReducer = handleActions<DiscountItemsSubset, any>(
  {
    // Reducer for fetching all the priceable items in batches.
    [FETCH_DISCOUNT_ITEMS]: (state: DiscountItemsSubset) => ({
      ...state,
    }),
    [STOP_FETCH_DISCOUNT_ITEMS]: (state: DiscountItemsSubset) => ({
      ...state,
    }),
    [FETCH_DISCOUNT_ITEMS_SUCCESS]: (
      state: DiscountItemsSubset,
      action: Action<GridDiscountPagedItem[]>,
    ) => {
      const oldDiscountItems = discountItemsSubsetSelector(state) ?? [];
      const items = [...oldDiscountItems, ...action.payload];

      return {
        ...state,
        allDiscountItems: sortBy(uniqBy(items, 'id'), 'page'),
      };
    },
    [CLEAR_DISCOUNT_ITEMS]: (state: DiscountItemsSubset) => ({
      ...state,
      allDiscountItems: initialState.discountItems.allDiscountItems,
    }),

    [PREV_DISCOUNT_ITEMS]: (state: DiscountItemsSubset) => ({
      ...state,
      allPrevDiscountItems: state.allDiscountItems,
    }),
    [CLEAR_PREV_DISCOUNT_ITEMS]: (state: DiscountItemsSubset) => ({
      ...state,
      allPrevDiscountItems: initialState.discountItems.allPrevDiscountItems,
    }),

    // Reducer for fetching priceable items term types.
    [FETCH_DISCOUNT_ITEMS_TERM_TYPES]: (state: DiscountItemsSubset) => ({
      ...state,
    }),
    [FETCH_DISCOUNT_ITEMS_TERM_TYPES_SUCCESS]: (
      state: DiscountItemsSubset,
      action: Action<{ data: DiscountItemsTermTypesQuery | null }>,
    ) => {
      const termTypes = get(action, 'data.termTypes', []);
      return {
        ...state,
        termTypes,
      };
    },

    [RESET_DISCOUNT_STORE]: (state: DiscountItemsSubset) => ({
      ...state,
      ...initialState?.discountItems,
    }),

    // Reducer for batch fetch priceable items from gateway.
    [START_DISCOUNT_BATCH_FETCH]: (state: DiscountItemsSubset) => ({
      ...state,
      batchFetchInProgressDiscount: true,
    }),
    [END_DISCOUNT_BATCH_FETCH]: (state: DiscountItemsSubset) => ({
      ...state,
      batchFetchInProgressDiscount: false,
    }),

    // Reducer for handling the Side panel
    [OPEN_DISCOUNT_DETAILS_SIDE_PANEL]: (state: DiscountItemsSubset, action: Action<any>) => {
      const currentSelectedPriceableItemId = get(action, 'payload', '');
      return {
        ...state,
        currentSelectedPriceableItemId,
        sidePanelParam: true,
      };
    },
    [CLOSE_DISCOUNT_DETAILS_SIDE_PANEL]: (state: DiscountItemsSubset) => ({
      ...state,
      currentSelectedPriceableItemId: null,
      sidePanelParam: false,
    }),
    [ADD_OVERRIDE_VALUE]: (state: DiscountItemsSubset, action: Action<Override[]>) => {
      const oldDiscountItemsOverrides = discountItemsOverridesSubset(state) ?? [];
      const newDiscountItemsOverrides = get(action, 'payload', []);
      const overrides = sortBy(
        uniqBy([...newDiscountItemsOverrides, ...oldDiscountItemsOverrides], 'id'),
        'page',
      );
      return {
        ...state,
        overrides,
      };
    },
    [CLEAR_OVERRIDE_VALUE]: (state: DiscountItemsSubset, action: Action<string[]>) => {
      const currentDiscountItemsOverrides = discountItemsOverridesSubset(state) ?? [];
      const idsToClear: string[] = get(action, 'payload', []);
      return {
        ...state,
        overrides: idsToClear
          ? currentDiscountItemsOverrides.filter(override => !idsToClear.includes(override.id))
          : initialState.discountItems.overrides,
      };
    },
    // Dummy action all the work is done in the saga.
    [PUBLISH_WORKING_DISCOUNTS]: (state: DiscountItemsSubset) => ({
      ...state,
      publish: true,
    }),
    [PUBLISH_WORKING_DISCOUNTS_SUCCESS]: (state: DiscountItemsSubset) => ({
      ...state,
      publish: initialDiscountItems.publish,
      overrides: initialDiscountItems.overrides,
    }),

    [FIND_CURVES]: (state: DiscountItemsSubset) => ({
      ...state,
      curvesLoading: true,
    }),
    [FIND_CURVES_SUCCESS]: (
      state: DiscountItemsSubset,
      action: Action<{ data: FindCurvesQuery | null }>,
    ) => {
      const curves = get(action, 'data.findCurves', [] as Curve[]);
      return {
        ...state,
        curvesLoading: false,
        curves,
      };
    },
    [CLEAR_CURVES]: (state: DiscountItemsSubset) => ({
      ...state,
      curves: [],
    }),
    [APPLY_CURVES]: (state: DiscountItemsSubset) => ({
      ...state,
      mutateCurvesLoading: true,
    }),
    [APPLY_CURVES_SUCCESS]: (
      state: DiscountItemsSubset,
      action: Action<DiscountCurveResult[] | null>,
    ) => {
      if (action.payload === null) {
        return {
          ...state,
          mutateCurvesLoading: false,
        };
      }

      const priceableItems = applyCurvesToPriceableItems(state.allDiscountItems, action.payload);

      return {
        ...state,
        mutateCurvesLoading: false,
        allDiscountItems: priceableItems,
      };
    },
    [REMOVE_CURVES]: (state: DiscountItemsSubset) => ({
      ...state,
      mutateCurvesLoading: true,
    }),
    [REMOVE_CURVES_SUCCESS]: (
      state: DiscountItemsSubset,
      action: Action<DiscountCurveResult[] | null>,
    ) => {
      if (action.payload === null) {
        return {
          ...state,
          mutateCurvesLoading: false,
        };
      }

      const priceableItems = removeCurvesFromPriceableItems(state.allDiscountItems, action.payload);

      return {
        ...state,
        mutateCurvesLoading: false,
        allDiscountItems: priceableItems,
      };
    },

    // Curve Management - Creation of curves in FE.
    [CREATE_CURVES]: (state: DiscountItemsSubset) => ({
      ...state,
      createCurvesLoading: true,
    }),
    [CREATE_CURVES_SUCCESS]: (state: DiscountItemsSubset) => ({
      ...state,
      createCurvesLoading: false,
    }),

    [UPDATE_CURVES]: (state: DiscountItemsSubset) => ({
      ...state,
      updateCurvesLoading: true,
    }),
    [UPDATE_CURVES_SUCCESS]: (state: DiscountItemsSubset) => ({
      ...state,
      updateCurvesLoading: false,
    }),

    // Reducer for fetching all the priceable items in batches.
    [FETCH_DISCOUNT_ITEMS_CURVES]: (state: DiscountItemsSubset) => ({
      ...state,
    }),
    [STOP_FETCH_DISCOUNT_ITEMS_CURVES]: (state: DiscountItemsSubset) => ({
      ...state,
    }),
    [FETCH_DISCOUNT_ITEMS_CURVES_SUCCESS]: (
      state: DiscountItemsSubset,
      action: Action<{ data: CurvesQuery }>,
    ) => {
      let newDiscountCurvesItems = get(action, 'data.curves', [] as Curve[]);
      const oldDiscountCurvesItems = discountCurvesItemsSubsetSelector(state) ?? ([] as Curve[]);
      newDiscountCurvesItems = batchFetchStateSubsetSelectorDiscountCurves(state)
        ? [...newDiscountCurvesItems, ...oldDiscountCurvesItems]
        : newDiscountCurvesItems;
      newDiscountCurvesItems = uniqBy(newDiscountCurvesItems, 'id');

      return {
        ...state,
        allDiscountCurves: newDiscountCurvesItems,
      };
    },
    [CLEAR_DISCOUNT_ITEMS_CURVES]: (state: DiscountItemsSubset) => ({
      ...state,
      allDiscountCurves: initialState.discountItems.allDiscountCurves,
    }),

    // Reducer for batch fetch priceable items from gateway.
    [START_DISCOUNT_CURVES_BATCH_FETCH]: (state: DiscountItemsSubset) => ({
      ...state,
      batchFetchInProgressAllDiscountCurves: true,
    }),
    [END_DISCOUNT_CURVES_BATCH_FETCH]: (state: DiscountItemsSubset) => ({
      ...state,
      batchFetchInProgressAllDiscountCurves: false,
    }),
    [APPLY_CURVE_FILTER]: (state: DiscountItemsSubset, action: Action<DiscountCurveStatus>) => {
      const newCurveFilters = new Set<DiscountCurveStatus>([...state.curveFilters]);
      if (newCurveFilters.has(action.payload)) {
        newCurveFilters.delete(action.payload);
      } else {
        newCurveFilters.add(action.payload);
      }
      return {
        ...state,
        curveFilters: newCurveFilters,
      };
    },
    [RESET_CURVE_FILTER]: (state: DiscountItemsSubset) => ({
      ...state,
      curveFilters: new Set<DiscountCurveStatus>([
        DiscountCurveStatus.ACTIVE,
        DiscountCurveStatus.INACTIVE,
      ]),
    }),
    [FETCH_USER_OPERATORS]: (state: DiscountItemsSubset) => ({
      ...state,
      userOperatorsLoading: true,
    }),
    [FETCH_USER_OPERATORS_SUCCESS]: (state: DiscountItemsSubset, action: Action<Operator[]>) => ({
      ...state,
      userOperatorsLoading: false,
      userOperators: action.payload,
    }),

    [FETCH_DISCOUNT_HISTORY]: (state: DiscountItemsSubset) => ({
      ...state,
      discountHistoryFetchState: {
        loading: true,
        loaded: false,
        error: null,
      },
    }),
    [FETCH_DISCOUNT_HISTORY_SUCCESS]: (state: DiscountItemsSubset, action: Action<any>) => {
      const discountHistory = get(
        action,
        'data.priceableItem.discountsHistory',
        [] as DiscountHistory[],
      );
      return {
        ...state,
        discountHistoryFetchState: {
          loading: false,
          loaded: true,
          error: null,
        },
        discountHistory,
      };
    },
    [SET_IDS_CANCEL_FUTURE_DISCOUNT]: (state: DiscountItemsSubset, action: Action<string[]>) => ({
      ...state,
      cancelFutureDiscountIds: action.payload,
    }),
    [CANCEL_FUTURE_DISCOUNT]: (state: DiscountItemsSubset) => ({
      ...state,
      cancelFutureDiscountLoading: true,
    }),
    [CANCEL_FUTURE_DISCOUNT_SUCCESS]: (state: DiscountItemsSubset, action: Action<string[]>) => {
      const priceableItems = discountItemsSubsetSelector(state) ?? [];
      const newPriceableItems = setFutureDiscountsNull(priceableItems, action.payload);

      return {
        ...state,
        allDiscountItems: newPriceableItems,
        cancelFutureDiscountLoading: false,
        cancelFutureDiscountIds: [],
      };
    },
  },
  initialState.discountItems,
);
