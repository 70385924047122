import * as React from 'react';
import { ReactElement } from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import PromotionEditHistoryCreatedAt from '../promotionEditHistoryCreatedAt';
import PromotionEditHistoryCreatedBy from '../promotionEditHistoryCreatedBy';

function EditHistoryGroupRenderer(params: ICellRendererParams): ReactElement {
  const historyRecord = params.node?.allLeafChildren[0]?.data;

  return (
    <>
      <PromotionEditHistoryCreatedAt createdAtString={historyRecord.createdAt} />
      {' by '}
      <PromotionEditHistoryCreatedBy createdBy={historyRecord.createdBy} />
    </>
  );
}

export default EditHistoryGroupRenderer;
