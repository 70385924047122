import styled from 'styled-components';
import { List } from 'semantic-ui-react';
import { darkBlue, lightestGray } from '../../../../styles/colors';

// eslint-disable-next-line import/prefer-default-export
export const OnDemandGridWrapper = styled.div`
  height: 100vh;
  max-height: calc(100vh - 250px);
  margin: 12px 40px 0;
`;

export const PriceHistoryList = styled(List)`
  margin-top: 10px;
  padding: 0;
  margin-left: 0;
  list-style: none;
`;

export const PriceHistoryProductContainer = styled.div`
  margin-top: 20px;
`;

export const PriceHistoryContainer = styled.div`
  flex: 0 0 auto;
  width: 100%;

  &.open {
    padding-bottom: 28px;

    > .section-header .toggle-accordion-icon::before {
      transform: rotate(180deg);
    }

    > .section-body {
      height: auto;
    }
  }

  &.closed {
    > .section-body {
      height: 0;
    }
  }
`;

export const PriceHistoryBlock = styled.div`
  margin-top: 25px;
  margin-left: 20px;
`;

export const PriceHistoryListItem = styled.li`
  flex: 0 0 auto;
  line-height: 35px;
  font-size: 12px;
  font-weight: 400;
  color: ${darkBlue};

  &:nth-child(even) {
    background: ${lightestGray};
  }
`;
