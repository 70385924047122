import styled from 'styled-components';
import { FlexVerticalHelper, ListReset } from '../../../../styles/app.styled';
import { darkBlue, pureBlack } from '../../../../styles/colors';

export const PromoPageContentColumn = styled.div`
  flex: 0 0 100%;
  margin-bottom: 35px;
  height: calc(100vh - 275px);
  padding-bottom: 10px;
`;

export const PromoPageTitle = styled.h1`
  display: block;
  padding: 0;
  margin: 20px 0 !important;
  line-height: 16px;
  font-size: 16px;
  font-weight: 600;
  color: ${darkBlue};
`;

export const PromoPageSubTitle = styled.h4`
  padding: 0;
  width: 100px;
  margin: 0 18px 0 0;
  line-height: 32px;
  font-size: 16px;
  font-weight: 500;
  color: ${darkBlue};
  white-space: nowrap;
`;

export const PromoPageRowWrapper = styled.div`
  div.ui.checkbox > input {
    top: 4px;
  }
  .relative {
    > input,
    > textarea {
      width: 300px;
      padding: 0.5rem 0.75rem;
      resize: none;
      border: 1px solid #b0b0b0;
      margin: 0;
      outline: none;
      -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
      text-align: left;
      font-family: Apercu, Helvetica Neue, Arial, Helvetica, sans-serif;
      background: #fff;
      color: #001e33;
      border-radius: 0.25rem;
      transition: box-shadow 0.1s ease, border-color 0.1s ease;
      box-shadow: none;
      &.small-area {
        width: 150px;
      }
    }
  }
  .relative > input[type='number']::-webkit-inner-spin-button,
  .relative > input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }

  &:last-of-type {
    margin: 0;
  }
`;

export const InlineWrapper = styled.div`
  > * {
    display: inline-block;
  }
`;

export const ExampleCodeField = styled.p`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const CodePreviewBox = styled.div`
  min-width: 0;
`;
export const PromoPageRow = styled.div`
  height: 60px;
  ${ListReset};
  ${FlexVerticalHelper};
  flex-direction: row;
  margin: 10px 0 0;
  justify-content: space-between;
  &.align-center {
    justify-content: center;
  }
  > i {
    width: 300px;
  }
  &.full-width {
    flex-direction: column;
    height: 100%;
    margin: 0;
  }
  &.promo-table {
    height: auto;
    display: block;
    margin: 0;
  }
  > .load-more {
    margin-top: 10px;
    padding: 10px;
  }
  > div {
    &:not(:first-of-type) {
      margin: 0 50px;
    }
    display: flex;
    align-items: center;
    height: 75px;
    line-height: 30px;
    margin: 0 50px 0 0;
    font-size: 14px;
    font-weight: 500;
    color: ${pureBlack};
    &.full-width {
      margin: 0 auto 0 0;
      height: fit-content;
    }
    &.time-zone-picker {
      > div {
        width: 300px;
        > ul {
          max-height: 100px;
          background: white;
        }
      }
    }
    &.max-width {
      width: 414px;
    }
    > p {
      margin: 0 10px 0;
    }
  }
`;
