import { ReactElement, useState } from 'react';
import * as React from 'react';
import { ValueGetterParams } from 'ag-grid-community';
import { isBefore } from 'date-fns';
import { Toggle } from '@wework/ray2';
import PromotionsEnabledConfirm from '../promotionsEnabledConfirm';
import { trackAnalytics } from '../../../../../utils/analytics/helpers';

const PromotionEnabledRenderer = (params: ValueGetterParams): ReactElement => {
  const { id, enabled } = params.data;
  const endDate = params.data.codes.length === 1 ? params.data.codes[0].endDate : undefined;
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const now = new Date();

  const trackEnableTogglePromotion = () =>
    trackAnalytics(`Promotion - Enable Toggle Clicked`, {
      workflow: `Promotion - Enable Toggle Clicked`,
      object_type: 'toggle',
      object_name: `Enable Toggle`,
    });

  if (!params.data) {
    return <></>;
  }

  return (
    <div>
      <Toggle
        checked={enabled}
        disabled={endDate && isBefore(new Date(endDate), now)}
        onChange={() => {
          setConfirmationModalOpen(true);
          trackEnableTogglePromotion();
        }}
      />
      <PromotionsEnabledConfirm
        closeModal={() => setConfirmationModalOpen(false)}
        promotionsEnabledInput={{
          ids: [id],
          enabled: !enabled,
        }}
        isOpen={confirmationModalOpen}
      />
    </div>
  );
};

export default PromotionEnabledRenderer;
