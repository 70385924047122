import { RowNode } from 'ag-grid-community';
import { StoreInterface } from '../../../../../../utils/store/store.interface';
import {
  StandardPricingItemsSubset,
  StandardPricingPagedItem,
} from '../../entities/standardPricingItems';

const standardPricingItemsSelector = (state: StoreInterface) => state?.standardPricingItems;

export const standardPricingItemsSubsetSelector = (
  state: StandardPricingItemsSubset,
): StandardPricingPagedItem[] => state?.allItems;

export const standardPricingItemsSubsetSelectorStore = (
  state: StoreInterface,
): StandardPricingPagedItem[] => standardPricingItemsSelector(state)?.allItems;

export const standardPricingEditModeSelector = (state: StoreInterface): boolean =>
  standardPricingItemsSelector(state)?.editMode;

export const standardPricingCancelPricesLoadingSelector = (state: StoreInterface): boolean =>
  standardPricingItemsSelector(state)?.cancelFuturePricesLoading;

export const standardPricingRevertPricesLoadingSelector = (state: StoreInterface): boolean =>
  standardPricingItemsSelector(state)?.revertWorkingPricesLoading;

export const standardPricingSelectedNodesSelector = (state: StoreInterface): RowNode[] =>
  standardPricingItemsSelector(state)?.selectedNodes;

export const standardPricingBatchFetchStoreSelector = (state: StoreInterface): boolean =>
  standardPricingItemsSelector(state)?.batchFetch;

export const standardPricingListPriceModeSelector = (state: StoreInterface): boolean =>
  standardPricingItemsSelector(state)?.isListPriceMode;

export const batchEditLoadingSelectorStore = (state: StoreInterface): boolean =>
  standardPricingItemsSelector(state)?.batchEditLoading;
