import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { useSelector } from 'react-redux';
import 'ag-grid-enterprise';
import { GridApi, GridReadyEvent } from 'ag-grid-community';
import { orderBy } from 'lodash';
import { AgGridReact } from 'ag-grid-react';
import {
  AgGridWrapper,
  TableLayoutCurveManagement,
  ViewingTableCount,
} from './curveManagementTableAGGrid.styled';
import {
  batchFetchStateSelectorDiscountCurves,
  discountCurvesFilterSelector,
  discountCurvesItemsSelector,
} from '../../store/modules/discountItems/discountItems.selector';
import './curveManagementAGGridThemeOverrides.css';
import TableFilterBarCurveManagement from './tableFilterBar';
import { columnDefs, gridOptions } from './curveManagementTableAGGrid.settings';
import { GridCustomWrapper, MarginWrapper } from '../../../../styles/app.styled';
import { getCurveStatus } from '../discountItems.helpers';

function CurveManagementTable(): ReactElement {
  const [gridApi, setGridApi] = useState<GridApi | null>(null);

  const batchFetch = useSelector(batchFetchStateSelectorDiscountCurves);
  const allDiscountCurves = useSelector(discountCurvesItemsSelector);
  const discountCurvesFilter = useSelector(discountCurvesFilterSelector);

  /**
   * OnGridReady is an AgGrid event that receives a param with access to the api.
   * @param params Parameter passed from AG grid.
   */
  const onGridReady = (params: GridReadyEvent): void => {
    if (params.columnApi) {
      params.columnApi.autoSizeAllColumns();
    }

    // setColumnApi(params?.columnApi);
    setGridApi(params?.api);
  };

  /**
   * Applying filter in the grid
   */
  const setGridData = () => {
    const sortedAllDiscountCurves = orderBy(
      allDiscountCurves ?? [],
      ['updatedAt', 'createdAt'],
      ['desc'],
    ).filter(value => discountCurvesFilter.has(getCurveStatus(value)));

    if (gridApi) {
      if (!batchFetch) {
        gridApi.setRowData(sortedAllDiscountCurves);
        gridApi.hideOverlay();
      } else if (batchFetch) {
        gridApi.showLoadingOverlay();
      }
      gridApi.sizeColumnsToFit();
      gridApi.resetRowHeights();
    }
  };
  useEffect(setGridData, [allDiscountCurves, batchFetch, gridApi, discountCurvesFilter]);

  const onGridSizeChanged = useCallback(() => {
    if (gridApi) {
      gridApi.sizeColumnsToFit();
      gridApi.resetRowHeights();
    }
  }, [gridApi]);

  return (
    <TableLayoutCurveManagement>
      <TableFilterBarCurveManagement agGridApi={gridApi} />
      <GridCustomWrapper>
        <MarginWrapper>
          <ViewingTableCount>
            <p>{batchFetch ? 'Loading...' : `Viewing ${allDiscountCurves?.length} Curves`}</p>
          </ViewingTableCount>
        </MarginWrapper>
        <AgGridWrapper className="ag-theme-alpine discounts-curve-management-grid">
          <AgGridReact
            gridOptions={gridOptions}
            columnDefs={columnDefs}
            onGridReady={onGridReady}
            onGridSizeChanged={onGridSizeChanged}
            enableBrowserTooltips
          />
        </AgGridWrapper>
      </GridCustomWrapper>
    </TableLayoutCurveManagement>
  );
}

export default CurveManagementTable;
