import * as React from 'react';
import { ReactElement, useState } from 'react';
import { Popup } from 'semantic-ui-react';
import { Chip, DatePicker } from '@wework/dieter-ui';
import cn from 'classnames';
import { addDays, endOfDay, format, isAfter, isBefore, startOfDay, subDays } from 'date-fns';
import { IconName } from '@wework/ray2';
import { utcToZonedTime } from 'date-fns-tz';
import { Modifier } from 'react-day-picker';
import InfoTooltip from '../../../../sharedComponents/tooltip/infoTooltip';
import { INSTANT_FORMAT } from '../../../../utils/store/store.constants';
import { DateRangeInfo } from './promotion.styled';

interface PromotionDateRangeProps {
  startDate?: Date;
  endDate?: Date;
  timeZone?: string;
  setStartDate: (date?: Date) => void;
  setEndDate: (date?: Date) => void;
  pastDisabled?: boolean;
  showTooltip?: boolean;
}

function PromotionCodeDateRange({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  timeZone,
  pastDisabled = true,
  showTooltip = true,
}: PromotionDateRangeProps): ReactElement {
  const [startDatePickerOpen, setStartDatePickerOpen] = useState<boolean>(false);
  const [endDatePickerOpen, setEndDatePickerOpen] = useState<boolean>(false);

  const startDateDisabledBefore = () => {
    if (!pastDisabled) {
      return undefined;
    }

    const tomorrow = addDays(new Date(), 1);
    const tomorrowInTimeZone = timeZone
      ? utcToZonedTime(format(tomorrow, INSTANT_FORMAT), timeZone)
      : tomorrow;
    tomorrowInTimeZone.setHours(0, 0, 0, 0);

    return tomorrowInTimeZone;
  };
  const startDateDisabledAfter = () => endDate && subDays(endDate, 1);

  const getStartDateDisabledDays = (): Modifier[] => {
    const startDisabledBefore = startDateDisabledBefore();
    const startDisabledAfter = startDateDisabledAfter();

    const modifiers: Modifier[] = [];
    if (startDisabledBefore) {
      modifiers.push({ before: startDisabledBefore });
    }
    if (startDisabledAfter) {
      modifiers.push({ after: startDisabledAfter });
    }
    return modifiers;
  };

  const isStartDateInRange = (date: Date) => {
    const startDisabledBefore = startDateDisabledBefore();
    const startDisabledAfter = startDateDisabledAfter();

    return (
      !(startDisabledBefore && isBefore(date, startDisabledBefore)) &&
      !(startDisabledAfter && isAfter(date, startDisabledAfter))
    );
  };

  const endDateDisabledBefore = () => {
    const startDisabledBefore = startDate || startDateDisabledBefore();

    return startDisabledBefore ? addDays(startDisabledBefore, 1) : undefined;
  };

  const isEndDateInRange = (date: Date) => {
    const endDisabledBefore = endDateDisabledBefore();

    return !(endDisabledBefore && isBefore(date, endDisabledBefore));
  };

  const getEndDateDisabledDays = (): Modifier | undefined => {
    const endDisabledBefore = endDateDisabledBefore();

    return endDisabledBefore ? { before: endDisabledBefore } : undefined;
  };

  return (
    <>
      <Popup
        basic
        position="top left"
        trigger={
          <Chip
            className={cn({ active: !!startDate })}
            active={!!startDate}
            onClick={() => setStartDatePickerOpen(prevState => !prevState)}
          >
            {startDate ? format(startDate, 'MMM d, yyyy') : 'Select Date'}
          </Chip>
        }
        on="click"
        className="publish-data-popup"
        open={startDatePickerOpen}
        onClose={() => setStartDatePickerOpen(prevState => !prevState)}
        closeOnDocumentClick
        closeOnEscape
      >
        <Popup.Content>
          <DatePicker
            selectedDays={startDate}
            onDayClick={(day, { selected }) => {
              if (isStartDateInRange(day)) {
                setStartDate(selected ? undefined : startOfDay(day));
                setStartDatePickerOpen(false);
              }
            }}
            disabledDays={getStartDateDisabledDays()}
          />
        </Popup.Content>
      </Popup>
      <span> / </span>
      <Popup
        basic
        position="top left"
        trigger={
          <Chip
            className={cn({ active: !!endDate })}
            active={!!endDate}
            onClick={() => setEndDatePickerOpen(prevState => !prevState)}
          >
            {endDate ? format(endDate, 'MMM d, yyyy') : 'Select Date'}
          </Chip>
        }
        on="click"
        className="publish-data-popup"
        open={endDatePickerOpen}
        onClose={() => setEndDatePickerOpen(prevState => !prevState)}
        closeOnDocumentClick
        closeOnEscape
      >
        <Popup.Content>
          <DatePicker
            selectedDays={endDate}
            onDayClick={(day, { selected }) => {
              if (isEndDateInRange(day)) {
                setEndDate(selected ? undefined : endOfDay(day));
                setEndDatePickerOpen(false);
              }
            }}
            disabledDays={getEndDateDisabledDays()}
          />
        </Popup.Content>
      </Popup>
      {showTooltip && (
        <InfoTooltip
          popupContent={
            <DateRangeInfo>
              {startDate
                ? `Promotion code starts on ${format(startDate, 'MMM d, yyyy')}`
                : `Promotion code starts immediately`}
              {endDate
                ? ` and expires on ${format(endDate, 'MMM d, yyyy')}.`
                : ' and does not expire.'}
            </DateRangeInfo>
          }
          iconName={IconName.CIRCLE_QUESTION}
        />
      )}
    </>
  );
}

export default PromotionCodeDateRange;
