import { format, formatInTimeZone } from 'date-fns-tz';
import { toast } from 'react-toastify';
import {
  find,
  forEach,
  includes,
  isEmpty,
  isEqual,
  map,
  max,
  reject,
  startCase,
  xorWith,
} from 'lodash';
import {
  GetMainMenuItemsParams,
  MenuItemDef,
  ProcessCellForExportParams,
  ProcessHeaderForExportParams,
  ValueFormatterParams,
} from 'ag-grid-community';
import { RowNode } from 'ag-grid-community/dist/lib/entities/rowNode';
import { put } from 'redux-saga/effects';
import {
  capitalizeFunc,
  inputNumberFormatter,
} from 'app/pricing/standardPricing/components/helpers';
import { maxIntegerValue, maxJavaIntegerValue } from 'app/breakeven/components/constants';
import { Unassigned } from './sharedTypes';
import ROUTES from './routes/routes';
import { PriceableItemFilter } from '../sharedStore/entities/priceableItemFilters';
import { CLEAR_GENERATED_URL } from '../sharedStore/modules/generateLink/generateLink.ducks';
import { Product } from '../generated/voyager/graphql';

/**
 * Adding formatting to currency on currency ISO Code and value.
 * @param currency
 * @param number
 */
export function intlCurrencyFormatter(currency: string, number: number): string {
  const options = currency
    ? { style: 'currency', currency, minimumFractionDigits: 0, maximumFractionDigits: 2 }
    : {};
  const locales = navigator.language;

  return new Intl.NumberFormat(locales, options).format(number);
}

/**
 * Adding formatting to currency on currency ISO Code and value.
 * @param number
 */
export function intlNumberFormatter(number: number): string {
  const locales = navigator.language;

  return new Intl.NumberFormat(locales).format(number);
}

export const getBooleanValue = (value: any): string => {
  if (value === true) {
    return 'Yes';
  } else if (value === false) {
    return 'No';
  }
  return '-';
};

/**
 * Format received value to percentage or to number
 * @param num
 */
export const getValPercentage = (num: number | Unassigned): number =>
  Number(((num ?? 0) * 100).toFixed(0));

export const getValFormat = (currencyIsoCode: string, num: number | Unassigned): string =>
  intlCurrencyFormatter(currencyIsoCode, Number((num ?? 0).toFixed(0)));

export const formatDate = (date: number | string | Date, timeZone?: string): string =>
  timeZone ? formatInTimeZone(new Date(date), timeZone, 'PP') : format(new Date(date), 'PP');

export const formatDateFromDateString = (date: string): string =>
  format(new Date(`${date}T00:00:00`), 'PP');

export const dateAtLocationZoneToStringVF = (params: ValueFormatterParams): string => {
  if (!params.value) {
    return '-';
  }

  return formatDate(params.value, params.data.timeZone ?? 'UTC');
};

/**
 * @description
 * Takes an Array<V>, and a grouping function,
 * and returns a Map of the array grouped by the grouping function.
 *
 * @param list An array of type V.
 * @param keyGetter A Function that takes the the Array type V as an input, and returns a value of type K.
 *                  K is generally intended to be a property key of V.
 *
 * @returns Map of the array grouped by the grouping function.
 */
export function groupBy<K, V>(list: Array<V>, keyGetter: (_: V) => K): Map<K, Array<V>> {
  const newMap = new Map<K, Array<V>>();
  list.forEach(item => {
    const key = keyGetter(item);
    const collection = newMap.get(key);
    if (!collection) {
      newMap.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return newMap;
}

/**
 * @description Generates random boolean value
 *
 * @return boolean
 */
export const randomBoolean = (): boolean => Math.random() < 0.5;

/**
 *
 */
export const getNumberOfFilterApplied = (allFilters: PriceableItemFilter[]): string => {
  const skuFilter = ['hotDeskSelected', 'dedicatedDeskSelected', 'personalOfficeSelected'];

  let len = 0;
  let skuFlag = false;
  // Doing this logic to treat sku filters as one filter.
  forEach(allFilters, (eachFilter: PriceableItemFilter) => {
    if (includes(skuFilter, eachFilter?.filterName)) {
      if (!skuFlag) {
        skuFlag = true;
        len += 1;
      }
    } else {
      len += 1;
    }
  });

  if (len > 1) {
    return `${len} Filter(s) Applied`;
  } else if (len === 1) {
    return '1 Filter Applied';
  }
  return 'Apply Filters';
};

export function getPhysicalAttributesDetails(
  params: ProcessCellForExportParams,
  type: string,
): string {
  const hasWindow: boolean =
    find(params.node?.data?.attributes, ['label', 'hasWindow'])?.value === 'true';
  const internalRoomVal: string | Unassigned = find(params.node?.data?.attributes, [
    'label',
    'internalRoomCount',
  ])?.value;

  const internalRoomPresent: boolean = internalRoomVal !== 'null' && internalRoomVal !== '0';
  const internalRoomCount = !internalRoomVal || internalRoomVal === 'null' ? '0' : internalRoomVal;

  if (type === 'window') {
    return `${hasWindow ? 'Yes' : 'No'}`;
  }
  return internalRoomPresent && !internalRoomCount ? `${internalRoomCount}` : '-';
}

const productTypeColumns = ['Current Prices', 'Published At'];
const idColumns = ['rowSelector', 'selectionCheckbox'];

const isColumnPartOfArray = (columnName: string, arrayColumns: string[]): boolean =>
  arrayColumns.indexOf(columnName) > -1;

export function processHeaderCallback(
  params: ProcessHeaderForExportParams,
  products?: Product[],
): string {
  const columnId = params.column.getColId();
  if (columnId === 'physicalAttributes') {
    return 'Has Window';
  } else if (columnId === 'physicalAttributes2') {
    return 'Internal Room Present';
  } else if (isColumnPartOfArray(columnId, idColumns)) {
    return 'Reservable Id';
  } else if (isColumnPartOfArray(columnId.split('|')[1], productTypeColumns)) {
    const columnIdSplit = columnId.split('|');
    return startCase(
      `${products?.find(eacProd => eacProd.id === columnIdSplit[0])?.name ?? 'NULL'} (${
        columnIdSplit[1]
      })`,
    );
  }

  return startCase(params.column.getColId());
}

/**
 * Validate input number value
 */
interface ConditionParams {
  fields: Array<string>;
  predicate?: boolean;
}

interface ValidationFieldGroups {
  percentValues?: ConditionParams;
  containsDecimalValues?: ConditionParams;
  wholeNumberValues?: ConditionParams;
  valuesIncludedInCalculation?: ConditionParams;
}

export function validateInputNumberValue(
  event: any,
  field: string,
  vFG: ValidationFieldGroups,
  onValid: (value: number) => void,
): void {
  const currentValue = inputNumberFormatter(event?.target?.value);
  const numericalValue = parseFloat(currentValue);
  if (
    vFG.percentValues?.fields.includes(field) &&
    (vFG.percentValues?.predicate === true || vFG.percentValues?.predicate === undefined) &&
    (numericalValue > 100 || numericalValue < 0)
  ) {
    if (!toast.isActive('percentageValue')) {
      toast.error('Percent values must be from 0 to 100.', {
        position: toast.POSITION.TOP_CENTER,
        toastId: 'percentageValue',
      });
    }
  } else if (
    vFG.containsDecimalValues?.fields.includes(field) &&
    (vFG.containsDecimalValues?.predicate === true ||
      vFG.containsDecimalValues?.predicate === undefined) &&
    numericalValue % 1 > 0
  ) {
    if (!toast.isActive('containsDecimal')) {
      toast.error('Number cannot contain decimal value.', {
        position: toast.POSITION.TOP_CENTER,
        toastId: 'containsDecimal',
      });
    }
  } else if (
    vFG.wholeNumberValues?.fields.includes(field) &&
    (vFG.wholeNumberValues?.predicate === true || vFG.wholeNumberValues?.predicate === undefined) &&
    numericalValue > maxJavaIntegerValue
  ) {
    if (!toast.isActive('greaterThanJavaIntRange')) {
      toast.error(`Value cannot be more than ${maxJavaIntegerValue}.`, {
        position: toast.POSITION.TOP_CENTER,
        toastId: 'greaterThanJavaIntRange',
      });
    }
  } else if (numericalValue < 0) {
    if (!toast.isActive('nonNegativeValue')) {
      toast.error('Value can only be positive.', {
        position: toast.POSITION.TOP_CENTER,
        toastId: 'nonNegativeValue',
      });
    }
  } else if (numericalValue > maxIntegerValue) {
    if (!toast.isActive('greaterThanIntRange')) {
      toast.error(`Value cannot be more than ${maxIntegerValue}.`, {
        position: toast.POSITION.TOP_CENTER,
        toastId: 'greaterThanIntRange',
      });
    }
  } else if (
    vFG.valuesIncludedInCalculation?.fields.includes(field) &&
    (vFG.valuesIncludedInCalculation?.predicate === true ||
      vFG.valuesIncludedInCalculation?.predicate === undefined)
  ) {
    if (!toast.isActive('resultGreaterThanIntRange')) {
      toast.error(`Breakeven and SG&A result values cannot be more than ${maxIntegerValue}.`, {
        position: toast.POSITION.TOP_CENTER,
        toastId: 'resultGreaterThanIntRange',
      });
    }
  } else {
    onValid(numericalValue);
  }
}

// function return main menu items without 'Reset Columns' item
export function getMainMenuItemsWithoutReset(
  params: GetMainMenuItemsParams,
): (string | MenuItemDef)[] {
  const resetColumnsMenuIndex = params.defaultItems.findIndex(item => item === 'resetColumns');
  const autoSizeThisMenuIndex = params.defaultItems.findIndex(item => item === 'autoSizeThis');
  params.defaultItems.splice(resetColumnsMenuIndex, 1);
  params.defaultItems.splice(autoSizeThisMenuIndex, 1);
  return params.defaultItems;
}

export const getMaxPageInSkuGroup = (skuGroup: RowNode): number =>
  max(map(skuGroup.childrenAfterSort, 'data.page')) ?? 0;

// Return App Header;
export const titleName = (pathname: string | undefined) => {
  if (pathname?.startsWith(ROUTES.STANDARD_PRICING)) {
    return 'Pricing Management';
  }
  if (pathname?.startsWith(ROUTES.DISCOUNT_TABLEVIEW)) {
    return 'Discounts Management';
  }
  return 'Breakeven Price';
};

export const modifyOldFilterHelper = (
  oldFilter: PriceableItemFilter[],
  skuFilterSetFlag: boolean,
  yesNoFilterSetFlag: boolean,
  minMaxFilterSetFlag: boolean,
  discountExpiryFilterSetFlag: boolean,
  userActionFiltersPricingSet?: boolean,
) => {
  let oldFilterModified = skuFilterSetFlag
    ? reject(
        oldFilter,
        eachFilter =>
          eachFilter?.filterName === 'hotDeskSelected' ||
          eachFilter?.filterName === 'dedicatedDeskSelected' ||
          eachFilter?.filterName === 'personalOfficeSelected',
      )
    : oldFilter;
  oldFilterModified = yesNoFilterSetFlag
    ? reject(
        oldFilterModified,
        eachFilter =>
          eachFilter?.filterName === 'hasWindow' || eachFilter?.filterName === 'internalRoom',
      )
    : oldFilterModified;
  oldFilterModified = discountExpiryFilterSetFlag
    ? reject(
        oldFilterModified,
        eachFilter =>
          eachFilter?.filterName === 'DiscountExpiringSoon' ||
          eachFilter?.filterName === 'No Discount',
      )
    : oldFilterModified;

  oldFilterModified = minMaxFilterSetFlag
    ? reject(oldFilterModified, eachFilter => eachFilter?.filterName === 'sku')
    : oldFilterModified;
  return userActionFiltersPricingSet
    ? reject(oldFilterModified, eachFilter => eachFilter?.filterName === 'emptyPriceSelected')
    : oldFilterModified;
};

export function* clearGeneratedUrlWorker() {
  yield put({ type: CLEAR_GENERATED_URL });
}

export const isArrayEqual = (x: any, y: any): boolean => isEmpty(xorWith(x, y, isEqual));

export const toTitleCase = (str: string | Unassigned): string => {
  const st = str ? str.split('@')[0].replace('.', ' ').replace('_', ' ') : '';
  return st.replace(/\w\S*/g, capitalizeFunc);
};
