import * as React from 'react';
import { ReactElement, useState } from 'react';
import { withRequestedAuthz } from '@wework/we-auth-react';
import { get } from 'lodash';
import { Button } from '@wework/ray2';
import { Input } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { promotionWithChangelogSelector } from 'app/promotion/store/promotion.selector';
import {
  TableFilter,
  TableFilterBarWrapper,
  TableFilterColumnsWrap,
  TableFilterRow,
} from '../../../../styles/sharedTableFilterBar.styled';
import { AuthzProps } from '../../../../utils/constants';
import EmployeePermissions from '../../../../utils/store/permissions';
import { routerHistory } from '../../../../utils/store/configureStore';
import ROUTES from '../../../../utils/routes/routes';
import PromotionFilterSidePanel from './promotionFilterSidePanel';
import { PromotionTableFilter } from '../../store/entities/promotionItem';
import { INITIAL_FILTER } from '../promotionDisplayTable/promotionViewTableAGGrid';
import { trackAnalytics } from '../../../../utils/analytics/helpers';
import PromotionEditHistorySidePanel from '../promotionEditHistory/promotionEditHistorySidePanel';

export interface PromotionFilterProps {
  filter: PromotionTableFilter;
  setFilter: (filter: PromotionTableFilter, debounce?: boolean) => void;
}

interface PromotionTableFilterBarProps extends AuthzProps, PromotionFilterProps {}

function PromotionTableFilterBar({
  requestedPermissions,
  filter,
  setFilter,
}: PromotionTableFilterBarProps): ReactElement {
  const [sidePanelOpen, setSidePanelOpen] = useState(false);

  const promotionWithChangelog = useSelector(promotionWithChangelogSelector);

  const getAppliedFiltersCount = (): number =>
    Object.keys(filter).filter(
      key => key !== 'nameOrCodeContains' && key !== 'timeZone' && filter[key] !== undefined,
    ).length;

  const getMoreFiltersText = (): string => {
    const appliedFilters = getAppliedFiltersCount();

    return `More filters${appliedFilters > 0 ? ` (${appliedFilters} applied)` : ''}`;
  };

  const trackCreateNewPromotionClick = () =>
    trackAnalytics(`Promotion - Create New Promotion button click`, {
      workflow: `Promotion - create new promotion`,
      object_type: 'button',
      object_name: `Create New Promotion`,
    });

  const trackSearchPromotionInGrid = (searchTxt: string) =>
    trackAnalytics(`Promotion - Search Promotion in Grid used`, {
      workflow: `Promotion - Search promotion`,
      object_type: 'textarea',
      object_name: `Search promotion`,
      object_value: searchTxt,
    });

  return (
    <TableFilterBarWrapper>
      <TableFilterColumnsWrap>
        <TableFilterRow>
          <TableFilter>
            <Input
              className={'promotions-search'}
              value={filter?.nameOrCodeContains ?? ''}
              icon="search"
              iconPosition="left"
              placeholder="Search by name or code"
              onChange={event => {
                const searchTxt = event.target.value;
                setFilter({ ...filter, nameOrCodeContains: searchTxt }, true);
                trackSearchPromotionInGrid(searchTxt);
              }}
            />
          </TableFilter>
          <TableFilter>
            <Button
              theme={`${getAppliedFiltersCount() > 0 ? 'fill' : 'outline'}`}
              size={'medium'}
              className="side-panel-trigger"
              onClick={() => setSidePanelOpen(!sidePanelOpen)}
            >
              {getMoreFiltersText()}
            </Button>
          </TableFilter>
          <TableFilter>
            <Button theme={'outline'} size={'medium'} onClick={() => setFilter(INITIAL_FILTER)}>
              Clear all
            </Button>
          </TableFilter>
        </TableFilterRow>
        <TableFilterRow>
          <TableFilter className="right-align">
            {get(requestedPermissions, EmployeePermissions.voyager_promotions_manage, false) && (
              <Button
                size="medium"
                theme="fill"
                onClick={() => {
                  routerHistory.push({
                    pathname: ROUTES.PROMOTION_CREATE_PAGE,
                  });
                  trackCreateNewPromotionClick();
                }}
              >
                Create New Promotion
              </Button>
            )}
          </TableFilter>
        </TableFilterRow>
      </TableFilterColumnsWrap>
      <PromotionFilterSidePanel
        isOpen={sidePanelOpen}
        close={() => setSidePanelOpen(false)}
        filter={filter}
        setFilter={setFilter}
      />
      {promotionWithChangelog && (
        <PromotionEditHistorySidePanel promotionWithChangelog={promotionWithChangelog} />
      )}
    </TableFilterBarWrapper>
  );
}

export default withRequestedAuthz<PromotionTableFilterBarProps>({
  permissions: [EmployeePermissions.voyager_promotions_manage],
})(PromotionTableFilterBar);
