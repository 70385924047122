import { gql } from '@apollo/client';
import promotionFragment from './promotionItems.fragment';

export const FETCH_ALL_PROMOTIONS_QUERY = gql`
  query Promotions(
    $filter: PromotionFilter
    $pageInfo: PageInfo!
    $sortInfos: [SortInfo!]
    $codeFilter: PromotionCodeFilter
    $codePageInfo: PageInfo
    $codeSortInfos: [SortInfo!]
  ) {
    promotions(filter: $filter, pageInfo: $pageInfo, sortInfos: $sortInfos) {
      ...promotion
    }
  }
  ${promotionFragment}
`;

export const FETCH_PROMOTION_BY_ID_QUERY = gql`
  query PromotionById(
    $id: UUID!
    $codeFilter: PromotionCodeFilter
    $codePageInfo: PageInfo
    $codeSortInfos: [SortInfo!]
  ) {
    promotion(id: $id) {
      ...promotion
      requestor
      productLimitations
      geoHierarchyLimitations
      distribution {
        month
        discount
      }
    }
  }
  ${promotionFragment}
`;

export const FETCH_PROMOTION_WITH_CHANGELOG_QUERY = gql`
  query PromotionWithChangelog($id: UUID!) {
    promotion(id: $id) {
      createdBy
      createdAt
      isBulkPromotion
      changelog {
        id
        changeType
        transactionId
        entityId
        updatedField
        oldValue
        newValue
        createdBy
        createdAt
      }
    }
  }
`;

export const FETCH_PROMOTION_PRODUCTS_QUERY = gql`
  query PromotionProducts {
    products(filter: { promotionsEnabled: true }) {
      id
      name
      businessLine
    }
  }
`;

export const FETCH_PROMOTION_LOCATIONS_QUERY = gql`
  query PromotionGeoHierarchies($filter: GeoHierarchyFilter) {
    geoHierarchies(filter: $filter) {
      id
      name
      parentId
      type
    }
  }
`;
