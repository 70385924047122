import { intersection, isEmpty, isEqual, times } from 'lodash';
import { format, isAfter, isBefore } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { TreeNode, TreeNodeProps } from 'react-dropdown-tree-select';
import { TagProps } from '@wework/dieter-ui/src/components/Label/Tag';
import {
  GeoHierarchy,
  Promotion,
  PromotionCode,
  PromotionInput,
  QueryPromotionsArgs,
} from '../../../generated/voyager/graphql';
import {
  PromotionCodeTableInput,
  PromotionTableInput,
  QueryPromotionsTableArgs,
} from './entities/promotionItem';
import { DATE_TIME_FORMAT, INSTANT_FORMAT } from '../../../utils/store/store.constants';
import { toTitleCase } from '../../../utils/helpers';

const ALPHANUMERIC_REGEXP = /^[a-zA-Z0-9]*$/;
const CODE_CHARSET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ123456789';
export const LARGE_NUMBER_OF_CODES = 1000;

export const validatePromotionInput = (
  promotion: PromotionTableInput,
  isUpdateInput: boolean,
): string[] => {
  const nameNotEmpty = !isEmpty(promotion.name?.trim());
  const approverNotEmpty = !isEmpty(promotion.approver?.trim());
  const distributionNotEmpty = !isEmpty(promotion.distribution);
  const errors: string[] = [];

  if (!nameNotEmpty) {
    errors.push('Name is required');
  }
  if (!approverNotEmpty) {
    errors.push('Approver is required');
  }
  if (!distributionNotEmpty) {
    errors.push('At least one distribution entry is required');
  }

  if (promotion.isBulkPromotion) {
    const prefix = promotion.bulkCodeCreation?.prefix;
    const suffix = promotion.bulkCodeCreation?.suffix;
    const codeAmount = promotion.bulkCodeCreation?.codeAmount;
    const codeLength = promotion.bulkCodeCreation?.generatedPartLength;
    const maxUsages = promotion.bulkCodeCreation?.maxUsages;
    if (!isUpdateInput && (!codeAmount || codeAmount < 1)) {
      errors.push('Code Amount must be greater than 1');
    }
    if (codeAmount || !isUpdateInput) {
      if (prefix && !prefix.match(ALPHANUMERIC_REGEXP)) {
        errors.push('Prefix must be an alphanumeric string');
      }
      if (suffix && !suffix.match(ALPHANUMERIC_REGEXP)) {
        errors.push('Suffix must be an alphanumeric string');
      }
      if (!codeLength || codeLength < 1) {
        errors.push('Code Length must be greater than 1');
      }
    }
    if (maxUsages && maxUsages < 1) {
      errors.push('Max usages must be > 0 or empty');
    }
  } else {
    const codesNotEmpty = isUpdateInput || !isEmpty(promotion.manualCodeCreation);
    const codesErrors = validateCodes(promotion.manualCodeCreation ?? []);
    if (!codesNotEmpty) {
      errors.push('At least one code is required');
    }
    errors.push(...codesErrors);
  }

  return errors;
};

const validateCodes = (codes: PromotionCodeTableInput[]): string[] => {
  const errors: string[] = [];

  codes.forEach((codeInput, index) => {
    const codeAlphanumeric = codeInput.code?.match(ALPHANUMERIC_REGEXP);
    const codeUnique =
      codes.filter(
        (ci, idx) => ci.code?.toLowerCase() === codeInput.code?.toLowerCase() && idx !== index,
      ).length === 0;
    const maxUsagesValid = !codeInput.maxUsages || codeInput.maxUsages >= (codeInput.usages ?? 0);

    if (!codeAlphanumeric) {
      errors.push(`Code in row ${index + 1} must be an alphanumeric string`);
    }
    if (!codeUnique) {
      errors.push(`Code in row ${index + 1} must be unique`);
    }
    if (!maxUsagesValid) {
      errors.push(`Code in row ${index + 1} max usages must be >= usages or empty`);
    }
  });

  return errors;
};

export const sanitizePromotionInput = (promotion: PromotionTableInput): PromotionInput => ({
  name: promotion.name ?? '',
  description: promotion.description,
  approver: promotion.approver ?? '',
  requestor: promotion.requestor,
  enabled: promotion.enabled ?? true,
  distribution:
    promotion.distribution?.map(distribution => ({
      month: distribution.month,
      discount: distribution.discount / 100,
    })) ?? [],
  manualCodeCreation: promotion.isBulkPromotion
    ? null
    : promotion.manualCodeCreation?.map(code => ({
        code: code.code ?? '',
        enabled: code.enabled ?? true,
        maxUsages: code.maxUsages,
        startDate: toDateTimeInput(code.startDate),
        endDate: toDateTimeInput(code.endDate),
        timeZone: code.timeZone ?? 'UTC',
      })),
  bulkCodeCreation: promotion.isBulkPromotion
    ? {
        codeAmount: promotion.bulkCodeCreation?.codeAmount,
        prefix: promotion.bulkCodeCreation?.prefix,
        suffix: promotion.bulkCodeCreation?.suffix,
        generatedPartLength: promotion.bulkCodeCreation?.generatedPartLength,
        startDate: toDateTimeInput(promotion.bulkCodeCreation?.startDate),
        endDate: toDateTimeInput(promotion.bulkCodeCreation?.endDate),
        timeZone: promotion.bulkCodeCreation?.timeZone ?? 'UTC',
        enabled: promotion.bulkCodeCreation?.enabled ?? true,
        maxUsages: promotion.bulkCodeCreation?.maxUsages,
      }
    : null,
  productLimitations: promotion.productLimitations ?? [],
  geoHierarchyLimitations: promotion.geoHierarchyLimitations ?? [],
  asyncProcessing: getNumberOfCodesToProcess(promotion) >= LARGE_NUMBER_OF_CODES,
});

const toDateTimeInput = (date?: Date): string | undefined => {
  if (!date) {
    return undefined;
  }

  return `${format(date, DATE_TIME_FORMAT)}`;
};

export const promotionToTableInput = (promotion: Promotion): PromotionTableInput => ({
  id: promotion.id,
  name: promotion.name,
  description: promotion.description,
  enabled: promotion.enabled,
  approver: promotion.approver,
  requestor: promotion.requestor,
  totalCodes: promotion.totalCodes,
  isBulkPromotion: promotion.isBulkPromotion,
  productLimitations: promotion.productLimitations,
  geoHierarchyLimitations: promotion.geoHierarchyLimitations,
  distribution:
    promotion.distribution?.map(distribution => ({
      discount: distribution.discount * 100,
      month: distribution.month,
    })) ?? [],
  manualCodeCreation: promotionCodesToTableInput(promotion.codes),
  bulkCodeCreation: promotion.isBulkPromotion
    ? {
        startDate: new Date(promotion.codes[0].startDate),
        endDate: promotion.codes[0].endDate && new Date(promotion.codes[0].endDate),
        timeZone: promotion.codes[0].timeZone,
        enabled: promotion.codes[0].enabled,
        maxUsages: promotion.codes[0].maxUsages,
      }
    : null,
});

export const promotionCodesToTableInput = (
  promotionCodes: PromotionCode[],
): PromotionCodeTableInput[] =>
  promotionCodes.map(code => ({
    isNew: false,
    isUpdated: false,
    tableKey: code.id,
    code: code.code,
    enabled: code.enabled,
    maxUsages: code.maxUsages,
    usages: code.usages,
    startDate: new Date(code.startDate),
    endDate: code.endDate && new Date(code.endDate),
    timeZone: code.timeZone,
  }));

export const sanitizePromotionsArgs = (args: QueryPromotionsTableArgs): QueryPromotionsArgs => ({
  ...args,
  filter: Object.keys(args.filter).some(key => key !== 'timeZone' && args.filter[key] !== undefined)
    ? {
        nameOrCodeContains: args.filter.nameOrCodeContains,
        enabled: args.filter.enabled,
        productLimitations: args.filter.productLimitations,
        geoHierarchyLimitations: args.filter.geoHierarchyLimitations,
        startDateOnOrAfter: toInstantInput(args.filter.startDateOnOrAfter, args.filter.timeZone),
        startDateOnOrBefore: toInstantInput(args.filter.startDateOnOrBefore, args.filter.timeZone),
        endDateOnOrAfter: toInstantInput(args.filter.endDateOnOrAfter, args.filter.timeZone),
        endDateOnOrBefore: toInstantInput(args.filter.endDateOnOrBefore, args.filter.timeZone),
      }
    : undefined,
});

const toInstantInput = (date: Date | undefined, timeZone: string): string | undefined => {
  if (!date) {
    return undefined;
  }

  const dateTime = format(date, INSTANT_FORMAT); // local to UTC
  return formatInTimeZone(dateTime, timeZone, INSTANT_FORMAT); // UTC with zone shift
};

export const buildCheckbox = (
  data: GeoHierarchy[],
  currentChild: GeoHierarchy,
  geoHierarchyLimitations: string[],
): TreeNodeProps => ({
  label: toTitleCase(currentChild.name),
  value: currentChild.id,
  checked: geoHierarchyLimitations.includes(currentChild.id),
  children: data
    .filter(geoHierarchy => geoHierarchy.parentId === currentChild.id)
    .map((geoHierarchy: GeoHierarchy) =>
      buildCheckbox(data, geoHierarchy, geoHierarchyLimitations),
    ),
});

export const getChildrenArray = (
  node: TreeNodeProps | TreeNode,
  excludeSelected?: boolean,
): string[] => {
  if (!node.children || !node.children.length) {
    return !excludeSelected || !node.checked ? [node.value] : [];
  }

  return node.children.flatMap(child => getChildrenArray(child, excludeSelected));
};

export const findNode = (id: string, nodes?: TreeNodeProps[] | TreeNode[]): TreeNode | undefined =>
  nodes?.find(node => node.value === id) ||
  findNode(
    id,
    nodes?.flatMap(node => node.children),
  );

export const getStatusTag = (enabled: boolean, startDate: any, endDate: any): TagProps => {
  const now = new Date();

  if (!enabled) {
    return { color: 'red', content: 'Deactivated' };
  } else if (endDate && isBefore(new Date(endDate), now)) {
    return { color: 'yellow', content: 'Expired' };
  } else if (isAfter(new Date(startDate), now)) {
    return { color: 'blue', content: 'Not Active' };
  }

  return { color: 'green', content: 'Active' };
};

export const generateCodePreview = (length: number): string =>
  times(length, () => CODE_CHARSET[Math.floor(Math.random() * CODE_CHARSET.length)]).join('');

export const getNumberOfCodesToCreate = (promotion: PromotionTableInput): number =>
  (promotion.isBulkPromotion
    ? promotion.bulkCodeCreation?.codeAmount
    : promotion.manualCodeCreation?.filter(code => code.isNew)?.length) ?? 0;

export const getNumberOfCodesToUpdate = (promotion: PromotionTableInput): number => {
  const bulkCodeCreation = promotion.bulkCodeCreation;
  const manualCodeCreation = promotion.manualCodeCreation;

  if (promotion.isBulkPromotion) {
    const firstCode = manualCodeCreation && manualCodeCreation[0];
    const hasChanges =
      bulkCodeCreation &&
      firstCode &&
      intersection(Object.keys(bulkCodeCreation), Object.keys(firstCode)).some(
        field => !isEqual(firstCode[field], bulkCodeCreation[field]),
      );

    return hasChanges ? promotion.totalCodes : 0;
  }

  return manualCodeCreation?.filter(code => !code.isNew && code.isUpdated)?.length ?? 0;
};

export const getNumberOfCodesToProcess = (promotion: PromotionTableInput): number =>
  getNumberOfCodesToCreate(promotion) + getNumberOfCodesToUpdate(promotion);
