import * as React from 'react';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { Button } from '@wework/ray2';
import { Message, Modal } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidV4 } from 'uuid';
import cn from 'classnames';
import { find, isEmpty, map } from 'lodash';
import { toast } from 'react-toastify';
import { RowNode } from 'ag-grid-community';
import { pluralize } from 'apollo/lib/utils';
import {
  BaseOverrideRow,
  ModalContentWrapper,
  ModalSubTitle,
  OverrideBox,
} from './discountsOverride.styled';
import {
  discountItemsOverrides,
  discountItemsSubsetSelectorStore,
  discountItemsTermTypes,
} from '../../store/modules/discountItems/discountItems.selector';
import { ADD_OVERRIDE_VALUE } from '../../store/modules/discountItems/discountItems.ducks';
import { BaseOverrideInput, TermType } from '../../../../generated/voyager/graphql';
import { flattenDiscounts } from '../discountItems.helpers';
import { getValPercentage } from '../../../../utils/helpers';
import { inputNumberFormatter } from '../../../pricing/standardPricing/components/helpers';
import InfoTooltip from '../../../../sharedComponents/tooltip/infoTooltip';
import { Override, TableViewDiscountItem } from '../../store/entities/discountItems';
import CustomTermsInfo from '../discountCustomTermsInfo';

interface DiscountsOverrideModalProps {
  discountsOverrideModalClose: Function;
  rowData: RowNode[];
}

function DiscountsOverrideModal({
  discountsOverrideModalClose,
  rowData,
}: DiscountsOverrideModalProps): ReactElement {
  const [baseDiscounts, setBaseDiscounts] = useState<BaseOverrideInput[]>([]);
  const [hasFutureOverrides, setHasFutureOverrides] = useState<boolean>(false);
  const [hasFutureBaseDiscount, setHasFutureBaseDiscount] = useState<boolean>(false);
  const [allOverrideOriginalData, setAllOverrideOriginalData] = useState<TableViewDiscountItem[]>(
    [],
  );

  const termTypes = useSelector(discountItemsTermTypes);
  const discountOverrides = useSelector(discountItemsOverrides);
  const allDiscountItems = useSelector(discountItemsSubsetSelectorStore) ?? [];

  const loadInitData = () => {
    const allOverrideOriginalDataTemp = map(rowData, 'data');

    setAllOverrideOriginalData(allOverrideOriginalDataTemp);
    setHasFutureOverrides(
      allOverrideOriginalDataTemp
        .map(item => find(allDiscountItems, { id: item.id }))
        .filter(item => !!item)
        .flatMap(item => item?.gridDiscounts)
        .some(discounts => discounts?.futureOverride),
    );
    setHasFutureBaseDiscount(
      allOverrideOriginalDataTemp
        .map(item => find(allDiscountItems, { id: item.id }))
        .filter(item => !!item)
        .flatMap(item => item?.gridDiscounts)
        .some(discounts => discounts?.futureBaseDiscount),
    );

    // If only one row node is selected we can display the override values if exist previously.
    // TODO: CHECK FOR BATCH OVERRIDES.
    //  I don't think it makes sense to process the data
    //  and figure out if all items in batch as same data and display that.
    if (
      !isEmpty(discountOverrides) &&
      !isEmpty(allOverrideOriginalDataTemp) &&
      allOverrideOriginalDataTemp.length === 1
    ) {
      const overrideForRowNode: Override | undefined = discountOverrides.find(
        eachOverride => eachOverride.id === allOverrideOriginalDataTemp[0].id,
      );
      setBaseDiscounts(overrideForRowNode?.baseDiscounts ?? []);
    }
  };
  useEffect(loadInitData, []);

  // DISPATCH
  const dispatch = useDispatch();
  const addDiscountOverrides = useCallback(
    (payload: Override[]) => dispatch({ type: ADD_OVERRIDE_VALUE, payload }),
    [dispatch],
  );

  const isOverridden = (termLabel: string): boolean =>
    !!baseDiscounts.find(baseDiscount => baseDiscount.termTypeLabel === termLabel)?.termTypeLabel;

  const setOverrideInput = (value: number, termTypeLabel: string) => {
    const tempOverride: BaseOverrideInput = {
      value,
      termTypeLabel,
    };
    const idx = baseDiscounts.findIndex(
      baseDiscount => baseDiscount.termTypeLabel === termTypeLabel,
    );
    const tempBaseDiscounts = [...baseDiscounts];
    if (idx > -1) {
      tempBaseDiscounts[idx] = tempOverride;
    } else {
      tempBaseDiscounts.push(tempOverride);
    }
    setBaseDiscounts(tempBaseDiscounts);
  };

  const removeOverrideInput = (termTypeLabel: string) => {
    const idx = baseDiscounts.findIndex(
      baseDiscount => baseDiscount.termTypeLabel === termTypeLabel,
    );
    const tempBaseDiscounts = [...baseDiscounts];
    if (idx > -1) {
      tempBaseDiscounts.splice(idx, 1);
    }
    setBaseDiscounts(tempBaseDiscounts);
  };

  const getBaseOverrideInput = (termTypeLabel: string) =>
    baseDiscounts.find(baseDiscount => baseDiscount.termTypeLabel === termTypeLabel)?.value ?? '';

  const cleanAndSetVal = (event: any, termType: TermType) => {
    const currentValue = inputNumberFormatter(event?.target?.value);
    if (!currentValue || currentValue === '') {
      removeOverrideInput(termType.label);
      return;
    }

    const currValue = Number(currentValue);
    // Doing validation of input value and setting it if only less than max value.
    if (currValue >= 0 && currValue <= getValPercentage(termType.baseMaxDiscount)) {
      setOverrideInput(currValue, termType.label);
    } else if (isNaN(currValue)) {
      removeOverrideInput(termType.label);
    } else {
      toast.error(
        `For ${termType.label} term max discount override is ${getValPercentage(
          termType.baseMaxDiscount,
        )}%`,
      );
    }
  };

  const inputRenderers = () =>
    termTypes &&
    termTypes.map(termType => (
      <li key={`input${termType.label}`}>
        <OverrideBox>
          <input
            type="text"
            onChange={event => cleanAndSetVal(event, termType)}
            value={getBaseOverrideInput(termType.label)}
            className={cn({ filled: isOverridden(termType.label) })}
          />
          <span>%</span>
        </OverrideBox>
      </li>
    ));

  const applyAndCloseOverrides = () => {
    const allOverrides = allOverrideOriginalData.map(eachRowData => {
      const id = eachRowData.id;
      const eachDiscount = id ? find(allDiscountItems, { id }) : null;
      const flattenedDiscounts = flattenDiscounts(eachDiscount?.gridDiscounts ?? []);
      // Calculating the original Discounts and storing it out.
      // Doing this here and storing it since the flattened discount and termTypes are already available here.
      const originalDiscounts = !isEmpty(termTypes)
        ? termTypes.map(
            (eTT): BaseOverrideInput => ({
              termTypeLabel: eTT.label,
              value: flattenedDiscounts?.get(eTT.label)?.baseOrOverride ?? 0,
            }),
          )
        : null;

      return {
        id,
        baseDiscounts,
        name: `${eachRowData.sku}/${eachRowData.officeName}`,
        page: eachRowData.page,
        originalDiscounts,
        location: eachRowData.location,
      };
    });
    addDiscountOverrides([...allOverrides]);
    toast.success(`Applied discounts to ${pluralize(allOverrides.length, 'item')}`);
    discountsOverrideModalClose();
  };

  return (
    <Modal
      open
      className="discount-override-modal"
      onClose={() => discountsOverrideModalClose()}
      closeOnEscape={false}
    >
      <Modal.Content>
        <ModalContentWrapper>
          {hasFutureOverrides && (
            <Message color="yellow">
              {'One or more selected reservables has scheduled overrides '}
              <InfoTooltip
                popupContent={
                  <div>
                    One or more selected reservables have overrides scheduled to be published in the
                    future. If you apply overrides or base-discounts to these reservables, their
                    scheduled overrides will be canceled.
                  </div>
                }
              />
            </Message>
          )}
          {hasFutureBaseDiscount && (
            <Message color="yellow">
              {'One or more selected reservables has scheduled base-discounts '}
              <InfoTooltip
                popupContent={
                  <div>
                    One or more selected reservables have base-discounts scheduled to be published
                    in the future. If you apply base-discounts to these reservables, their scheduled
                    base-discounts and any overrides will be canceled.
                  </div>
                }
              />
            </Message>
          )}
          <ModalSubTitle className="no-indent">Input a base override.</ModalSubTitle>
          <CustomTermsInfo tooltipPosition={'top left'} />
          <div>
            <BaseOverrideRow>
              <li>Month Term:</li>
              {termTypes && termTypes.map(termType => <li key={uuidV4()}>{termType.label}</li>)}
            </BaseOverrideRow>
            <BaseOverrideRow>
              <li>Base Override:</li>
              {inputRenderers()}
            </BaseOverrideRow>
          </div>
        </ModalContentWrapper>
      </Modal.Content>
      <Modal.Actions>
        <Button theme={'outline'} size={'medium'} onClick={() => discountsOverrideModalClose()}>
          Cancel
        </Button>
        <Button
          theme={'fill'}
          size={'medium'}
          onClick={() => applyAndCloseOverrides()}
          disabled={isEmpty(baseDiscounts)}
          className={'ml-sm'}
        >
          Apply
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default DiscountsOverrideModal;
