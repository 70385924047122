import styled from 'styled-components';
import { FlexHorizontalHelper } from '../../styles/app.styled';

export const HomeBreadcrumbRegionStyled = styled.div`
  flex: 0 0 auto;
  padding: 13px 0 26px;
  margin: 0;
`;

export const MainTitleStyled = styled.div`
  ${FlexHorizontalHelper};
  justify-content: space-between;
  margin: 0 40px 20px;
  > div {
    display: flex;
  }
  .side-margin {
    margin: 0 5px 0 5px;
  }
`;
