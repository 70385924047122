import React, { ReactElement, useState } from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { format } from 'date-fns';
import isISO8601 from 'validator/lib/isISO8601';
import TextLink from 'sharedComponents/styledText/styledText';

const MAX_TEXT_LENGTH = 40;

function ChangelogValueRenderer({ value }: ICellRendererParams): ReactElement {
  const [textExpanded, setTextExpanded] = useState(false);

  if (!value) {
    return <div>-</div>;
  }

  if (isISO8601(value)) {
    return <div>{format(new Date(value), 'PPp')}</div>;
  }

  return (
    <div>
      {value.length > MAX_TEXT_LENGTH && (
        <>
          {textExpanded ? value : `${value.slice(0, MAX_TEXT_LENGTH)}...`}
          <div>
            <TextLink onClick={() => setTextExpanded(!textExpanded)}>
              {!textExpanded ? 'View More' : 'View Less'}
            </TextLink>
          </div>
        </>
      )}
      {value.length <= MAX_TEXT_LENGTH && value}
    </div>
  );
}

export default ChangelogValueRenderer;
