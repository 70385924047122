// GraphQL Queries
import { gql } from '@apollo/client';

export const onDemandPricesFragment = gql`
  fragment priceHierarchy on PriceHierarchy {
    id
    price
    currencyIsoCode
    validFrom
    createdBy
    publishedAt
    productId
  }
`;

export const workingPricesSubsetFragment = gql`
  fragment workingPriceSubsetHierarchy on WorkingPriceHierarchy {
    id
    price
    currencyIsoCode
    productId
  }
`;

export const FETCH_ON_DEMAND_PRICING_QUERY = gql`
  query GeoHierarchies($productIds: [UUID!]!) {
    geoHierarchies {
      id
      name
      timeZone
      currencyIsoCode
      parentId
      type
      currentPrices {
        ...priceHierarchy
      }
      futurePrices {
        ...priceHierarchy
      }
      workingPrices {
        ...workingPriceSubsetHierarchy
      }
      pricePreviews(productIds: $productIds) {
        id
        price
        type
        productId
      }
    }
  }
  ${onDemandPricesFragment}
  ${workingPricesSubsetFragment}
`;

export const FETCH_ON_DEMAND_PRODUCTS_QUERY = gql`
  query Products {
    products(filter: { businessLines: [ON_DEMAND] }) {
      id
      name
    }
  }
`;

export const workingPricesFragment = gql`
  fragment workingPriceHierarchy on WorkingPriceHierarchy {
    id
    price
    currencyIsoCode
    createdAt
    createdBy
    productId
    changelog {
      id
      price
      currencyIsoCode
      createdBy
      createdAt
    }
  }
`;

export const FETCH_ON_DEMAND_CHANGE_LOG = gql`
  query GeoHierarchiesChangeLog($filter: GeoHierarchyFilter, $productIds: [UUID!]) {
    geoHierarchies(filter: $filter) {
      id
      name
      workingPrices(productIds: $productIds) {
        ...workingPriceHierarchy
      }
    }
  }
  ${workingPricesFragment}
`;

export const FETCH_ON_DEMAND_PRICE_HISTORY_QUERY = gql`
  query GeoHierarchiesPriceHistory($filter: GeoHierarchyFilter) {
    geoHierarchies(filter: $filter) {
      id
      name
      timeZone
      currencyIsoCode
      parentId
      priceHistory {
        ...priceHierarchy
      }
    }
  }
  ${onDemandPricesFragment}
`;
