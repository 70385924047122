import { StoreInterface } from '../../../utils/store/store.interface';
import { PromotionItemsSubset, PromotionTableInput } from './entities/promotionItem';
import { GeoHierarchy, Product, Promotion } from '../../../generated/voyager/graphql';

const promotionItemsSelector = (state: StoreInterface) => state?.promotionItems;

export const promotionAllItemsSelector = (state: StoreInterface): Promotion[] =>
  promotionItemsSelector(state)?.allPromotionItems;

export const selectedPromotionSelector = (state: StoreInterface): PromotionTableInput | undefined =>
  promotionItemsSelector(state)?.selectedPromotion;

export const fetchAllPromotionsLoadingSelector = (state: StoreInterface): boolean =>
  promotionItemsSelector(state)?.fetchAllPromotionsLoading;

export const fetchPromotionLoadingSelector = (state: StoreInterface): boolean =>
  promotionItemsSelector(state)?.fetchPromotionLoading;

export const noMoreCodesSelector = (state: StoreInterface): boolean =>
  promotionItemsSelector(state)?.noMoreCodes;

export const productsSelector = (state: StoreInterface): Product[] =>
  promotionItemsSelector(state)?.products || [];

export const productsLoadingSelector = (state: StoreInterface): boolean =>
  promotionItemsSelector(state)?.productsLoading;

export const geoHierarchiesSelector = (state: StoreInterface): GeoHierarchy[] =>
  promotionItemsSelector(state)?.geoHierarchies || [];

export const geoHierarchiesLoadingSelector = (state: StoreInterface): boolean =>
  promotionItemsSelector(state)?.geoHierarchiesLoading;

export const savePromotionLoadingSelector = (state: StoreInterface): boolean =>
  promotionItemsSelector(state)?.savePromotionLoading;

export const promotionItemsSubsetSelector = (state: PromotionItemsSubset): Promotion[] =>
  state?.allPromotionItems;

export const promotionWithChangelogSelector = (state: StoreInterface): Promotion | undefined =>
  promotionItemsSelector(state)?.promotionWithChangelog;
