import { ColDef, ColGroupDef, GridOptions } from 'ag-grid-community';
import { GetRowIdFunc } from 'ag-grid-community/dist/lib/entities/gridOptions';
import { GetRowIdParams } from 'ag-grid-community/dist/lib/entities/iCallbackParams';
import './promotionViewTableAGGrid.css';
import PromotionStatusRenderer from './renderers/promotionStatusRenderer';
import PromotionActionsRenderer from './renderers/promotionActionsRenderer';
import PromotionUsageRenderer from './renderers/promotionUsageRenderer';
import PromotionStartEndRenderer from './renderers/promotionStartEndRenderer';
import PromotionCodeNameRenderer from './renderers/promotionCodeNameRenderer';
import PromotionEnabledRenderer from './renderers/promotionEnabledRenderer';
import TableLoadingRenderer from '../../../../sharedComponents/tableComponent/tableLoadingRenderer';
import EmptyTableComponentRenderer from '../../../../sharedComponents/tableComponent/tableEmpty';
import { toTitleCase } from '../../../../utils/helpers';

export const defaultColDef: ColDef = {
  flex: 1,
  minWidth: 125,
  suppressMenu: true,
  wrapText: true,
  cellClass: ['cell-vertical-alignment-center'],
};

const getRowId: GetRowIdFunc = (data: GetRowIdParams): string => data.data?.id;

export const columnDefs: (ColDef | ColGroupDef)[] = [
  {
    headerName: 'Promotion',
    cellRenderer: 'agGroupCellRenderer',
    field: 'name',
    minWidth: 200,
  },
  {
    headerName: 'Description',
    field: 'description',
    minWidth: 200,
    cellClass: ['background-gray', 'cell-vertical-alignment-center'],
  },
  {
    headerName: 'Code',
    cellRenderer: PromotionCodeNameRenderer,
  },
  {
    headerName: 'Status',
    cellRenderer: PromotionStatusRenderer,
    cellClass: ['background-gray', 'cell-vertical-alignment-center'],
  },
  {
    headerName: 'Start / End',
    cellRenderer: PromotionStartEndRenderer,
    minWidth: 200,
  },
  {
    headerName: 'Uses (remaining / max)',
    cellRenderer: PromotionUsageRenderer,
    minWidth: 150,
    cellClass: ['background-gray', 'cell-vertical-alignment-center'],
  },
  {
    headerName: 'Approver',
    field: 'approver',
    valueFormatter: params => toTitleCase(params.value),
  },
  {
    headerName: 'Created By',
    field: 'createdBy',
    valueFormatter: params => toTitleCase(params.value),
    cellClass: ['background-gray', 'cell-vertical-alignment-center'],
  },
  {
    headerName: 'Enabled',
    cellRenderer: PromotionEnabledRenderer,
  },
  {
    headerName: 'Actions',
    minWidth: 100,
    maxWidth: 100,
    cellRenderer: PromotionActionsRenderer,
    cellClass: ['background-gray', 'cell-vertical-alignment-center'],
  },
];

export const gridOptions: GridOptions = {
  defaultColDef,
  suppressMaxRenderedRowRestriction: true,
  suppressAnimationFrame: true,
  suppressContextMenu: true,
  suppressCellFocus: true,
  suppressRowClickSelection: true,
  suppressRowHoverHighlight: true,
  suppressAggFuncInHeader: true,
  debounceVerticalScrollbar: true,
  suppressColumnVirtualisation: true,
  loadingOverlayComponent: TableLoadingRenderer,
  noRowsOverlayComponent: EmptyTableComponentRenderer,
  getRowId,
  rowHeight: 56,
};
