import { ICellRendererParams } from 'ag-grid-community';
import * as React from 'react';
import { ReactElement, useCallback } from 'react';
import { Popup, Icon } from 'semantic-ui-react';
import { format } from 'date-fns-tz';
import { useDispatch, useSelector } from 'react-redux';
import { sortBy } from 'lodash';
import {
  ChangelogList,
  ChangelogListItem,
  ChangelogTitle,
} from '../../../pricingTable/pricingTable.styled';
import { FETCH_CURRENT_CHANGE_LOG } from '../../../../store/modules/priceableItems/priceableItems.ducks';
import Spinner from '../../../../../../../assets/spinner.svg';
import {
  priceableItemChangeLogSelector,
  priceableItemChangeLogStateSelector,
  priceableItemCurrencyIsoSelector,
} from '../../../../store/modules/priceableItems/priceableItems.selector';
import { formatDate, intlCurrencyFormatter, toTitleCase } from '../../../../../../../utils/helpers';

const ChangelogAndSchedule = (params: ICellRendererParams): ReactElement => {
  const dispatch = useDispatch();

  const fetchPriceableItemChangeLog = useCallback(
    (payload: string) => dispatch({ type: FETCH_CURRENT_CHANGE_LOG, payload }),
    [dispatch],
  );

  const priceableItemChangeLog = useSelector(priceableItemChangeLogSelector);
  const currencyIsoSelector = useSelector(priceableItemCurrencyIsoSelector);
  const priceableItemChangeLogState = useSelector(priceableItemChangeLogStateSelector);

  const changeLogListItems = () => {
    if (priceableItemChangeLogState?.loading) {
      return <Icon as="img" link src={Spinner} />;
    }
    const sortedPriceableItemChangeLog = sortBy(priceableItemChangeLog, 'createdAt');
    return sortedPriceableItemChangeLog?.length ? (
      sortedPriceableItemChangeLog.map(changeLogItem => (
        <ChangelogListItem key={changeLogItem.id}>
          {intlCurrencyFormatter(currencyIsoSelector ?? '', changeLogItem.price)} on{' '}
          {format(new Date(changeLogItem.createdAt), 'Pp')} by
          {` ${toTitleCase(changeLogItem.createdBy)}`}
        </ChangelogListItem>
      ))
    ) : (
      <div>No Change Log</div>
    );
  };

  const scheduledPrices = () =>
    sortBy(params.data.prices, 'validFrom').map((price: any) => (
      <ChangelogListItem key={price.id}>
        {intlCurrencyFormatter(currencyIsoSelector ?? '', price.price)} on{' '}
        {formatDate(price.validFrom, params.data.timeZone)} by
        {` ${toTitleCase(price.createdBy)}`}
      </ChangelogListItem>
    ));

  return (
    <>
      {params.data?.workingPrice?.price >= 0 && (
        <Popup
          basic
          className="changelog-popup"
          trigger={
            <button
              className="changelog-trigger"
              type="button"
              onClick={() => fetchPriceableItemChangeLog(params.data.id)}
            />
          }
          on={'click'}
          content={
            <Popup.Content>
              <ChangelogTitle>Change Log for {params.data?.name}</ChangelogTitle>
              <ChangelogList>{changeLogListItems()}</ChangelogList>
            </Popup.Content>
          }
        />
      )}
      {params.data?.prices.length > 0 && (
        <Popup
          basic
          className="changelog-popup"
          trigger={<button className="scheduled-price-trigger" type="button" />}
          on={'click'}
          content={
            <Popup.Content>
              <ChangelogTitle>Scheduled price change</ChangelogTitle>
              <ChangelogList>{scheduledPrices()}</ChangelogList>
            </Popup.Content>
          }
        />
      )}
    </>
  );
};

export default ChangelogAndSchedule;
