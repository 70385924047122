import { gql } from '@apollo/client';
import promotionFragment from './promotionItems.fragment';

export const CREATE_PROMOTION_MUTATION = gql`
  mutation CreatePromotion(
    $promotionInput: PromotionInput!
    $codeFilter: PromotionCodeFilter
    $codePageInfo: PageInfo
    $codeSortInfos: [SortInfo!]
  ) {
    createPromotion(promotionInput: $promotionInput) {
      ...promotion
    }
  }
  ${promotionFragment}
`;

export const UPDATE_PROMOTION_MUTATION = gql`
  mutation UpdatePromotion(
    $id: UUID!
    $promotionInput: PromotionInput!
    $codeFilter: PromotionCodeFilter
    $codePageInfo: PageInfo
    $codeSortInfos: [SortInfo!]
  ) {
    updatePromotion(id: $id, promotionInput: $promotionInput) {
      ...promotion
    }
  }
  ${promotionFragment}
`;

export const UPDATE_PROMOTIONS_ENABLED_MUTATION = gql`
  mutation UpdatePromotionsEnabled(
    $promotionsEnabledInput: PromotionsEnabledInput!
    $codeFilter: PromotionCodeFilter
    $codePageInfo: PageInfo
    $codeSortInfos: [SortInfo!]
  ) {
    updatePromotionsEnabled(promotionsEnabledInput: $promotionsEnabledInput) {
      ...promotion
    }
  }
  ${promotionFragment}
`;
