import React, { ReactElement } from 'react';
import { formatDistanceToNow } from 'date-fns';
import { Popup } from 'semantic-ui-react';
import { format } from 'date-fns-tz';
import { InfoTooltipContent } from '../../../../sharedComponents/tooltip/infoTooltip.styled';

interface PromotionEditHistoryCreatedAtProps {
  createdAtString: string;
}

function PromotionEditHistoryCreatedAt({
  createdAtString,
}: PromotionEditHistoryCreatedAtProps): ReactElement {
  const createdAt = new Date(createdAtString);

  return (
    <Popup
      basic
      trigger={
        <span>
          {formatDistanceToNow(createdAt, {
            addSuffix: true,
          })}
        </span>
      }
      on="hover"
      className={`info-tooltip`}
    >
      <Popup.Content>
        <InfoTooltipContent>{format(createdAt, 'PPp')}</InfoTooltipContent>
      </Popup.Content>
    </Popup>
  );
}

export default PromotionEditHistoryCreatedAt;
