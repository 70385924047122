import { Action } from 'redux-actions';
import { all, call, delay, put, race, take, takeLatest } from 'redux-saga/effects';
import { chunk, get, isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import { FetchResult } from '@apollo/client/link/core';
import { pluralize } from 'apollo/lib/utils';
import { graphQLClient } from '../../../../../../index';
import { errorHandlerActive } from '../../../../../../utils/errorHandling/helpers';
import {
  CancelFuturePriceInput,
  CancelPricesMutation,
  MutationResetPricesToLastPublishedArgs,
  PriceableItem,
  PriceableItemFilter,
  ResetPricesMutation,
  SaveWorkingPricesMutation,
  StandardPricingItemsQuery,
  StandardPricingItemsQueryVariables,
  TotalPriceableItemsQuery,
  WorkingPriceInput,
} from '../../../../../../generated/voyager/graphql';
import { DataWithCallback, Unassigned } from '../../../../../../utils/sharedTypes';
import {
  FETCH_STANDARD_PRICING_ITEMS_QUERY,
  GET_TOTAL_PRICEABLE_ITEMS_QUERY,
} from './standardPricingItems.query';
import {
  CANCEL_FUTURE_PRICES,
  CANCEL_FUTURE_PRICES_SUCCESS,
  CREATE_STANDARD_WORKING_PRICE_ERROR,
  CREATE_STANDARD_WORKING_PRICE_SUCCESS,
  CREATE_STANDARD_WORKING_PRICES,
  END_STANDARD_PRICING_BATCH_FETCH,
  FETCH_STANDARD_PRICING_ITEMS,
  FETCH_STANDARD_PRICING_ITEMS_SUCCESS,
  REVERT_WORKING_PRICES,
  REVERT_WORKING_PRICES_SUCCESS,
  REVERT_WORKING_PRICES_UPDATE_ITEMS,
  START_STANDARD_PRICING_BATCH_FETCH,
  STOP_FETCH_STANDARD_PRICING_ITEMS,
} from './standardPricingItems.ducks';
import {
  CANCEL_FUTURE_PRICES_MUTATION,
  REVERT_PRICES_TO_LAST_PUBLISHED_MUTATION,
  SAVE_STANDARD_WORKING_PRICES_MUTATION,
} from './standardPriceingItems.mutation';

const fetchPriceableItems = (
  variables: StandardPricingItemsQueryVariables,
): Promise<FetchResult<StandardPricingItemsQuery>> =>
  graphQLClient.query({
    fetchPolicy: 'network-only',
    query: FETCH_STANDARD_PRICING_ITEMS_QUERY,
    variables,
  });

const getTotalPriceableItems = (
  filter: PriceableItemFilter | Unassigned,
): Promise<FetchResult<TotalPriceableItemsQuery>> =>
  graphQLClient.query({
    fetchPolicy: 'network-only',
    query: GET_TOTAL_PRICEABLE_ITEMS_QUERY,
    variables: { filter },
  });

const cancelFuturePricesMutation = (
  cancelFuturePriceInput: CancelFuturePriceInput,
): Promise<FetchResult<CancelPricesMutation>> =>
  graphQLClient.mutate({
    mutation: CANCEL_FUTURE_PRICES_MUTATION,
    variables: {
      cancelFuturePriceInput,
    },
  });

const revertWorkingPricesMutation = (
  variables: MutationResetPricesToLastPublishedArgs,
): Promise<FetchResult<ResetPricesMutation>> =>
  graphQLClient.mutate({
    mutation: REVERT_PRICES_TO_LAST_PUBLISHED_MUTATION,
    variables,
  });

function* fetchPriceData(variables: StandardPricingItemsQueryVariables): any {
  const requestDelay = 1000 / Number(process.env.REACT_APP_REQUESTS_PER_SECOND);
  const page = variables.page.page;
  yield delay(page * requestDelay);

  // Fetch data from backend.
  const { errors, data } = yield call(fetchPriceableItems, variables);

  // If any errors stop the load and redirect to error page.
  if (!isEmpty(errors) || !data?.priceableItems) {
    yield put({ type: END_STANDARD_PRICING_BATCH_FETCH });
    errorHandlerActive(new Error(errors[0] ?? 'Error while fetching priceable items'));
  } else {
    // Store the data in the store.
    const priceableItems = data.priceableItems.map((item: PriceableItem) => ({
      ...item,
      page,
    }));
    yield put({ type: FETCH_STANDARD_PRICING_ITEMS_SUCCESS, payload: priceableItems });
  }
}

// Saga - for fetching the priceable items in batches.
function* fetchStandardPricingItemsWorker(action: Action<StandardPricingItemsQueryVariables>) {
  try {
    const input = action.payload;
    // Starting a batch fetch.
    yield put({ type: START_STANDARD_PRICING_BATCH_FETCH });

    const { errors, data } = yield call(getTotalPriceableItems, input.filter);
    if (!isEmpty(errors) || !data) {
      yield put({ type: END_STANDARD_PRICING_BATCH_FETCH });
      errorHandlerActive(new Error(errors[0] ?? 'Error while calculating total priceable items'));
      return;
    }

    const requestsNumber = Math.ceil(data.totalPriceableItems / input.page.size);
    yield all(
      [...Array(requestsNumber).keys()].map(index =>
        fetchPriceData({
          ...input,
          page: {
            ...input.page,
            page: index + 1,
          },
        }),
      ),
    );

    // End batch fetch.
    yield put({ type: END_STANDARD_PRICING_BATCH_FETCH });
  } catch (e: any) {
    errorHandlerActive(new Error(e));
    yield put({ type: END_STANDARD_PRICING_BATCH_FETCH });
  }
}

// Batching the update request.
const updatePriceableItems = (
  workingPriceInputs: WorkingPriceInput[],
): Promise<FetchResult<SaveWorkingPricesMutation>> =>
  graphQLClient.mutate({
    mutation: SAVE_STANDARD_WORKING_PRICES_MUTATION,
    variables: { workingPriceInputs },
  });

function* createStandardWorkingPricesWorker(action: Action<WorkingPriceInput[]>) {
  try {
    const workingPriceInputs: WorkingPriceInput[] = action.payload;
    const batchSize = Number(process.env.REACT_APP_REQUEST_BATCH_SIZE);

    yield all(
      chunk(workingPriceInputs, batchSize).map((batch, index) =>
        createStandardWorkingPrices(batch, index),
      ),
    );

    yield put({ type: CREATE_STANDARD_WORKING_PRICE_SUCCESS, payload: workingPriceInputs });
    toast.success(`Successfully updated ${pluralize(workingPriceInputs.length, 'working price')}`);
  } catch (e: any) {
    yield put({ type: CREATE_STANDARD_WORKING_PRICE_ERROR });
    toast.error(`Working price save error - ${get(e, 'message', 'Unknown Error')}`);
    errorHandlerActive(new Error(e));
  }
}

// Saga - Save current working prices
function* createStandardWorkingPrices(input: WorkingPriceInput[], page: number) {
  const requestDelay = 1000 / Number(process.env.REACT_APP_REQUESTS_PER_SECOND);
  yield delay(page * requestDelay);
  const { data, errors } = yield call(updatePriceableItems, input);

  if (!isEmpty(errors) || !data?.saveWorkingPrices?.success) {
    yield put({ type: END_STANDARD_PRICING_BATCH_FETCH });
    const error = data?.saveWorkingPrices?.errors ?? errors[0]?.message;
    toast.error(`Working price save error - ${error}`);
    errorHandlerActive(new Error(error));
  }
}

function* cancelFuturePricesWorker(action: Action<DataWithCallback<CancelFuturePriceInput>>) {
  try {
    const input = action.payload.data;
    const batchSize = Number(process.env.REACT_APP_REQUEST_BATCH_SIZE);

    yield all(
      chunk(input.ids, batchSize).map((batch, index) =>
        cancelFuturePrices(
          {
            ...input,
            ids: batch,
          },
          index,
        ),
      ),
    );

    toast.success(`Canceled future prices for ${pluralize(input.ids.length, 'item')}`);
    yield put({ type: CANCEL_FUTURE_PRICES_SUCCESS, payload: input.ids });
    action.payload.successCallback();
  } catch (e: any) {
    toast.error(`Error while canceling future prices: ${e.message}`);
    errorHandlerActive(e);
  }
}

function* cancelFuturePrices(input: CancelFuturePriceInput, page: number) {
  const requestDelay = 1000 / Number(process.env.REACT_APP_REQUESTS_PER_SECOND);
  yield delay(page * requestDelay);

  const { data, errors } = yield call(cancelFuturePricesMutation, input);

  if (!isEmpty(errors) || !data?.cancelFuturePrices?.success) {
    const error = data?.cancelFuturePrices?.errors ?? errors[0];
    toast.error(`Cancel Future Prices Error - ${error}`);
    errorHandlerActive(new Error(error));
  }
}

function* revertWorkingPricesWorker(
  action: Action<DataWithCallback<MutationResetPricesToLastPublishedArgs>>,
) {
  try {
    const input = action.payload.data;
    const batchSize = Number(process.env.REACT_APP_REQUEST_BATCH_SIZE);

    yield all(
      chunk(input.ids, batchSize).map((batch, index) =>
        revertWorkingPrices(
          {
            ...input,
            ids: batch,
          },
          index,
        ),
      ),
    );

    toast.success(`Reverted prices for ${pluralize(input.ids.length, 'item')} to last published`);
    yield put({ type: REVERT_WORKING_PRICES_SUCCESS });
    action.payload.successCallback();
  } catch (e: any) {
    toast.error(`Error while reverting prices: ${e.message}`);
    errorHandlerActive(e);
  }
}

function* revertWorkingPrices(input: MutationResetPricesToLastPublishedArgs, page: number) {
  const requestDelay = 1000 / Number(process.env.REACT_APP_REQUESTS_PER_SECOND);
  yield delay(page * requestDelay);

  const { data, errors } = yield call(revertWorkingPricesMutation, input);

  if (!isEmpty(errors) || !data) {
    toast.error(`Revert Working Prices Error - ${errors[0]}`);
    errorHandlerActive(new Error(errors[0]));
  } else {
    yield put({
      type: REVERT_WORKING_PRICES_UPDATE_ITEMS,
      payload: data.resetPricesToLastPublished,
    });
  }
}

export default function* standardPricingItemSaga(): any {
  yield takeLatest(
    [FETCH_STANDARD_PRICING_ITEMS],
    function* (args: Action<StandardPricingItemsQueryVariables>) {
      yield race({
        task: call(fetchStandardPricingItemsWorker, args),
        cancel: take(STOP_FETCH_STANDARD_PRICING_ITEMS),
      });
    },
  );
  yield all([takeLatest(CREATE_STANDARD_WORKING_PRICES, createStandardWorkingPricesWorker)]);
  yield all([takeLatest(CANCEL_FUTURE_PRICES, cancelFuturePricesWorker)]);
  yield all([takeLatest(REVERT_WORKING_PRICES, revertWorkingPricesWorker)]);
}
