import {
  GeoHierarchy,
  InputMaybe,
  PageInfo,
  Product,
  Promotion,
  PromotionBulkCodeInput,
  PromotionByIdQueryVariables,
  PromotionFilter,
  PromotionInput,
  PromotionManualCodeInput,
  QueryPromotionsArgs,
} from '../../../../generated/voyager/graphql';

export interface PromotionItemsSubset {
  fetchAllPromotionsLoading: boolean;
  allPromotionItems: Promotion[];
  selectedPromotion?: PromotionTableInput;
  noMoreCodes: boolean;
  fetchPromotionLoading: boolean;
  savePromotionLoading: boolean;
  products: Product[];
  productsLoading: boolean;
  geoHierarchies: GeoHierarchy[];
  geoHierarchiesLoading: boolean;
  promotionWithChangelog?: Promotion;
}

export const smallCodePageInfo: PageInfo = { page: 1, size: 3 };

export interface PromotionTableInput
  extends Omit<Partial<PromotionInput>, 'manualCodeCreation' | 'bulkCodeCreation'> {
  id: any;
  manualCodeCreation: InputMaybe<PromotionCodeTableInput[]>;
  bulkCodeCreation: InputMaybe<Partial<PromotionBulkCodeInput>>;
  totalCodes: number;
  isBulkPromotion: boolean;
}

export interface PromotionCodeTableInput extends Partial<PromotionManualCodeInput> {
  isNew: boolean;
  isUpdated: boolean;
  usages?: number;
  tableKey: string; // hack to uniquely identify new promotion codes
}

export interface PromotionTableFilter extends PromotionFilter {
  timeZone: string;
}

export interface QueryPromotionsTableArgs extends Omit<QueryPromotionsArgs, 'filter'> {
  filter: PromotionTableFilter;
}

export interface PromotionByIdQueryInput {
  queryVariables: PromotionByIdQueryVariables;
  appendCodes?: boolean;
}
