import * as React from 'react';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input } from 'semantic-ui-react';
import { Button } from '@wework/ray2';
import { ColumnApi, GridApi } from 'ag-grid-community';
import { isEmpty } from 'lodash';
import {
  TableFilter,
  TableFilterBarLoadingOverlay,
  TableFilterBarWrapper,
  TableFilterColumnsWrap,
  TableFilterRow,
} from '../../../../../../styles/sharedTableFilterBar.styled';
import {
  standardPricingBatchFetchStoreSelector,
  standardPricingListPriceModeSelector,
} from '../../../store/modules/standardPricingItems/standardPricingItems.selector';
import StandardTableViewEditModeSelector from './standardPricingTableEditToggle';
import StandardPricingFilterSidePanel from '../standardPricingTableFilterSidePanel/standardPricingFilterSidePanel';
import { standardPricingFiltersSelector } from '../../../store/modules/standardPricingFilters/standardPricingFilters.selector';
import {
  DISABLE_EDIT_MODE_STANDARD_PRICING,
  SET_LIST_PRICE_MODE_STANDARD_PRICING,
} from '../../../store/modules/standardPricingItems/standardPricingItems.ducks';
import { getNumberOfFilterApplied } from '../../../../../../utils/helpers';

interface StandardPricingTableFilterBarProps {
  agGridApi?: GridApi;
  agColumnApi?: ColumnApi;
}
function StandardPricingTableFilterBar({
  agGridApi,
  agColumnApi,
}: StandardPricingTableFilterBarProps): ReactElement {
  // STATE PROPS
  const batchFetch = useSelector(standardPricingBatchFetchStoreSelector);
  const filters = useSelector(standardPricingFiltersSelector);
  const isListPriceMode = useSelector(standardPricingListPriceModeSelector);

  const [isVisible, setIsVisible] = useState<boolean>(false);

  const visibilityHandler = () => setIsVisible(!isVisible);

  const dispatch = useDispatch();
  const disableEditModeStandardPricingView = useCallback(
    () => dispatch({ type: DISABLE_EDIT_MODE_STANDARD_PRICING }),
    [dispatch],
  );
  const setListPriceMode = useCallback(
    (listPriceMode: boolean) =>
      dispatch({ type: SET_LIST_PRICE_MODE_STANDARD_PRICING, payload: listPriceMode }),
    [dispatch],
  );

  const arpmColumns = [
    'reservationPriceARPM',
    'currentPriceARPM',
    'suggestedPriceARPM',
    'workingPriceARPM',
    'changelogAndScheduleARPM',
  ];

  const lpColumns = ['reservationPriceLP', 'currentPriceLP', 'suggestedPriceLP', 'workingPriceLP'];

  const toggleChange = () => {
    if (agColumnApi) {
      // show "list price"/"arpm" columns
      agColumnApi.setColumnsVisible(arpmColumns, !isListPriceMode);
      agColumnApi.setColumnsVisible(lpColumns, isListPriceMode);
      disableEditModeStandardPricingView();
    }
  };
  useEffect(toggleChange, [agColumnApi, isListPriceMode]);

  return (
    <TableFilterBarWrapper>
      {batchFetch ? <TableFilterBarLoadingOverlay /> : ''}
      <TableFilterColumnsWrap>
        <TableFilterRow>
          <TableFilter>
            <Input
              className="reservable-search"
              icon="search"
              iconPosition="left"
              title="Search Location or Reservables"
              placeholder="Search"
              onChange={(event: any): void => agGridApi?.setQuickFilter(event.target.value)}
              onKeyPress={(event: any): void => agGridApi?.setQuickFilter(event.target.value)}
            />
          </TableFilter>
          <TableFilter>
            <Button
              theme={`${!isEmpty(filters) ? 'fill' : 'outline'}`}
              size={'medium'}
              className="side-panel-trigger"
              onClick={() => setIsVisible(!isVisible)}
            >
              {getNumberOfFilterApplied(filters)}
            </Button>
          </TableFilter>
          <TableFilter className="info-button-groups">
            <Button
              theme={!isListPriceMode ? 'fill' : 'outline'}
              size={'medium'}
              onClick={() => setListPriceMode(false)}
            >
              ARPM
            </Button>
            <Button
              theme={isListPriceMode ? 'fill' : 'outline'}
              size={'medium'}
              onClick={() => setListPriceMode(true)}
            >
              List Price
            </Button>
          </TableFilter>
        </TableFilterRow>
        <StandardTableViewEditModeSelector agGridApi={agGridApi} disabled={isListPriceMode} />
      </TableFilterColumnsWrap>
      <StandardPricingFilterSidePanel isVisible={isVisible} visibilityHandler={visibilityHandler} />
    </TableFilterBarWrapper>
  );
}

export default StandardPricingTableFilterBar;
