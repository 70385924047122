import * as React from 'react';
import { ReactElement, useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import cn from 'classnames';
import { Icon, IconType, IconSize, IconName } from '@wework/ray2';
import { useDispatch, useSelector } from 'react-redux';
import { LocationDescriptorObject } from 'history';
import { trackAnalytics } from '../../utils/analytics/helpers';
import { AppItemWrap } from './sidebar.styled';
import { ADD_REFERRER } from '../../sharedStore/modules/auth/auth.ducks';
import currentPathNameSelector from '../../sharedStore/modules/router/router.selector';
import { logoutHelper } from '../../index';

interface AppNavItemProps {
  icon: IconName;
  name: string;
  disabled: boolean;
  route: string;
}

function AppNavItem({ icon, name, disabled, route }: AppNavItemProps): ReactElement {
  // DISPATCH PROPS
  const dispatch = useDispatch();
  // Dispatching props in functional component.
  const setReferrer = useCallback(
    (payload: LocationDescriptorObject) => dispatch({ type: ADD_REFERRER, payload }),
    [dispatch],
  );

  const location = useSelector(currentPathNameSelector);

  const trackAnalyticsHelper = () => {
    if (name === 'logout-button') {
      setReferrer(location);
      logoutHelper();
    }
    trackAnalytics('Sidebar Application Menu Item Clicked', {
      workflow: 'Sidebar Navigation',
      object_type: 'link',
      object_name: name,
      object_value: route,
    });
  };

  return (
    <AppItemWrap className={cn({ disabled })}>
      <NavLink
        title={name}
        className="app-icon"
        to={{ pathname: route }}
        type="outline"
        key={route}
        onClick={() => trackAnalyticsHelper()}
      >
        <Icon className="text-black" size={IconSize.LARGE} type={IconType.OUTLINE} icon={icon} />
      </NavLink>
    </AppItemWrap>
  );
}

export default AppNavItem;
