import styled from 'styled-components';
import { blue, darkGray, lightGray } from './colors';
import {
  device,
  FlexHorizontalEndHelper,
  FlexHorizontalHelper,
  FlexHorizontalWrappedHelper,
  ListReset,
} from './app.styled';

export const TableFilterBarWrapper = styled.div`
  position: relative;
  margin: 0 40px 20px;
  * {
    box-sizing: border-box;
  }
`;
export const TableFilterBarLoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
  background: rgba(255, 255, 255, 0.5);
`;
export const TableFilterColumnsWrap = styled.div`
  ${FlexHorizontalHelper};
`;
export const TableFilterRow = styled.ol`
  flex: 0 0 auto;
  ${ListReset};
  ${FlexHorizontalWrappedHelper};
  &:last-of-type {
    flex: 1 1 auto;
    justify-content: flex-end;
  }
  > span{
    margin: 12px 12px 0 0;
    font-weight: 400;
    color: #666;
  }
  }
`;
export const TableFilter = styled.li`
  flex: 0 0 auto;
  @media all and ${device.tablet} {
    margin-right: 10px;
  }
  @media all and ${device.tabletL} {
    margin-right: 20px;
  }
  &:last-of-type {
    margin-right: 0;
  }
  &.right-align {
    position: absolute;
  }
  &.info-button-groups {
    justify-content: flex-end;
    > button:not(:last-of-type) {
      border-bottom-right-radius: unset;
      border-top-right-radius: unset;
    }
    > button.middle-btn {
      border-radius: unset;
    }
    > button:last-of-type {
      border-bottom-left-radius: unset;
      border-top-left-radius: unset;
    }
    list-style-type: none;
  }
  &.info-button-groups-disabled {
    > button {
      pointer-events: none;
    }
  }
  &.edit-table-filter {
    ${FlexHorizontalEndHelper};
    > p.edit-mode-on-off {
      line-height: 40px;
      margin: 0 8px;
    }
    @media all and ${device.tablet} {
      margin: 0 0 0 10px;
    }
    @media all and ${device.tabletL} {
      margin: 0 0 0 20px;
    }
  }
  > .relative {
    > button.side-panel-trigger {
      margin: 0;
      @media all and ${device.tablet} {
        font-size: 12px;
        padding: 5px 10px;
      }
      @media all and ${device.tabletL} {
        font-size: 14px;
        padding: 8px 16px;
      }
    }
    > input.reservable-search {
      width: 217px;

      &.reservable-search-extra-width2 {
        width: 305px;
      }

      > input {
        font-family: Apercu Mono, monospace;
        color: ${darkGray};
        cursor: pointer;
      }

      @media all and ${device.tablet} {
        > input {
          font-size: 12px;
          padding-top: 5px;
          padding-bottom: 5px;

          &::placeholder {
            font-size: 12px;
          }
        }
      }
      @media all and ${device.tabletL} {
        > input {
          font-size: 14px;
          padding-top: 8px;
          padding-bottom: 8px;

          &::placeholder {
            font-size: 14px;
          }
        }
      }
    }
  }
  .promotions-search {
    width: 250px;
  }
`;
export const SwitchStyled = styled.label`
  position: relative;
  width: 44px;
  height: 20px;
  @media all and ${device.tablet} {
    margin: 9px 0;
  }
  @media all and ${device.tabletL} {
    margin: 9px 0;
  }
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  .slider {
    :not(.disabled) {
      cursor: pointer;
    }
    position: absolute;
    border-radius: 14px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${lightGray};
    -webkit-transition: 0.3s;
    transition: 0.3s;
    &::before {
      position: absolute;
      content: '';
      height: 22px;
      width: 22px;
      border-radius: 50%;
      left: -1px;
      bottom: -1px;
      background-color: white;
      border: 1px solid ${lightGray};
      -webkit-transition: 0.3s;
      transition: 0.3s;
    }
  }
  input:checked + .slider {
    background-color: ${blue};
  }
  input:focus + .slider {
    box-shadow: none;
  }
  input:checked + .slider:before {
    transform: translateX(30px);
  }
  .on,
  .off {
    color: white;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 55%;
    left: 50%;
    font-size: 10px;
    font-weight: 400;
  }
  .on {
    display: none;
    left: 12px;
  }
  .off {
    left: auto;
    right: -4px;
  }
  input:checked + .slider + .on {
    display: block;
  }
  input:checked + .slider + .on + .off {
    display: none;
  }
`;
