import * as React from 'react';
import { ReactElement, useCallback, useState } from 'react';
import { pluralize } from 'apollo/lib/utils';
import { TextField } from '@wework/ray2';
import { Confirm } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { RowNode } from 'ag-grid-community';
import {
  CancelFuturePriceInput,
  CancelPriceScope,
} from '../../../../../../generated/voyager/graphql';
import {
  standardPricingCancelPricesLoadingSelector,
  standardPricingSelectedNodesSelector,
} from '../../../store/modules/standardPricingItems/standardPricingItems.selector';
import { ConfirmProps } from '../../../../../../sharedStore/entities/confirmProps';
import { DataWithCallback } from '../../../../../../utils/sharedTypes';
import {
  CANCEL_FUTURE_PRICES,
  SET_SELECTED_NODES_STANDARD_PRICING,
} from '../../../store/modules/standardPricingItems/standardPricingItems.ducks';

function CancelFuturePricesConfirm(props: ConfirmProps): ReactElement {
  const selectedNodes = useSelector(standardPricingSelectedNodesSelector);
  const cancelLoading = useSelector(standardPricingCancelPricesLoadingSelector);

  const dispatch = useDispatch();
  const cancelFuturePrices = useCallback(
    (payload: DataWithCallback<CancelFuturePriceInput>) =>
      dispatch({ type: CANCEL_FUTURE_PRICES, payload }),
    [dispatch],
  );
  const resetSelectedNodes = useCallback(
    (payload: RowNode[] = []) => dispatch({ type: SET_SELECTED_NODES_STANDARD_PRICING, payload }),
    [dispatch],
  );

  const [cancelPriceReason, setCancelPriceReason] = useState<string>('');

  const closeCancelConfirmationModal = () => {
    props.closeModal();
    resetSelectedNodes();
    setCancelPriceReason('');
  };

  return (
    <Confirm
      open={props.isOpen}
      onCancel={closeCancelConfirmationModal}
      onConfirm={() =>
        cancelFuturePrices({
          data: {
            ids: selectedNodes.filter(node => node.data.prices.length > 0).map(node => node.id),
            scope: CancelPriceScope.PRICEABLE_ITEM,
            reason: cancelPriceReason || null,
          },
          successCallback: closeCancelConfirmationModal,
        })
      }
      cancelButton={{
        content: 'Cancel',
        className: 'cancel-confirmation',
        disabled: cancelLoading,
      }}
      closeIcon
      confirmButton={{
        content: `Cancel Prices for ${pluralize(
          selectedNodes.filter(node => node.data.prices.length > 0).length,
          'item',
        )}`,
        loading: cancelLoading,
      }}
      header="Cancel Prices"
      content={
        <div>
          <p>
            For the selected reservables, <b>all</b> future scheduled prices will be canceled.
          </p>
          <p>Are you sure you want to cancel future prices?</p>
          <TextField
            type="text"
            placeholder={'Enter a reason'}
            onChange={event => setCancelPriceReason(event.target.value)}
            value={cancelPriceReason}
          />
        </div>
      }
    />
  );
}

export default CancelFuturePricesConfirm;
