import 'ag-grid-enterprise';
import * as React from 'react';
import { ReactElement, useCallback, useEffect } from 'react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { useDispatch } from 'react-redux';
import { withRequestedAuthz } from '@wework/we-auth-react';
import { get } from 'lodash';
import { push } from 'connected-react-router';
import EmployeePermissions from 'utils/store/permissions';
import { AuthzProps } from 'utils/constants';
import ROUTES from 'utils/routes/routes';
import { MainRegionStyled, OverflowHack } from '../../../styles/app.styled';
import HeaderComponent from '../../../sharedComponents/header/header';
import PromotionViewTable from '../components/promotionDisplayTable/promotionViewTableAGGrid';

function PromotionDisplayView({ requestedPermissions }: AuthzProps): ReactElement {
  const dispatch = useDispatch();

  const redirectToAccessDenied = useCallback(
    () => dispatch(push(ROUTES.ACCESS_DENIED_PAGE)),
    [dispatch],
  );

  useEffect(() => {
    if (!get(requestedPermissions, EmployeePermissions.voyager_promotions_view, false)) {
      redirectToAccessDenied();
    }
  }, [requestedPermissions, redirectToAccessDenied]);

  return (
    <>
      <MainRegionStyled>
        <OverflowHack>
          <HeaderComponent title={'View Promotions'} />
          <PromotionViewTable />
        </OverflowHack>
      </MainRegionStyled>
    </>
  );
}

export default withRequestedAuthz<AuthzProps>({
  permissions: [EmployeePermissions.voyager_promotions_view],
})(PromotionDisplayView);
