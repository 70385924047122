import { ValueGetterParams } from 'ag-grid-community';
import * as React from 'react';
import { ReactElement, useCallback } from 'react';
import { Icon } from 'semantic-ui-react';
import { useDispatch } from 'react-redux';
import { FETCH_PROMOTION_WITH_CHANGELOG } from 'app/promotion/store/promotion.ducks';
import Pencil from '../../../../../assets/pencil.svg';
import History from '../../../../../assets/history.svg';
import { routerHistory } from '../../../../../utils/store/configureStore';
import ROUTES from '../../../../../utils/routes/routes';
import { trackAnalytics } from '../../../../../utils/analytics/helpers';
import Eye from '../../../../../assets/eye.svg';

const PromotionActionsRenderer = (params: ValueGetterParams): ReactElement => {
  const trackPromoShowEditsHistoryActionClicked = () =>
    trackAnalytics(`Promotion - Show Edits History Action Icon Clicked`, {
      workflow: `Promotion Show Edits History Action Icon `,
      object_type: 'button',
      object_name: `Show Edits History Action`,
    });

  const redirectToPromoPage = (mode: 'Edit' | 'View', promoUUID: string) => {
    routerHistory.push({
      pathname: `${
        mode === 'Edit' ? ROUTES.PROMOTION_UPDATE_PAGE : ROUTES.PROMOTION_VIEW_PAGE
      }/${promoUUID}`,
    });
    trackAnalytics(`Promotion - ${mode} Action Icon Clicked`, {
      workflow: `Promotion ${mode} Action Icon`,
      object_type: 'button',
      object_name: `${mode} Action`,
    });
  };

  const dispatch = useDispatch();

  const fetchPromotionWithChangelog = useCallback(
    (payload: string) => dispatch({ type: FETCH_PROMOTION_WITH_CHANGELOG, payload }),
    [dispatch],
  );

  const openPanel = () => {
    trackPromoShowEditsHistoryActionClicked();
    fetchPromotionWithChangelog(params.data.id);
  };

  if (!params.data) {
    return <></>;
  }

  return (
    <div>
      <Icon
        as="img"
        link
        src={Pencil}
        onClick={() => redirectToPromoPage('Edit', params.data.id)}
      />
      <Icon as="img" link src={Eye} onClick={() => redirectToPromoPage('View', params.data.id)} />
      <Icon as="img" link src={History} onClick={openPanel} />
    </div>
  );
};

export default PromotionActionsRenderer;
