import { RowNode } from 'ag-grid-community';
import { PageInfo } from '../../../../generated/voyager/graphql';

export interface DiscountSyncAttemptSubset {
  isLoading: boolean;
  allDiscountSyncAttempt: DiscountSyncAttempt[];
  discountSyncAttemptFilter: DiscountSyncAttemptFilter;
  selectedNodes: RowNode[];
  pageInfo: PageInfo;
}

export interface DiscountSyncAttempt {
  id: string;
  discountUuid: string;
  reservableUuid: string;
  termLength: string;
  expirationDate: string;
  discount: string;
  scheduledFor: string;
  expirationStatus: string;
  status: string;
  retryCount: string;
  lastMessage: string;
  createdAt: string;
  updatedAt: string;
}

export interface DiscountSyncAttemptFilter {
  discountUuid: string[] | null;
  reservableUuid: string[] | null;
  status: string[] | null;
  startScheduledFor: string | null;
  endScheduledFor: string | null;
  startCreatedAt: string | null;
  endCreatedAt: string | null;
}

export const defaultDiscountFilter: DiscountSyncAttemptFilter = {
  startCreatedAt: null,
  endCreatedAt: null,
  startScheduledFor: null,
  endScheduledFor: null,
  discountUuid: null,
  reservableUuid: null,
  status: null,
};

export const defaultPageIngo: PageInfo = {
  page: 0,
  size: 15,
};
