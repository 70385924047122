import { FetchState } from '../sharedTypes';
import { PageInfo } from '../../generated/voyager/graphql';

export const PREFIX = 'shared/';

export function createRDXConstant(constant: string): string {
  return PREFIX + constant;
}

// BC: Default page info unless and until there will be paging in the frontend for all the components
export const pageInfo: PageInfo = { page: 1, size: 10000 };
// Adding small pages for batching requests.
export const pageInfoSmall: PageInfo = { page: 1, size: 50 };
export const pageInfoESmall: PageInfo = { page: 1, size: 15 };
export const pageInfoTiny: PageInfo = { page: 1, size: 5 };
export const initState: FetchState = {
  loaded: false,
  loading: false,
  error: null,
};

export const DATE_TIME_FORMAT = "yyyy-MM-dd'T'HH:mm:ss";
export const INSTANT_FORMAT = "yyyy-MM-dd'T'HH:mm:ss'Z'";
