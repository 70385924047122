import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { useDispatch, useSelector } from 'react-redux';
import 'ag-grid-enterprise';
import { GridApi, GridReadyEvent } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { isEmpty } from 'lodash';
import {
  AgGridWrapper,
  PromotionGridPaddingWrapper,
  ViewingTableCount,
} from './promotionViewTableAGGrid.styled';
import './promotionViewTableAGGrid.css';
import { columnDefs, gridOptions } from './promotionViewTableAGGrid.settings';
import { GridCustomWrapper, MarginWrapper } from '../../../../styles/app.styled';
import {
  fetchAllPromotionsLoadingSelector,
  promotionAllItemsSelector,
} from '../../store/promotion.selector';
import PromotionTableFilterBar from '../promotionFilterBar/promotionTableFilterBar';
import PaginationComponent from '../../../../sharedComponents/paginationComponent/paginationComponent';
import { FETCH_PROMOTION_ITEMS } from '../../store/promotion.ducks';
import { pageInfoESmall } from '../../../../utils/store/store.constants';
import { useDebounce } from '../../../pricing/standardPricing/components/helpers';
import { sanitizePromotionsArgs } from '../../store/promotions.helper';
import { QueryPromotionsTableArgs } from '../../store/entities/promotionItem';

export const INITIAL_FILTER = { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone };
const INITIAL_SEARCH_DATA = { pageInfo: pageInfoESmall, filter: INITIAL_FILTER };

function PromotionViewTable(): ReactElement {
  const [gridApi, setGridApi] = useState<GridApi>();
  const [searchData, setSearchData] = useState<QueryPromotionsTableArgs>(INITIAL_SEARCH_DATA);

  const allPromotionsLoading = useSelector(fetchAllPromotionsLoadingSelector);
  const allPromotionItems = useSelector(promotionAllItemsSelector);

  /**
   * OnGridReady is an AgGrid event that receives a param with access to the api.
   * @param params Parameter passed from AG grid.
   */
  const onGridReady = (params: GridReadyEvent): void => {
    if (params.columnApi) {
      params.columnApi.autoSizeAllColumns();
    }

    setGridApi(params?.api);
  };

  const dispatch = useDispatch();
  const getGridData = useCallback(
    (args: QueryPromotionsTableArgs) => {
      dispatch({ type: FETCH_PROMOTION_ITEMS, payload: sanitizePromotionsArgs(args) });
    },
    [dispatch],
  );
  const debouncedGetGridData = useDebounce(
    (payload: QueryPromotionsTableArgs) => getGridData(payload),
    500,
  );

  const loadInitialData = () => getGridData(searchData);
  useEffect(loadInitialData, []);

  /**
   * Applying filter in the grid
   */
  const setGridData = () => {
    if (gridApi) {
      if (allPromotionsLoading) {
        gridApi.showLoadingOverlay();
      } else if (isEmpty(allPromotionItems)) {
        gridApi.setRowData([]);
        gridApi.showNoRowsOverlay();
      } else {
        gridApi.setRowData(allPromotionItems);
        gridApi.hideOverlay();
      }
      gridApi.sizeColumnsToFit();
      gridApi.resetRowHeights();
    }
  };
  useEffect(setGridData, [allPromotionItems, allPromotionsLoading, gridApi]);

  const onGridSizeChanged = useCallback(() => {
    if (gridApi) {
      gridApi.sizeColumnsToFit();
      gridApi.resetRowHeights();
    }
  }, [gridApi]);

  return (
    <PromotionGridPaddingWrapper>
      <PromotionTableFilterBar
        filter={searchData.filter}
        setFilter={(filter, debounce) => {
          const newSearchData = { ...searchData, pageInfo: pageInfoESmall, filter };
          setSearchData(newSearchData);
          if (debounce) {
            debouncedGetGridData(newSearchData);
          } else {
            getGridData(newSearchData);
          }
        }}
      />
      <GridCustomWrapper>
        <MarginWrapper>
          <ViewingTableCount>
            <p>
              {allPromotionsLoading
                ? 'Loading...'
                : `Viewing ${allPromotionItems?.length ?? 0} Promotions`}
            </p>
          </ViewingTableCount>
        </MarginWrapper>
        <AgGridWrapper className="ag-theme-alpine promotion-view-management-grid">
          <AgGridReact
            gridOptions={gridOptions}
            columnDefs={columnDefs}
            onGridReady={onGridReady}
            onGridSizeChanged={onGridSizeChanged}
            enableBrowserTooltips
          />
        </AgGridWrapper>
        <PaginationComponent
          allItems={allPromotionItems}
          pageInfo={searchData.pageInfo}
          setPageInfo={pageInfo => {
            const newSearchData = { ...searchData, pageInfo };
            setSearchData(newSearchData);
            getGridData(newSearchData);
          }}
          featureName="Promotion"
        />
      </GridCustomWrapper>
    </PromotionGridPaddingWrapper>
  );
}

export default PromotionViewTable;
