import * as React from 'react';
import { ReactElement, useState } from 'react';
import { Radio } from 'semantic-ui-react';
import cn from 'classnames';
import { PromotionFilterProps } from './promotionTableFilterBar';
import {
  ActiveFilterValues,
  FilterBody,
  FilterHeader,
  FilterItemOption,
  PanelFilterItem,
} from '../../../../styles/app.styled';
import { getBooleanValue } from '../../../../utils/helpers';
import { InputMaybe } from '../../../../generated/voyager/graphql';
import { trackAnalytics } from '../../../../utils/analytics/helpers';

function PromotionEnabledFilter({ filter, setFilter }: PromotionFilterProps): ReactElement {
  const [accordionOpen, setAccordionOpen] = useState<boolean>(true);

  const trackEnabledFilterPromotions = (filterValue?: InputMaybe<boolean>) =>
    trackAnalytics(`Promotion - Enabled/Disabled Filter Promotion used`, {
      workflow: `Promotion - Filter promotion`,
      object_type: 'toggle',
      object_name: `Enabled/Disabled Filter promotion`,
      object_value: filterValue,
    });

  const setPromotionEnabled = (enabled?: InputMaybe<boolean>) => {
    setFilter({ ...filter, enabled });
    trackEnabledFilterPromotions(enabled);
  };

  return (
    <PanelFilterItem className={cn({ open: accordionOpen, closed: !accordionOpen })}>
      <FilterHeader className="filter-header">
        <button type="button" onClick={() => setAccordionOpen(!accordionOpen)}>
          <span>{'Promotion Enabled'}</span>
          <i className="toggle-accordion-icon" />
        </button>
        {!accordionOpen && (
          <ActiveFilterValues>
            {filter.enabled === undefined ? 'All' : getBooleanValue(filter.enabled)}
          </ActiveFilterValues>
        )}
      </FilterHeader>
      <FilterBody className="filter-body">
        <FilterItemOption>
          <Radio
            name="promotionEnabled"
            label={<span> All</span>}
            checked={filter.enabled === undefined}
            onChange={() => setPromotionEnabled(undefined)}
          />
        </FilterItemOption>
        <FilterItemOption>
          <Radio
            name="promotionEnabled"
            label={<span> Enabled</span>}
            checked={filter.enabled === true}
            onChange={() => setPromotionEnabled(true)}
          />
        </FilterItemOption>
        <FilterItemOption>
          <Radio
            name="promotionEnabled"
            label={<span> Disabled</span>}
            checked={filter.enabled === false}
            onChange={() => setPromotionEnabled(false)}
          />
        </FilterItemOption>
      </FilterBody>
    </PanelFilterItem>
  );
}

export default PromotionEnabledFilter;
