import * as React from 'react';
import { ReactElement } from 'react';
import TimezonePicker from 'react-timezone';
import { Table } from 'semantic-ui-react';
import { Button, Icon, IconName, IconSize, IconType, TextField } from '@wework/ray2';
import { v4 as uuidV4 } from 'uuid';
import { Checkbox, Tag } from '@wework/dieter-ui';
import { format } from 'date-fns';
import PromotionCodeDateRange from '../shared/promotionCodeDateRange';
import { getBooleanValue, validateInputNumberValue } from '../../../../utils/helpers';
import { PromotionCodeTableInput } from '../../store/entities/promotionItem';
import { InlineWrapper } from './promotionAction.styled';
import { trackAnalytics } from '../../../../utils/analytics/helpers';
import { TimeZoneWrapper } from '../shared/promotion.styled';
import { getStatusTag } from '../../store/promotions.helper';

const getInitialCodeInput = (): PromotionCodeTableInput => ({
  enabled: true,
  isNew: true,
  isUpdated: false,
  tableKey: uuidV4(),
  timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
});

interface PromotionCodesTableProps {
  codes: PromotionCodeTableInput[];
  removeCode: (index: number) => void;
  addCode: (code: PromotionCodeTableInput) => void;
  updateCode: (updatedCode: PromotionCodeTableInput, index: number) => void;
  promotionEnabled?: boolean;
  readOnly: boolean;
  showEnabledColumn: boolean;
}

function PromotionCodesTable({
  codes,
  removeCode,
  addCode,
  updateCode,
  promotionEnabled,
  readOnly,
  showEnabledColumn,
}: PromotionCodesTableProps): ReactElement {
  const trackAddNewCode = () =>
    trackAnalytics(`Promotion - Add new Code Clicked`, {
      workflow: `Promotion Manual Code Addition`,
      object_type: 'button',
      object_name: `Add new Code`,
    });

  const trackTimeZoneSelectorModify = (timeZone: string) =>
    trackAnalytics(`Promotion - Track Time Zone Modify`, {
      workflow: `Promotion Time Zone Modify`,
      object_type: 'dropdown',
      object_name: `Time Zone picker`,
      object_value: timeZone,
    });

  return (
    <Table basic>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Code*</Table.HeaderCell>
          <Table.HeaderCell>Usages/Max Usages</Table.HeaderCell>
          <Table.HeaderCell>Start/End Date</Table.HeaderCell>
          <Table.HeaderCell>Time Zone*</Table.HeaderCell>
          {readOnly && <Table.HeaderCell>Status</Table.HeaderCell>}
          {showEnabledColumn && <Table.HeaderCell>Enabled</Table.HeaderCell>}
          {!readOnly && <Table.HeaderCell>Actions</Table.HeaderCell>}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {codes.map((code, index) => (
          <Table.Row key={code.tableKey}>
            <Table.Cell>
              {code.isNew && !readOnly ? (
                <TextField
                  className={'small-area'}
                  value={code.code ?? ''}
                  onChange={event =>
                    updateCode({ ...code, code: event.target.value || undefined }, index)
                  }
                />
              ) : (
                code.code
              )}
            </Table.Cell>
            <Table.Cell>
              <InlineWrapper>
                {typeof code.usages === 'number' ? code.usages : '-'}
                {' / '}
                {readOnly ? (
                  code.maxUsages ?? '-'
                ) : (
                  <TextField
                    className={'small-area'}
                    size={10}
                    value={code.maxUsages ?? ''}
                    onChange={event =>
                      validateInputNumberValue(
                        event,
                        'maxUsages',
                        {
                          wholeNumberValues: {
                            fields: ['maxUsages'],
                          },
                        },
                        value =>
                          updateCode(
                            { ...code, maxUsages: value || undefined, isUpdated: true },
                            index,
                          ),
                      )
                    }
                  />
                )}
              </InlineWrapper>
            </Table.Cell>
            <Table.Cell>
              {readOnly ? (
                `${format(code.startDate, 'MMM d, yyyy')} / ${
                  code.endDate ? format(code.endDate, 'MMM d, yyyy') : '-'
                }`
              ) : (
                <PromotionCodeDateRange
                  startDate={code.startDate}
                  endDate={code.endDate}
                  timeZone={code.timeZone}
                  setStartDate={date =>
                    updateCode({ ...code, startDate: date, isUpdated: true }, index)
                  }
                  setEndDate={date =>
                    updateCode({ ...code, endDate: date, isUpdated: true }, index)
                  }
                />
              )}
            </Table.Cell>
            <Table.Cell>
              {readOnly ? (
                code.timeZone
              ) : (
                <TimeZoneWrapper>
                  <TimezonePicker
                    className={'w-80'}
                    value={code.timeZone}
                    onChange={timeZone => {
                      updateCode({ ...code, timeZone, isUpdated: true }, index);
                      trackTimeZoneSelectorModify(timeZone);
                    }}
                  />
                </TimeZoneWrapper>
              )}
            </Table.Cell>
            {readOnly && (
              <Table.Cell>
                <Tag
                  {...getStatusTag(
                    (promotionEnabled && code.enabled) ?? true,
                    code.startDate,
                    code.endDate,
                  )}
                />
              </Table.Cell>
            )}
            {showEnabledColumn && (
              <Table.Cell>
                {code.isNew || readOnly ? (
                  getBooleanValue(code.enabled)
                ) : (
                  <Checkbox
                    checked={code.enabled}
                    onChange={(_, data) =>
                      updateCode({ ...code, enabled: data.checked, isUpdated: true }, index)
                    }
                  />
                )}
              </Table.Cell>
            )}
            {!readOnly && (
              <Table.Cell>
                {code.isNew ? (
                  <Icon
                    icon={IconName.CLOSE}
                    size={IconSize.SMALL}
                    type={IconType.OUTLINE}
                    onClick={() => removeCode(index)}
                  />
                ) : (
                  <></>
                )}
              </Table.Cell>
            )}
          </Table.Row>
        ))}
      </Table.Body>
      {!readOnly && (
        <Table.Footer>
          <Button
            theme={'outline'}
            size={'medium'}
            onClick={() => {
              addCode(getInitialCodeInput());
              trackAddNewCode();
            }}
            className={'m-2xs'}
          >
            Add new code
          </Button>
        </Table.Footer>
      )}
    </Table>
  );
}

export default PromotionCodesTable;
