import * as React from 'react';
import { ReactElement } from 'react';
import { Table } from 'semantic-ui-react';
import { TableWrap } from '../multipleLocationWidget.styled';
import { getValPercentage } from '../../../utils/helpers';
import DetailPanelTrigger from './detailPanelTrigger';
import { Location } from '../../../generated/voyager/graphql';

interface OccupancyTabProps {
  locationsDetails: Location[];
}

function OccupancyTab({ locationsDetails }: OccupancyTabProps): ReactElement {
  return (
    <>
      <TableWrap>
        <Table basic="very" className="varColumns2 locations-tab-table">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Current Occupancy</Table.HeaderCell>
              <Table.HeaderCell>LG/SM SKU</Table.HeaderCell>
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {locationsDetails?.map(location => {
              const currentOccupancy = `${getValPercentage(location.metrics?.currentOccupancy)}%`;
              let largeSkuOccupancy: any = null;
              if (location.metrics?.largeSkuOccupancy) {
                largeSkuOccupancy = `${getValPercentage(location.metrics.largeSkuOccupancy)}%`;
              }
              let smallSkuOccupancy: any = null;
              if (location.metrics?.smallSkuOccupancy) {
                smallSkuOccupancy = `${getValPercentage(location.metrics.smallSkuOccupancy)}%`;
              }

              return (
                <Table.Row key={`location-widget-table-${location.id}`}>
                  <Table.Cell>{currentOccupancy}</Table.Cell>
                  <Table.Cell>
                    {`${largeSkuOccupancy ?? '-'} / ${smallSkuOccupancy ?? '-'}`}
                  </Table.Cell>
                  <Table.Cell>
                    <DetailPanelTrigger
                      currentDisplayableItem={location.id}
                      currentDisplayableItemType={'location'}
                    />
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </TableWrap>
    </>
  );
}

export default OccupancyTab;
