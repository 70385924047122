import * as React from 'react';
import { ReactElement, useCallback, useState } from 'react';
import { Button, ButtonSize, ButtonTheme, Textarea, TextField } from '@wework/ray2';
import { v4 as uuidV4 } from 'uuid';
import { Divider, Input } from 'semantic-ui-react';
import { difference, differenceBy, get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { GridCustomWrapper, MarginWrapper } from 'styles/app.styled';
import {
  PromoPageContentColumn,
  PromoPageRow,
  PromoPageRowWrapper,
  PromoPageSubTitle,
  PromoPageTitle,
} from './promotionAction.styled';
import PromotionCodesTable from './promotionCodesTable';
import PromotionProductsChecklist from '../shared/promotionProductsChecklist';
import { PromotionGridPaddingWrapper } from '../promotionDisplayTable/promotionViewTableAGGrid.styled';
import { PromotionByIdQueryInput, PromotionTableInput } from '../../store/entities/promotionItem';
import { validateInputNumberValue } from '../../../../utils/helpers';
import PromotionBulkCodes from './promotionBulkCodes';
import { SwitchStyled } from '../../../../styles/sharedTableFilterBar.styled';
import { trackAnalytics } from '../../../../utils/analytics/helpers';
import PromotionLocationsChecklist from '../shared/promotionLocationsChecklist';
import { PromotionCodesArgs } from '../../../../generated/voyager/graphql';
import { pageInfoTiny } from '../../../../utils/store/store.constants';
import { FETCH_PROMOTION_BY_ID, SET_SELECTED_PROMOTION } from '../../store/promotion.ducks';
import {
  fetchPromotionLoadingSelector,
  noMoreCodesSelector,
  selectedPromotionSelector,
} from '../../store/promotion.selector';
import { useDebounce } from '../../../pricing/standardPricing/components/helpers';

interface PromotionProps {
  mode: 'create' | 'update' | 'view';
}

function PromotionAction({ mode }: PromotionProps): ReactElement {
  const [searchData, setSearchData] = useState<PromotionCodesArgs>({ pageInfo: pageInfoTiny });

  const promotion = useSelector(selectedPromotionSelector);
  const promotionLoading = useSelector(fetchPromotionLoadingSelector);
  const noMoreCodes = useSelector(noMoreCodesSelector);

  const dispatch = useDispatch();
  const setSelectedPromotion = useCallback(
    (payload: PromotionTableInput | undefined) =>
      dispatch({ type: SET_SELECTED_PROMOTION, payload }),
    [dispatch],
  );
  const fetchPromotionById = useCallback(
    (payload: PromotionByIdQueryInput) => dispatch({ type: FETCH_PROMOTION_BY_ID, payload }),
    [dispatch],
  );
  const debouncedFetchPromotionById = useDebounce(
    (payload: PromotionByIdQueryInput) => fetchPromotionById(payload),
    500,
  );

  const setFieldValue = (fieldName: keyof PromotionTableInput, value: any) => {
    if (!promotion) {
      return;
    }

    setSelectedPromotion({
      ...promotion,
      [fieldName]: typeof value === 'boolean' ? value : value || undefined,
    });
  };

  const addArrayEntries = (fieldName: keyof PromotionTableInput, value: any | any[]) => {
    if (!promotion) {
      return;
    }

    setSelectedPromotion({
      ...promotion,
      [fieldName]: (promotion[fieldName] as any[]).concat(value),
    });
  };

  const updateArrayEntry = (
    fieldName: keyof PromotionTableInput,
    updateValue: any,
    updateIndex: number,
  ) => {
    if (!promotion) {
      return;
    }

    const updatedArray = [...(promotion[fieldName] as any[])];
    updatedArray.splice(updateIndex, 1, updateValue);
    setSelectedPromotion({
      ...promotion,
      [fieldName]: updatedArray,
    });
  };

  const removeArrayEntry = (fieldName: keyof PromotionTableInput, removeIndex: number) => {
    if (!promotion) {
      return;
    }

    const updatedArray = [...(promotion[fieldName] as any[])];
    updatedArray.splice(removeIndex, 1);
    setSelectedPromotion({
      ...promotion,
      [fieldName]: updatedArray,
    });
  };

  const removeArrayEntries = (fieldName: keyof PromotionTableInput, value: any[], key?: string) => {
    if (!promotion) {
      return;
    }

    setSelectedPromotion({
      ...promotion,
      [fieldName]: key
        ? differenceBy(promotion[fieldName] as any[], value, key)
        : difference(promotion[fieldName] as any[], value),
    });
  };

  const loadMore = () => {
    const newPageInfo = searchData.pageInfo
      ? {
          ...searchData.pageInfo,
          page: searchData.pageInfo?.page + 1,
        }
      : pageInfoTiny;
    setSearchData({ ...searchData, pageInfo: newPageInfo });
    fetchPromotionById({
      queryVariables: {
        id: promotion?.id,
        codePageInfo: newPageInfo,
        codeFilter: searchData.filter,
        codeSortInfos: searchData.sortInfos,
      },
      appendCodes: true,
    });
    trackLoadMore();
  };

  const trackToggleBulkPromo = () =>
    trackAnalytics(`Promotion - Manual vs Bulk promo creation toggle changed`, {
      workflow: `Promotion - Manual vs Bulk promo`,
      object_type: 'toggle',
      object_name: `Bulk vs Manual`,
    });

  const trackLoadMore = () =>
    trackAnalytics(`Promotion - Load More codes button used`, {
      workflow: `Promotion - Load More codes`,
      object_type: 'button',
      object_name: `Load More`,
    });

  const trackSearch = (searchTxt: string) =>
    trackAnalytics(`Promotion - Search Promotion Codes used`, {
      workflow: `Promotion - Search promotion codes`,
      object_type: 'textarea',
      object_name: `Search code`,
      object_value: searchTxt,
    });

  if (!promotion) {
    return <></>;
  }

  return (
    <PromotionGridPaddingWrapper>
      <GridCustomWrapper>
        <MarginWrapper>
          <PromoPageContentColumn>
            <PromoPageRowWrapper>
              <PromoPageRow>
                <div>
                  <PromoPageSubTitle>Name*:</PromoPageSubTitle>
                  {mode === 'view' ? (
                    promotion.name
                  ) : (
                    <TextField
                      value={promotion.name ?? ''}
                      onChange={event => setFieldValue('name', event.target.value)}
                      placeholder="Enter a name"
                    />
                  )}
                </div>
                <div>
                  <PromoPageSubTitle>Description:</PromoPageSubTitle>
                  {mode === 'view' ? (
                    promotion.description ?? '-'
                  ) : (
                    <Textarea
                      value={promotion.description ?? ''}
                      onChange={event => setFieldValue('description', event.target.value)}
                      placeholder="Enter a description"
                      id={uuidV4()}
                    />
                  )}
                </div>
              </PromoPageRow>
            </PromoPageRowWrapper>
            <PromoPageRowWrapper>
              <PromoPageRow>
                <div>
                  <PromoPageSubTitle>Approver*:</PromoPageSubTitle>
                  {mode === 'view' ? (
                    promotion.approver
                  ) : (
                    <TextField
                      value={promotion.approver ?? ''}
                      onChange={event => setFieldValue('approver', event.target.value)}
                      placeholder="Enter an approver"
                    />
                  )}
                </div>
                <div>
                  <PromoPageSubTitle>Requestor:</PromoPageSubTitle>
                  {mode === 'view' ? (
                    promotion.requestor ?? '-'
                  ) : (
                    <TextField
                      value={promotion.requestor ?? ''}
                      onChange={event => setFieldValue('requestor', event.target.value)}
                      placeholder="Enter a requestor"
                    />
                  )}
                </div>
              </PromoPageRow>
            </PromoPageRowWrapper>
            <PromoPageRowWrapper>
              <PromoPageRow>
                <div>
                  <PromoPageSubTitle>Discount*:</PromoPageSubTitle>
                  {mode === 'view' ? (
                    `${(promotion.distribution && promotion.distribution[0]?.discount) ?? '-'}%`
                  ) : (
                    <TextField
                      value={(promotion.distribution && promotion.distribution[0]?.discount) ?? ''}
                      onChange={event =>
                        validateInputNumberValue(
                          event,
                          'discount',
                          {
                            percentValues: {
                              fields: ['discount'],
                            },
                          },
                          value =>
                            setFieldValue('distribution', [
                              {
                                month: 1,
                                discount: value || undefined,
                              },
                            ]),
                        )
                      }
                      placeholder="Enter a Discount"
                    />
                  )}
                </div>
              </PromoPageRow>
            </PromoPageRowWrapper>
            <Divider />
            <PromoPageRowWrapper>
              <PromoPageRow className={'full-width'}>
                <div className={'full-width'}>
                  <PromoPageTitle>Limitations:</PromoPageTitle>
                </div>
                <div className={'full-width'}>
                  <PromoPageSubTitle>Products:</PromoPageSubTitle>
                  <PromotionProductsChecklist
                    productLimitations={promotion.productLimitations ?? []}
                    removeProductLimitations={productIds =>
                      removeArrayEntries('productLimitations', productIds)
                    }
                    addProductLimitations={productIds =>
                      addArrayEntries('productLimitations', productIds)
                    }
                    readOnly={mode === 'view'}
                    defaultAllSelected={mode === 'create'}
                  />
                </div>
                <div className={'full-width'}>
                  <PromoPageSubTitle>Locations:</PromoPageSubTitle>
                  <PromotionLocationsChecklist
                    geoHierarchyLimitations={promotion.geoHierarchyLimitations ?? []}
                    removeGeoHierarchyLimitations={geoHierarchyIds =>
                      removeArrayEntries('geoHierarchyLimitations', geoHierarchyIds)
                    }
                    addGeoHierarchyLimitations={geoHierarchyIds =>
                      addArrayEntries('geoHierarchyLimitations', geoHierarchyIds)
                    }
                    readOnly={mode === 'view'}
                  />
                </div>
              </PromoPageRow>
            </PromoPageRowWrapper>
            <Divider />
            <PromoPageRowWrapper>
              <PromoPageRow className={'full-width'}>
                <PromoPageTitle id={'promotion-codes'}>Promotion Codes*:</PromoPageTitle>
                {mode === 'create' && (
                  <div className={'full-width'}>
                    <p>Manual</p>
                    <SwitchStyled className="switch">
                      <input
                        type="checkbox"
                        checked={promotion.isBulkPromotion}
                        onChange={event => {
                          setFieldValue('isBulkPromotion', get(event, 'target.checked', false));
                          trackToggleBulkPromo();
                        }}
                      />
                      <span className="slider" />
                    </SwitchStyled>
                    <p>Bulk</p>
                  </div>
                )}
                {mode !== 'create' && (
                  <div className={'full-width'}>
                    <PromoPageSubTitle>Total codes:</PromoPageSubTitle>
                    {promotion.totalCodes}
                  </div>
                )}
              </PromoPageRow>
            </PromoPageRowWrapper>
            {!(promotion.isBulkPromotion && mode === 'create') && (
              <PromoPageRowWrapper>
                <PromoPageRow className={'promo-table'}>
                  {(promotion.isBulkPromotion || mode === 'view') && (
                    <Input
                      value={searchData.filter?.codeContains ?? ''}
                      icon="search"
                      iconPosition="left"
                      placeholder="Search by code"
                      onChange={event => {
                        const searchTxt = event.target.value;
                        const filter = { ...searchData.filter, codeContains: searchTxt };
                        const pageInfo = searchData.pageInfo
                          ? { ...searchData.pageInfo, page: 1 }
                          : pageInfoTiny;
                        setSearchData({ ...searchData, filter, pageInfo });
                        debouncedFetchPromotionById({
                          queryVariables: {
                            id: promotion?.id,
                            codePageInfo: pageInfo,
                            codeFilter: filter,
                            codeSortInfos: searchData.sortInfos,
                          },
                        });
                        trackSearch(searchTxt);
                      }}
                    />
                  )}
                  <div className={'full-width'}>
                    <PromotionCodesTable
                      readOnly={promotion.isBulkPromotion || mode === 'view'}
                      showEnabledColumn={mode !== 'create'}
                      promotionEnabled={promotion.enabled}
                      codes={promotion.manualCodeCreation ?? []}
                      removeCode={index => removeArrayEntry('manualCodeCreation', index)}
                      addCode={code => addArrayEntries('manualCodeCreation', code)}
                      updateCode={(updatedCode, index) =>
                        updateArrayEntry('manualCodeCreation', updatedCode, index)
                      }
                    />
                  </div>
                  {mode !== 'create' && (
                    <Button
                      className={'load-more'}
                      size={ButtonSize.SMALL}
                      theme={ButtonTheme.OUTLINE}
                      loading={promotionLoading}
                      disabled={noMoreCodes}
                      onClick={loadMore}
                    >
                      {noMoreCodes ? 'No more codes' : 'Load more ...'}
                    </Button>
                  )}
                </PromoPageRow>
              </PromoPageRowWrapper>
            )}
            {promotion.isBulkPromotion && mode !== 'view' && (
              <PromotionBulkCodes
                setBulkCodeCreation={bulkCodeCreation =>
                  setFieldValue('bulkCodeCreation', bulkCodeCreation)
                }
                bulkCodeCreation={promotion.bulkCodeCreation}
                mode={mode}
              />
            )}
          </PromoPageContentColumn>
        </MarginWrapper>
      </GridCustomWrapper>
    </PromotionGridPaddingWrapper>
  );
}

export default PromotionAction;
