import { ValueGetterParams } from 'ag-grid-community';
import * as React from 'react';
import { ReactElement } from 'react';
import { Tag } from '@wework/dieter-ui';
import { HashLink } from 'react-router-hash-link';
import TextLink from '../../../../../sharedComponents/styledText/styledText';
import { trackAnalytics } from '../../../../../utils/analytics/helpers';
import { getStatusTag } from '../../../store/promotions.helper';
import ROUTES from '../../../../../utils/routes/routes';

const PromotionStatusRenderer = (params: ValueGetterParams): ReactElement => {
  // Case 1: When there is no data.
  if (!params.data) {
    return <></>;
  }

  const { enabled } = params.data;

  // Case 2: When there is more than one code.
  if (params.data.codes.length > 1 && enabled) {
    return (
      <TextLink
        title={'Status may vary between codes'}
        onClick={() =>
          trackAnalytics(`Promotion - View More Clicked`, {
            workflow: `Promotion - view more`,
            object_type: 'button',
            object_name: `View More`,
          })
        }
      >
        <HashLink to={`${ROUTES.PROMOTION_VIEW_PAGE}/${params.data.id}#promotion-codes`}>
          View More
        </HashLink>
      </TextLink>
    );
  }

  // Case 3: When there is only one promo code.
  // Remember there will definitely be at least one code.
  const { startDate, endDate, enabled: codeEnabled } = params.data.codes[0];

  return <Tag {...getStatusTag(enabled && codeEnabled, startDate, endDate)} />;
};

export default PromotionStatusRenderer;
