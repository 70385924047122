import React, { ReactElement } from 'react';
import { Text, TextAreaProps } from '@wework/dieter-ui';
import { MarginWrapper } from '../../styles/app.styled';
import {
  ButtonGroup,
  ButtonRow,
  PageControlButton,
  PageTextArea,
} from '../../app/adminPanel/components/syncAttempts/syncAttempts.styled';
import {
  inputNumberFormatterNoNegative,
  usePrevious,
} from '../../app/pricing/standardPricing/components/helpers';
import { PageInfo } from '../../generated/voyager/graphql';
import { isArrayEqual } from '../../utils/helpers';
import { trackAnalytics } from '../../utils/analytics/helpers';

interface PaginationComponentProps {
  allItems: any;
  pageInfo: PageInfo;
  setPageInfo: (pageInfo: PageInfo) => void;
  featureName: string;
}

function PaginationComponent({
  allItems,
  pageInfo,
  setPageInfo,
  featureName,
}: PaginationComponentProps): ReactElement {
  const perPage = pageInfo.size;
  const prevAllItems = usePrevious(allItems);

  const trackPaginationClick = (btnType: string) =>
    trackAnalytics(`${featureName}- Pagination ${btnType} button Clicked`, {
      workflow: `${featureName} Pagination ${btnType} button`,
      object_type: 'button',
      object_name: `Pagination ${btnType}`,
    });

  const trackPaginationInput = (pageValue: string) =>
    trackAnalytics(`${featureName} - Pagination input page navigation`, {
      workflow: `${featureName} Pagination page input`,
      object_type: 'textarea',
      object_name: `Pagination page input`,
      object_value: pageValue,
    });

  function nextPage() {
    const pi: PageInfo = {
      size: perPage,
      page: pageInfo.page + 1,
    };
    setPageInfo(pi);
  }

  function prevPage() {
    const pi: PageInfo = {
      size: perPage,
      page: pageInfo.page - 1,
    };
    setPageInfo(pi);
  }

  return (
    <MarginWrapper>
      <ButtonRow>
        <Text>Page:</Text>
        <PageTextArea
          value={pageInfo.page}
          onChange={(_event: React.ChangeEvent<HTMLTextAreaElement>, data: TextAreaProps) => {
            if (typeof data.value !== 'undefined') {
              const pageNumStr = data.value.toString();
              const p = Number(inputNumberFormatterNoNegative(pageNumStr));
              const pi: PageInfo = {
                size: perPage,
                page: p - 1,
              };
              setPageInfo(pi);
              trackPaginationInput(pageNumStr);
            }
          }}
        />
        <ButtonGroup>
          <PageControlButton
            default
            disabled={pageInfo.page === 1}
            onClick={() => {
              prevPage();
              trackPaginationClick('Back');
            }}
          >
            Back
          </PageControlButton>
          <PageControlButton
            default
            disabled={allItems?.length !== perPage || isArrayEqual(allItems, prevAllItems)}
            onClick={() => {
              nextPage();
              trackPaginationClick('Next');
            }}
          >
            Next
          </PageControlButton>
        </ButtonGroup>
      </ButtonRow>
    </MarginWrapper>
  );
}

export default PaginationComponent;
