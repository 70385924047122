import * as React from 'react';
import { ReactElement, useState } from 'react';
import cn from 'classnames';
import TimezonePicker from 'react-timezone';
import {
  ActiveFilterValues,
  FilterBody,
  FilterHeader,
  FilterItemOption,
  PanelFilterItem,
} from '../../../../styles/app.styled';
import { PromotionFilterProps } from './promotionTableFilterBar';
import { formatDate } from '../../../../utils/helpers';
import PromotionCodeDateRange from '../shared/promotionCodeDateRange';
import { TimeZoneWrapper } from '../shared/promotion.styled';
import { trackAnalytics } from '../../../../utils/analytics/helpers';

function PromotionStartEndDateFilter({ filter, setFilter }: PromotionFilterProps): ReactElement {
  const [accordionOpen, setAccordionOpen] = useState<boolean>(true);

  const trackStartEndDateFilterPromotions = (dateFilterName: string, filterValue?: Date) =>
    trackAnalytics(`Promotion - Start/End Date Filter Promotion used`, {
      workflow: `Promotion - Filter promotion`,
      object_type: 'date',
      object_name: `${dateFilterName} Filter promotion`,
      object_value: filterValue,
    });
  const trackTimeZoneFilterPromotionsModify = (timeZone: string) =>
    trackAnalytics(`Promotion - Time Zone in Filter Promotion Modified`, {
      workflow: `Promotion - Filter promotion`,
      object_type: 'dropdown',
      object_name: `Time Zone picker`,
      object_value: timeZone,
    });

  return (
    <PanelFilterItem className={cn({ open: accordionOpen, closed: !accordionOpen })}>
      <FilterHeader className="filter-header">
        <button type="button" onClick={() => setAccordionOpen(!accordionOpen)}>
          <span>{'Start & End Date Range'}</span>
          <i className="toggle-accordion-icon" />
        </button>
        {!accordionOpen && (filter.startDateOnOrBefore || filter.startDateOnOrAfter) && (
          <ActiveFilterValues>{`Starts${
            filter.startDateOnOrAfter ? ` after ${formatDate(filter.startDateOnOrAfter)}` : ''
          }${
            filter.startDateOnOrBefore ? ` before ${formatDate(filter.startDateOnOrBefore)}` : ''
          }`}</ActiveFilterValues>
        )}
        {!accordionOpen && (filter.endDateOnOrBefore || filter.endDateOnOrAfter) && (
          <ActiveFilterValues>{`Ends${
            filter.endDateOnOrAfter ? ` after ${formatDate(filter.endDateOnOrAfter)}` : ''
          }${
            filter.endDateOnOrBefore ? ` before ${formatDate(filter.endDateOnOrBefore)}` : ''
          }`}</ActiveFilterValues>
        )}
      </FilterHeader>
      <FilterBody className="filter-body">
        <FilterItemOption>
          <TimeZoneWrapper>
            <TimezonePicker
              className={'w-80'}
              value={filter.timeZone}
              onChange={timeZone => {
                setFilter({ ...filter, timeZone });
                trackTimeZoneFilterPromotionsModify(timeZone);
              }}
            />
          </TimeZoneWrapper>
        </FilterItemOption>
        <FilterItemOption>
          <p>{'Start date (from / to)'}</p>
          <PromotionCodeDateRange
            startDate={filter.startDateOnOrAfter}
            endDate={filter.startDateOnOrBefore}
            timeZone={filter.timeZone}
            setStartDate={date => {
              setFilter({ ...filter, startDateOnOrAfter: date });
              trackStartEndDateFilterPromotions('startDateOnOrAfter', date);
            }}
            setEndDate={date => {
              setFilter({ ...filter, startDateOnOrBefore: date });
              trackStartEndDateFilterPromotions('startDateOnOrBefore', date);
            }}
            pastDisabled={false}
            showTooltip={false}
          />
        </FilterItemOption>
        <FilterItemOption>
          <p>{'End date (from / to)'}</p>
          <PromotionCodeDateRange
            startDate={filter.endDateOnOrAfter}
            endDate={filter.endDateOnOrBefore}
            timeZone={filter.timeZone}
            setStartDate={date => {
              setFilter({ ...filter, endDateOnOrAfter: date });
              trackStartEndDateFilterPromotions('endDateOnOrAfter', date);
            }}
            setEndDate={date => {
              setFilter({ ...filter, endDateOnOrBefore: date });
              trackStartEndDateFilterPromotions('endDateOnOrBefore', date);
            }}
            pastDisabled={false}
            showTooltip={false}
          />
        </FilterItemOption>
      </FilterBody>
    </PanelFilterItem>
  );
}

export default PromotionStartEndDateFilter;
