import * as React from 'react';
import '@wework/ray2/dist/foundations.css';
import { ReactElement } from 'react';
import cn from 'classnames';
import { withRequestedAuthz } from '@wework/we-auth-react';
import { get } from 'lodash';
import { IconName } from '@wework/ray2';
import {
  AppItem,
  AppList,
  AppSidebarNav,
  SidebarRegionStyled,
  SidebarStyled,
} from './sidebar.styled';
import AppNavItem from './appNavItem';
import config from '../../config';
import { AuthzProps } from '../../utils/constants';
import EmployeePermissions from '../../utils/store/permissions';
import ROUTES from '../../utils/routes/routes';

function SidebarComponent({ requestedPermissions }: AuthzProps): ReactElement {
  const devEnv = config.APP_ENV !== 'production';

  const applicationNavItems = [
    {
      name: 'home',
      content: <AppNavItem icon={IconName.HOME} name="home" disabled={false} route="/home" />,
    },
  ];

  if (get(requestedPermissions, EmployeePermissions.voyager_dedicated_space_price_view, false)) {
    applicationNavItems.push({
      name: 'pricing',
      content: (
        <AppNavItem icon={IconName.MONEY} name="pricing" disabled={false} route="/pricing" />
      ),
    });
  }

  if (get(requestedPermissions, EmployeePermissions.voyager_dedicated_space_discount_view, false)) {
    applicationNavItems.push({
      name: 'discounts',
      content: (
        <AppNavItem icon={IconName.TAG} name="discounts" disabled={false} route="/discount" />
      ),
    });
  }

  if (
    get(requestedPermissions, EmployeePermissions.voyager_dedicated_space_breakeven_view, false)
  ) {
    applicationNavItems.push({
      name: 'breakeven',
      content: (
        <AppNavItem
          icon={IconName.WHITEBOARD}
          name="breakeven"
          disabled={false}
          route="/breakeven"
        />
      ),
    });
  }

  if (get(requestedPermissions, EmployeePermissions.voyager_on_demand_price_view, false)) {
    applicationNavItems.push({
      name: 'on-demand',
      content: (
        <AppNavItem
          icon={IconName.AIR}
          name="on-demand"
          disabled={false}
          route="/pricing/ondemand"
        />
      ),
    });
  }

  if (get(requestedPermissions, EmployeePermissions.voyager_promotions_view, false)) {
    applicationNavItems.push({
      name: 'promotions',
      content: (
        <AppNavItem
          icon={IconName.ART}
          name="promotions"
          disabled={false}
          route={ROUTES.PROMOTION_TABLEVIEW}
        />
      ),
    });
  }

  const isSysAdmin = get(requestedPermissions, EmployeePermissions.voyager_system_admin, false);

  return (
    <SidebarRegionStyled>
      <SidebarStyled className={cn({ development: devEnv }, 'bg-gray-97')}>
        <AppSidebarNav>
          <AppList>
            {applicationNavItems.map((application: any) => (
              <AppItem key={application.name}>{application.content}</AppItem>
            ))}
          </AppList>
          <AppList>
            {isSysAdmin && (
              <AppItem key="admin-panel">
                <AppNavItem
                  icon={IconName.UTILITIES}
                  name="admin-panel"
                  disabled={false}
                  route="/admin-panel"
                />
              </AppItem>
            )}
            <AppItem key="action-items">
              <AppNavItem
                icon={IconName.BELL}
                name="action-items"
                disabled={false}
                route="/action-items"
              />
            </AppItem>
            <AppItem key="logout-button">
              <AppNavItem
                icon={IconName.HAND}
                name="logout-button"
                disabled={false}
                route="/logout"
              />
            </AppItem>
          </AppList>
        </AppSidebarNav>
      </SidebarStyled>
    </SidebarRegionStyled>
  );
}
export default withRequestedAuthz<AuthzProps>({
  permissions: [
    EmployeePermissions.voyager_dedicated_space_breakeven_view,
    EmployeePermissions.voyager_dedicated_space_price_view,
    EmployeePermissions.voyager_dedicated_space_discount_view,
    EmployeePermissions.voyager_system_admin,
    EmployeePermissions.voyager_on_demand_price_view,
    EmployeePermissions.voyager_promotions_view,
  ],
})(SidebarComponent);
