import * as React from 'react';
import { ReactElement, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { CartesianGrid, Line, LineChart, YAxis } from 'recharts';
import cn from 'classnames';
import { isEmpty } from 'lodash';
import { v4 as uuidV4 } from 'uuid';
import { Button, IconName, IconType } from '@wework/ray2';
import { compareDesc, isBefore } from 'date-fns';
import {
  DataBlock,
  DataList,
  DataRow,
  DetailDefinition,
  InfoBlock,
  InfoBlockTitle,
  ListItemValue,
} from '../detailSidePanel.styled';
import {
  isNumberNullFormat,
  isPercentageNull,
} from '../../../app/pricing/standardPricing/components/helpers';
import { getFetchFeatureCardState } from '../../../app/pricing/standardPricing/store/modules/priceableItems/priceableItems.selector';
import {
  ChangelogList,
  ChangelogListItem,
} from '../../../app/pricing/standardPricing/components/pricingTable/pricingTable.styled';
import { formatDate, intlCurrencyFormatter, toTitleCase } from '../../../utils/helpers';
import { FetchState } from '../../../utils/sharedTypes';
import { MarketPrice, PriceableItem } from '../../../generated/voyager/graphql';

interface ItemBlockInfo {
  label: string | null;
  value: string | null;
  highlight: boolean;
}

interface ItemBlockLocationOccupancy {
  last6M: number | null;
  last3M: number | null;
  current: number | null;
  next3M: number | null;
  next6M: number | null;
}

export interface ItemBlockDesks {
  hotDeskCount: number | null;
  dedicatedDeskCount: number | null;
  sharedOfficeCount: number | null;
  privateOfficeCount: number | null;
}

export interface ItemBlockOccupancy {
  locationSku: ItemBlockLocationOccupancy;
  largeSku: ItemBlockInfo[];
  smallSku: ItemBlockInfo[];
}

export interface ItemBlockFinancial {
  minPrice: ItemBlockInfo[];
  avgNetArpm: ItemBlockInfo[];
  avgDiscount: ItemBlockInfo[];
  largeSkuNetArpm: ItemBlockInfo[];
  smallSkuNetArpm: ItemBlockInfo[];
  marketPrice: ItemBlockInfo[];
  reservationPrice: ItemBlockInfo[];
}

const infoBlock = (title: string, className: string, content: ItemBlockInfo[]): ReactElement => (
  <InfoBlock>
    {title !== '' && <InfoBlockTitle>{title}</InfoBlockTitle>}
    <DataRow className={className}>
      {content.map(attr => (
        <DataBlock key={uuidV4()} className={cn({ current: attr.highlight })}>
          <span>{attr.label}</span>
          <strong>{attr.value}</strong>
        </DataBlock>
      ))}
    </DataRow>
  </InfoBlock>
);

/**
 * Detail block for type: desks
 */
interface ItemDetailBlockDesksProps {
  deskCountData: ItemBlockDesks;
}
export function ItemDetailBlockDesks({ deskCountData }: ItemDetailBlockDesksProps): ReactElement {
  return (
    <ListItemValue>
      <li>
        <span>Hot Desks</span>
        <span>{deskCountData.hotDeskCount ?? '-'}</span>
      </li>
      <li>
        <span>Dedicated Desks</span>
        <span>{deskCountData.dedicatedDeskCount ?? '-'}</span>
      </li>
      <li>
        <span>Shared Offices</span>
        <span>{deskCountData.sharedOfficeCount ?? '-'}</span>
      </li>
      <li>
        <span>Dedicated Offices</span>
        <span>{deskCountData.privateOfficeCount ?? '-'}</span>
      </li>
    </ListItemValue>
  );
}

/**
 * Detail block for type: occupancy
 */
interface ItemDetailBlockOccupancyProps {
  occupancyData: ItemBlockOccupancy;
}
export function ItemDetailBlockOccupancy({
  occupancyData,
}: ItemDetailBlockOccupancyProps): ReactElement {
  const locationSkuData = occupancyData.locationSku;
  const locationSkuOccupancyChartData = useMemo(
    () => [
      {
        name: 'Last 6M',
        uv: locationSkuData.last6M?.toFixed(2),
      },
      {
        name: 'Last 3M',
        uv: locationSkuData.last3M?.toFixed(2),
      },
      {
        name: 'Current',
        uv: locationSkuData.current?.toFixed(2),
      },
      {
        name: 'Next 3M',
        uv: locationSkuData.next3M?.toFixed(2),
      },
      {
        name: 'Next 6M',
        uv: locationSkuData.next6M?.toFixed(2),
      },
    ],
    [locationSkuData],
  );

  const showChart = !!(
    locationSkuData.next6M ||
    locationSkuData.last3M ||
    locationSkuData.current ||
    locationSkuData.next3M ||
    locationSkuData.next6M
  );

  // This method is to convert decimal data to percentage.
  const adjustData = useCallback(
    (value: string) => `${(parseFloat(value) * 100).toFixed(0).toString()}%`,
    [],
  );

  return (
    <>
      <DetailDefinition>Placeholder text for the definition of occupancy.</DetailDefinition>
      <InfoBlock>
        <InfoBlockTitle>Location SKU</InfoBlockTitle>
        {showChart && (
          <LineChart
            width={279}
            height={166}
            margin={{ left: -24 }}
            data={locationSkuOccupancyChartData}
            className="line-chart"
          >
            <Line type="monotone" dataKey="uv" stroke="#8884d8" animationDuration={0} />
            <CartesianGrid stroke="#ccc" />
            <YAxis dataKey="uv" tickFormatter={adjustData} />
          </LineChart>
        )}
        <DataRow className={cn({ 'x-axis': showChart, blocks5: !showChart })}>
          <DataBlock>
            <span>Last 6M</span>
            <strong>{isPercentageNull(locationSkuData.last6M) ?? '-'}</strong>
          </DataBlock>
          <DataBlock>
            <span>Last 3M</span>
            <strong>{isPercentageNull(locationSkuData.last3M) ?? '-'}</strong>
          </DataBlock>
          <DataBlock className="current">
            <span>Current</span>
            <strong>{isPercentageNull(locationSkuData.current) ?? '-'}</strong>
          </DataBlock>
          <DataBlock>
            <span>Next 3M</span>
            <strong>{isPercentageNull(locationSkuData.next3M) ?? '-'}</strong>
          </DataBlock>
          <DataBlock>
            <span>Next 6M</span>
            <strong>{isPercentageNull(locationSkuData.next6M) ?? '-'}</strong>
          </DataBlock>
        </DataRow>
      </InfoBlock>
      {infoBlock('Large SKU', 'blocks5', occupancyData.largeSku)}
      {infoBlock('Small SKU', 'blocks5', occupancyData.smallSku)}
    </>
  );
}

/**
 * Detail block for type: financial
 */
interface ItemDetailBlockFinancialProps {
  financialData: ItemBlockFinancial;
}
export function ItemDetailBlockFinancial({
  financialData,
}: ItemDetailBlockFinancialProps): ReactElement {
  return (
    <>
      <DetailDefinition>Placeholder text for the definition of financial health.</DetailDefinition>
      {/* TODO: REMOVING WRONG DATA need to uncomment once fixed the data - BC. */}
      {/* {infoBlock('Breakeven Price', 'blocks3', financialData.minPrice)} */}
      {infoBlock('Average Net ARPM', 'blocks3', financialData.avgNetArpm)}
      {infoBlock('Average Discount', 'blocks3', financialData.avgDiscount)}
      {infoBlock('Large SKU Net ARPM', 'blocks3', financialData.largeSkuNetArpm)}
      {infoBlock('Small SKU Net ARPM', 'blocks3', financialData.smallSkuNetArpm)}
    </>
  );
}
/**
 * Detail block for type: market price
 */
export function ItemDetailBlockMarketPrice(priceData: ItemBlockFinancial): ReactElement {
  return (
    <>
      <DetailDefinition>Placeholder text for the definition of market price.</DetailDefinition>
      {infoBlock('', 'blocks6', priceData.marketPrice)}
    </>
  );
}
/**
 * Detail block for type: reservation price
 */
export function ItemDetailBlockReservationPrice(priceData: ItemBlockFinancial): ReactElement {
  return (
    <>
      <DetailDefinition>Placeholder text for the definition of reservation price</DetailDefinition>
      {infoBlock('', 'blocks6', priceData.reservationPrice)}
    </>
  );
}

function listAllFeatures(priceableItemData: PriceableItem | null) {
  const tempFeatures = priceableItemData?.recommendedPrice?.features ?? [];

  if (isEmpty(tempFeatures)) {
    return <li>Reasons are not given for the suggested price.</li>;
  }

  return tempFeatures.map((eachFeature: any) => (
    <li key={eachFeature?.ordinal}>
      <span>{eachFeature?.ordinal}</span>
      <span>{eachFeature?.value}</span>
    </li>
  ));
}

export function ItemDetailBlockSuggestedPrice(
  suggestedPriceData: PriceableItem | null,
): ReactElement {
  const fetchFeatureCardState = useSelector(getFetchFeatureCardState);
  return (
    <>
      <DetailDefinition>Placeholder text for the definition of suggested price</DetailDefinition>
      <InfoBlock>
        {fetchFeatureCardState.loading ? (
          <Button
            size="large"
            theme="text"
            icon={IconName.AIR}
            iconType={IconType.OUTLINE}
            loading
          />
        ) : (
          <DataList>{listAllFeatures(suggestedPriceData)}</DataList>
        )}
      </InfoBlock>
      <DataRow className="blocks4">
        <DataBlock>
          <span>Minimum</span>
          <strong>
            {isNumberNullFormat(
              suggestedPriceData?.currentPrice?.currencyIsoCode ?? '',
              suggestedPriceData?.recommendedPrice?.minPrice,
            )}
          </strong>
        </DataBlock>
        <DataBlock>
          <span>Recomm.</span>
          <strong>
            {isNumberNullFormat(
              suggestedPriceData?.currentPrice?.currencyIsoCode ?? '',
              suggestedPriceData?.recommendedPrice?.price,
            )}
          </strong>
        </DataBlock>
        <DataBlock>
          <span>Maximum</span>
          <strong>
            {isNumberNullFormat(
              suggestedPriceData?.currentPrice?.currencyIsoCode ?? '',
              suggestedPriceData?.recommendedPrice?.maxPrice,
            )}
          </strong>
        </DataBlock>
        <DataBlock>
          <span>Confidence</span>
          <strong>{suggestedPriceData?.recommendedPrice?.confidenceLevel ?? '-'}</strong>
        </DataBlock>
      </DataRow>
    </>
  );
}

const changeLogListItems = (
  marketPriceDataChangeLog: MarketPrice[],
  currencyIsoCode: string,
  timeZone: string,
  fetchFeatureCardState: FetchState,
) => {
  if (fetchFeatureCardState?.loading) {
    return (
      <Button size="large" theme="text" icon={IconName.AIR} iconType={IconType.OUTLINE} loading />
    );
  }

  const now = new Date();
  const prevMarketPriceDataChangeLog = marketPriceDataChangeLog
    .filter((eachItem: MarketPrice) => isBefore(new Date(eachItem.validFrom), now))
    .sort((item1, item2) => compareDesc(new Date(item1.validFrom), new Date(item2.validFrom)));

  return fetchFeatureCardState?.loaded && prevMarketPriceDataChangeLog?.length ? (
    prevMarketPriceDataChangeLog.map(changeLogItem => (
      <ChangelogListItem key={changeLogItem.id} className="side-panel">
        {intlCurrencyFormatter(currencyIsoCode, changeLogItem.price)} on{' '}
        {formatDate(changeLogItem.validFrom, timeZone)} by
        {` ${toTitleCase(changeLogItem.createdBy)}`}
      </ChangelogListItem>
    ))
  ) : (
    <div>No Change Log</div>
  );
};

export function ItemDetailBlockPriceHistory(marketPriceData: PriceableItem | null): ReactElement {
  const fetchFeatureCardState = useSelector(getFetchFeatureCardState);

  return (
    <ChangelogList className="full-height">
      {changeLogListItems(
        marketPriceData?.prices ?? [],
        marketPriceData?.currentPrice?.currencyIsoCode ?? '',
        marketPriceData?.location?.timeZone ?? '',
        fetchFeatureCardState,
      )}
    </ChangelogList>
  );
}
