import { GridReadyEvent } from 'ag-grid-community';
import TableLoadingRenderer from 'sharedComponents/tableComponent/tableLoadingRenderer';

/**
 * Fit columns when grid size changed
 */
const onGridSizeChanged = (params: GridReadyEvent): void => {
  if (params.columnApi) {
    params.columnApi.autoSizeAllColumns();
  }
  if (params.api) {
    params.api.sizeColumnsToFit();
  }
};

export const columnDefs = [
  {
    headerName: 'Row number',
    field: 'rowNumber',
    sortable: true,
    minWidth: 50,
    maxWidth: 100,
  },
  {
    headerName: 'Error description',
    field: 'error',
    cellClass: 'background-gray',
  },
];

export const defaultColDef = {
  suppressMenu: true,
};

export const gridOptions = {
  rowSelection: 'multiple',
  suppressNoRowsOverlay: true,
  onGridSizeChanged,
  rowHeight: 56,
  loadingOverlayComponent: TableLoadingRenderer,
  suppressMaxRenderedRowRestriction: true,
  suppressAnimationFrame: true,
  suppressContextMenu: true,
  suppressCellFocus: true,
  suppressRowClickSelection: true,
  suppressRowHoverHighlight: true,
  suppressAggFuncInHeader: true,
  defaultColDef,
  enableCellTextSelection: true,
  ensureDomOrder: true,
};
