import { all, call, put, takeLatest } from 'redux-saga/effects';
import { get, isEmpty } from 'lodash';
import { DocumentNode } from 'graphql';
import { Action } from 'redux-actions';
import { toast } from 'react-toastify';
import {
  CREATE_PROMOTION,
  CREATE_PROMOTION_SUCCESS,
  FETCH_PROMOTION_BY_ID,
  FETCH_PROMOTION_BY_ID_SUCCESS,
  FETCH_PROMOTION_ITEMS,
  FETCH_PROMOTION_ITEMS_SUCCESS,
  FETCH_PROMOTION_LOCATIONS,
  FETCH_PROMOTION_LOCATIONS_SUCCESS,
  FETCH_PROMOTION_PRODUCTS,
  FETCH_PROMOTION_PRODUCTS_SUCCESS,
  UPDATE_PROMOTION,
  UPDATE_PROMOTION_SUCCESS,
  UPDATE_PROMOTIONS_ENABLED,
  UPDATE_PROMOTIONS_ENABLED_SUCCESS,
  FETCH_PROMOTION_WITH_CHANGELOG_SUCCESS,
  FETCH_PROMOTION_WITH_CHANGELOG,
} from './promotion.ducks';
import {
  MutationCreatePromotionArgs,
  MutationUpdatePromotionArgs,
  MutationUpdatePromotionsEnabledArgs,
  QueryPromotionsArgs,
} from '../../../generated/voyager/graphql';
import { errorHandlerActive } from '../../../utils/errorHandling/helpers';
import {
  FETCH_ALL_PROMOTIONS_QUERY,
  FETCH_PROMOTION_BY_ID_QUERY,
  FETCH_PROMOTION_WITH_CHANGELOG_QUERY,
  FETCH_PROMOTION_LOCATIONS_QUERY,
  FETCH_PROMOTION_PRODUCTS_QUERY,
} from './promotionItems.query';
import { graphQLClient } from '../../../index';
import { DataWithCallback } from '../../../utils/sharedTypes';
import {
  CREATE_PROMOTION_MUTATION,
  UPDATE_PROMOTION_MUTATION,
  UPDATE_PROMOTIONS_ENABLED_MUTATION,
} from './promotionItems.mutation';
import { PromotionByIdQueryInput, smallCodePageInfo } from './entities/promotionItem';

const executeGraphQLQuery = (query: DocumentNode, variables?: any): Promise<any> =>
  graphQLClient.query({
    fetchPolicy: 'network-only',
    query,
    variables,
  });

const executeGraphQLMutation = (mutation: DocumentNode, variables?: any): Promise<any> =>
  graphQLClient.mutate({
    mutation,
    variables,
  });

// Saga - for fetching the promotion items in batches.
function* fetchPromotionItemsWorker(action: Action<QueryPromotionsArgs>) {
  try {
    // 1) Trying fetch the data for next page.
    const { errors, data } = yield call(executeGraphQLQuery, FETCH_ALL_PROMOTIONS_QUERY, {
      ...action.payload,
      codePageInfo: smallCodePageInfo,
    });

    // 2) IF, there are any errors in fetching, then end the batch fetch and throw an error
    if (!isEmpty(errors)) {
      toast.error(`Fetching error - ${get(errors[0], 'message', 'Unknown Error')}`);
      errorHandlerActive(new Error(errors[0]));
      return;
    }

    yield put({ type: FETCH_PROMOTION_ITEMS_SUCCESS, data });
  } catch (e: any) {
    toast.error(`Fetching error - ${get(e, 'message', 'Unknown Error')}`);
    errorHandlerActive(new Error(e));
  }
}
function* fetchPromotionByIdWorker(action: Action<PromotionByIdQueryInput>) {
  try {
    const { errors, data } = yield call(
      executeGraphQLQuery,
      FETCH_PROMOTION_BY_ID_QUERY,
      action.payload.queryVariables,
    );

    if (!isEmpty(errors) || !data?.promotion) {
      toast.error(
        `Cannot fetch promotion: ${errors.map((error: any) => error.message).join(', ')}`,
      );
    }

    yield put({
      type: FETCH_PROMOTION_BY_ID_SUCCESS,
      payload: data && { promotion: data.promotion, appendCodes: action.payload.appendCodes },
    });
  } catch (e: any) {
    yield put({ type: FETCH_PROMOTION_BY_ID_SUCCESS });
    toast.error(`Cannot fetch promotion: ${get(e, 'message', 'Unknown Error')}`);
    errorHandlerActive(new Error(e));
  }
}

function* fetchPromotionWithChangelogWorker(action: Action<string>) {
  try {
    const { errors, data } = yield call(executeGraphQLQuery, FETCH_PROMOTION_WITH_CHANGELOG_QUERY, {
      id: action.payload,
    });

    if (!isEmpty(errors) || !data?.promotion) {
      toast.error(
        `Cannot fetch promotion changelog: ${errors.map((error: any) => error.message).join(', ')}`,
      );
    }

    yield put({ type: FETCH_PROMOTION_WITH_CHANGELOG_SUCCESS, payload: data?.promotion });
  } catch (e: any) {
    yield put({ type: FETCH_PROMOTION_WITH_CHANGELOG_SUCCESS });
    toast.error(`Cannot fetch promotion changelog: ${get(e, 'message', 'Unknown Error')}`);
    errorHandlerActive(new Error(e));
  }
}

function* fetchPromotionProductsWorker() {
  try {
    const { errors, data } = yield call(executeGraphQLQuery, FETCH_PROMOTION_PRODUCTS_QUERY);

    if (!isEmpty(errors) || !data?.products) {
      toast.error(`Cannot fetch products: ${errors.map((error: any) => error.message).join(', ')}`);
    }

    yield put({ type: FETCH_PROMOTION_PRODUCTS_SUCCESS, payload: data?.products });
  } catch (e: any) {
    yield put({ type: FETCH_PROMOTION_PRODUCTS_SUCCESS });
    toast.error(`Cannot fetch products: ${get(e, 'message', 'Unknown Error')}`);
    errorHandlerActive(new Error(e));
  }
}

function* fetchPromotionLocationsWorker() {
  try {
    const { errors, data } = yield call(executeGraphQLQuery, FETCH_PROMOTION_LOCATIONS_QUERY);

    if (!isEmpty(errors) || !data?.geoHierarchies) {
      toast.error(
        `Cannot fetch locations: ${errors.map((error: any) => error.message).join(', ')}`,
      );
    }

    yield put({ type: FETCH_PROMOTION_LOCATIONS_SUCCESS, payload: data?.geoHierarchies });
  } catch (e: any) {
    yield put({ type: FETCH_PROMOTION_LOCATIONS_SUCCESS });
    toast.error(`Cannot fetch locations: ${get(e, 'message', 'Unknown Error')}`);
    errorHandlerActive(new Error(e));
  }
}

function* createPromotionWorker(action: Action<DataWithCallback<MutationCreatePromotionArgs>>) {
  try {
    const { data, errors } = yield call(executeGraphQLMutation, CREATE_PROMOTION_MUTATION, {
      promotionInput: action.payload.data.promotionInput,
      codePageInfo: smallCodePageInfo,
    });

    if (!isEmpty(errors)) {
      toast.error(
        `Cannot create promotion: ${errors.map((error: any) => error.message).join(', ')}`,
      );
    } else {
      toast.success(`Created promotion successfully`);
      action.payload.successCallback();
    }

    yield put({ type: CREATE_PROMOTION_SUCCESS, payload: data?.createPromotion });
  } catch (e: any) {
    yield put({ type: CREATE_PROMOTION_SUCCESS });
    toast.error(`Create promotion error - ${get(e, 'message', 'Unknown Error')}`);
    errorHandlerActive(new Error(e));
  }
}

function* updatePromotionWorker(action: Action<DataWithCallback<MutationUpdatePromotionArgs>>) {
  try {
    const { data, errors } = yield call(executeGraphQLMutation, UPDATE_PROMOTION_MUTATION, {
      id: action.payload.data.id,
      promotionInput: action.payload.data.promotionInput,
      codePageInfo: smallCodePageInfo,
    });

    if (!isEmpty(errors)) {
      toast.error(
        `Cannot update promotion: ${errors.map((error: any) => error.message).join(', ')}`,
      );
    } else {
      toast.success(`Updated promotion successfully`);
      action.payload.successCallback();
    }

    yield put({ type: UPDATE_PROMOTION_SUCCESS, payload: data?.updatePromotion });
  } catch (e: any) {
    yield put({ type: UPDATE_PROMOTION_SUCCESS });
    toast.error(`Update promotion error - ${get(e, 'message', 'Unknown Error')}`);
    errorHandlerActive(new Error(e));
  }
}

function* updatePromotionsEnabledWorker(
  action: Action<DataWithCallback<MutationUpdatePromotionsEnabledArgs>>,
) {
  try {
    const { data, errors } = yield call(
      executeGraphQLMutation,
      UPDATE_PROMOTIONS_ENABLED_MUTATION,
      {
        promotionsEnabledInput: action.payload.data.promotionsEnabledInput,
        codePageInfo: smallCodePageInfo,
      },
    );

    if (!isEmpty(errors) || !data?.updatePromotionsEnabled) {
      toast.error(
        `Cannot update promotion statuses: ${errors.map((error: any) => error.message).join(', ')}`,
      );
    } else {
      toast.success(`Updated promotion statuses successfully`);
      action.payload.successCallback();
    }

    yield put({ type: UPDATE_PROMOTIONS_ENABLED_SUCCESS, payload: data?.updatePromotionsEnabled });
  } catch (e: any) {
    yield put({ type: UPDATE_PROMOTIONS_ENABLED_SUCCESS });
    toast.error(`Update promotions statuses error - ${get(e, 'message', 'Unknown Error')}`);
    errorHandlerActive(new Error(e));
  }
}

export default function* promotionItemSaga(): any {
  yield all([takeLatest(FETCH_PROMOTION_ITEMS, fetchPromotionItemsWorker)]);
  yield all([takeLatest(FETCH_PROMOTION_BY_ID, fetchPromotionByIdWorker)]);
  yield all([takeLatest(FETCH_PROMOTION_WITH_CHANGELOG, fetchPromotionWithChangelogWorker)]);
  yield all([takeLatest(FETCH_PROMOTION_PRODUCTS, fetchPromotionProductsWorker)]);
  yield all([takeLatest(FETCH_PROMOTION_LOCATIONS, fetchPromotionLocationsWorker)]);
  yield all([takeLatest(CREATE_PROMOTION, createPromotionWorker)]);
  yield all([takeLatest(UPDATE_PROMOTION, updatePromotionWorker)]);
  yield all([takeLatest(UPDATE_PROMOTIONS_ENABLED, updatePromotionsEnabledWorker)]);
}
