import React, { useCallback, useEffect } from 'react';
import { get } from 'lodash';
import { useDispatch } from 'react-redux';
import { withRequestedAuthz } from '@wework/we-auth-react';
import { push } from 'connected-react-router';
import { AuthzProps } from 'utils/constants';
import { MainRegionStyled, OverflowHack } from 'styles/app.styled';
import ROUTES from '../../../utils/routes/routes';
import ApplicationCardComponent from '../../../sharedComponents/homeDashboard/applicationCard';
import {
  ApplicationCardItem,
  ApplicationsDashboardList,
} from '../../../sharedComponents/homeDashboard/homeDashboard.styled';
import EmployeePermissions from '../../../utils/store/permissions';
import { AppSubHeading } from '../../../sharedComponents/homeDashboard/applicationCard.styled';
import BreadcrumbComponent from '../../../sharedComponents/breadcrumb/breadcrumbComponent';

const AdminPanelView = ({ requestedPermissions }: AuthzProps) => {
  const dispatch = useDispatch();

  const redirectToAccessDenied = useCallback(
    () => dispatch(push(ROUTES.ACCESS_DENIED_PAGE)),
    [dispatch],
  );

  useEffect(() => {
    if (!get(requestedPermissions, EmployeePermissions.voyager_system_admin, false)) {
      redirectToAccessDenied();
    }
  }, [requestedPermissions, redirectToAccessDenied]);

  const applicationCardsOnDemand = [
    {
      name: 'Inventory Reconciliation',
      content: ApplicationCardComponent(
        'pricing',
        !get(requestedPermissions, EmployeePermissions.voyager_system_admin, false),
        'Inventory',
        'Reconciliation',
        [
          {
            sectionDesc: 'Manage',
            buttons: [
              {
                label: 'Reconcile Inventory',
                route: ROUTES.INVENTORY_RECONCILIATION,
              },
            ],
          },
        ],
        'Reconcile all the inventory in voyager.',
      ),
    },
    {
      name: 'Inventory Reconciliation by Location',
      content: ApplicationCardComponent(
        'pricing',
        !get(requestedPermissions, EmployeePermissions.voyager_system_admin, false),
        'Inventory',
        'Reconciliation by Location',
        [
          {
            sectionDesc: 'Manage',
            buttons: [
              {
                label: 'Reconcile Inventory by Location',
                route: ROUTES.INVENTORY_RECONCILIATION_BY_LOCATION,
              },
            ],
          },
        ],
        'Reconcile all the inventory by location in voyager.',
      ),
    },
    {
      name: 'Location Reconciliation',
      content: ApplicationCardComponent(
        'pricing',
        !get(requestedPermissions, EmployeePermissions.voyager_system_admin, false),
        'Location',
        'Reconciliation',
        [
          {
            sectionDesc: 'Manage',
            buttons: [
              {
                label: 'Reconcile Locations',
                route: ROUTES.LOCATION_RECONCILIATION,
              },
            ],
          },
        ],
        'Reconcile All Locations in Voyager',
      ),
    },
  ];

  const applicationCardsOnDemandRow2 = [
    {
      name: 'Geo Hierarchy Reconciliation',
      content: ApplicationCardComponent(
        'pricing',
        !get(requestedPermissions, EmployeePermissions.voyager_system_admin, false),
        'Geo Hierarchy',
        'Reconciliation',
        [
          {
            sectionDesc: 'Manage',
            buttons: [
              {
                label: 'Reconcile Geo Hirearchy',
                route: ROUTES.GEO_HIERARCHY_RECONCILIATION,
              },
            ],
          },
        ],
        'Reconcile Geo Hirearchy',
      ),
    },
    {
      name: 'Reset BreakEven',
      content: ApplicationCardComponent(
        'pricing',
        !get(requestedPermissions, EmployeePermissions.voyager_system_admin, false),
        'Reset',
        'BreakEven',
        [
          {
            sectionDesc: 'Manage',
            buttons: [
              {
                label: 'BreakEven Reset',
                route: ROUTES.RESET_BREAKEVEN,
              },
            ],
          },
        ],
        'Reset all the breakeven',
      ),
    },
    {
      name: 'Snowflake Sync Initialization',
      content: ApplicationCardComponent(
        'pricing',
        !get(requestedPermissions, EmployeePermissions.voyager_system_admin, false),
        'Snowflake',
        'Sync Initialization',
        [
          {
            sectionDesc: 'Manage',
            buttons: [
              {
                label: 'Snowflake Sync Initialization',
                route: ROUTES.SNOWFLAKE_SYNC_INITIALIZATION,
              },
            ],
          },
        ],
        'Snowflake Sync Initialization',
      ),
    },
  ];

  const applicationCardsOnDemandRow3 = [
    {
      name: 'Spaceman Prices Consolidation',
      content: ApplicationCardComponent(
        'pricing',
        !get(requestedPermissions, EmployeePermissions.voyager_system_admin, false),
        'Spaceman',
        'Prices Consolidation',
        [
          {
            sectionDesc: 'Manage',
            buttons: [
              {
                label: 'Spaceman Prices Consolidation',
                route: ROUTES.SPACEMAN_PRICES_CONSOLIDATION,
              },
            ],
          },
        ],
        'Spaceman Prices Consolidation',
      ),
    },
    {
      name: 'Price sync attempts view',
      content: ApplicationCardComponent(
        'pricing',
        !get(requestedPermissions, EmployeePermissions.voyager_system_admin, false),
        'Price',
        'sync attempts',
        [
          {
            sectionDesc: 'Manage',
            buttons: [
              {
                label: 'Price sync attempts view',
                route: ROUTES.PRICE_SYNC_ATTEMPTS,
              },
            ],
          },
        ],
        'Price sync attempts view',
      ),
    },
    {
      name: 'Discount sync attempts view',
      content: ApplicationCardComponent(
        'discounts',
        !get(requestedPermissions, EmployeePermissions.voyager_system_admin, false),
        'Discount',
        'sync attempts',
        [
          {
            sectionDesc: 'Manage',
            buttons: [
              {
                label: 'Discount sync attempts view',
                route: ROUTES.DISCOUNT_SYNC_ATTEMPTS,
              },
            ],
          },
        ],
        'Discount sync attempts view',
      ),
    },
  ];

  return (
    <>
      <MainRegionStyled>
        <BreadcrumbComponent />
        <OverflowHack>
          <AppSubHeading>Admin Panel</AppSubHeading>
          <ApplicationsDashboardList key="on-demand-list">
            {applicationCardsOnDemand.map((application: any) => (
              <ApplicationCardItem key={application.name}>
                {application.content}
              </ApplicationCardItem>
            ))}
          </ApplicationsDashboardList>
          <ApplicationsDashboardList key="on-demand-list-row-2">
            {applicationCardsOnDemandRow2.map((application: any) => (
              <ApplicationCardItem key={application.name}>
                {application.content}
              </ApplicationCardItem>
            ))}
          </ApplicationsDashboardList>
          <ApplicationsDashboardList key="on-demand-list-row-3">
            {applicationCardsOnDemandRow3.map((application: any) => (
              <ApplicationCardItem key={application.name}>
                {application.content}
              </ApplicationCardItem>
            ))}
          </ApplicationsDashboardList>
        </OverflowHack>
      </MainRegionStyled>
    </>
  );
};

export default withRequestedAuthz<AuthzProps>({
  permissions: [EmployeePermissions.voyager_system_admin],
})(AdminPanelView);
