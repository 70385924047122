import React, { ReactElement, useCallback } from 'react';
import { Dock } from 'react-dock';
import { useDispatch } from 'react-redux';
import { AgGridReact } from 'ag-grid-react';
import { SET_PROMOTION_WITH_CHANGELOG } from 'app/promotion/store/promotion.ducks';
import { Promotion } from 'generated/voyager/graphql';
import {
  ClosePanelButton,
  PanelBody,
  PanelHeader,
  PanelTitle,
  SidePanelWrapper,
} from '../../../../styles/app.styled';
import { NoHistorySection, PromotionData } from './promotionEditHistorySidePanel.styled';
import { AgGridWrapper } from '../promotionDisplayTable/promotionViewTableAGGrid.styled';
import { columnDefs, gridOptions } from './promotionEditHistoryAGGrid.settings';
import PromotionEditHistoryCreatedAt from './promotionEditHistoryCreatedAt';
import PromotionEditHistoryCreatedBy from './promotionEditHistoryCreatedBy';

interface PromotionEditHistorySidePanelProps {
  promotionWithChangelog: Promotion;
}

function PromotionEditHistorySidePanel(props: PromotionEditHistorySidePanelProps): ReactElement {
  const dispatch = useDispatch();

  const closeSidePanel = useCallback(
    () => dispatch({ type: SET_PROMOTION_WITH_CHANGELOG, payload: undefined }),
    [dispatch],
  );

  return (
    <SidePanelWrapper>
      <Dock
        position="right"
        isVisible
        dimMode="none"
        fluid={false}
        size={props.promotionWithChangelog.changelog.length !== 0 ? 800 : 300}
        zIndex={100}
      >
        <ClosePanelButton type="button" onClick={() => closeSidePanel()} />
        <PanelHeader>
          <PanelTitle>Edits History</PanelTitle>
        </PanelHeader>
        <PanelBody className={'no-footer'}>
          <PromotionData>
            <div>
              <b>Created By: </b>
              <PromotionEditHistoryCreatedBy createdBy={props.promotionWithChangelog.createdBy} />
            </div>
            <div>
              <b>Created At: </b>
              <PromotionEditHistoryCreatedAt
                createdAtString={props.promotionWithChangelog.createdAt}
              />
            </div>
          </PromotionData>
          {props.promotionWithChangelog.changelog.length !== 0 && (
            <AgGridWrapper className="ag-theme-alpine promotion-view-management-grid">
              <AgGridReact
                gridOptions={gridOptions}
                columnDefs={columnDefs(props.promotionWithChangelog.isBulkPromotion)}
                rowData={props.promotionWithChangelog?.changelog ?? []}
                enableBrowserTooltips
              />
            </AgGridWrapper>
          )}
          {props.promotionWithChangelog.changelog.length === 0 && (
            <NoHistorySection>No Edit History</NoHistorySection>
          )}
        </PanelBody>
      </Dock>
    </SidePanelWrapper>
  );
}

export default PromotionEditHistorySidePanel;
