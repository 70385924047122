import * as React from 'react';
import { ReactElement, useState } from 'react';
import { Button } from '@wework/ray2';
import { get } from 'lodash';
import { withRequestedAuthz } from '@wework/we-auth-react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { AuthzProps } from '../../../../../utils/constants';
import { BottomPanelWrapper } from '../../../../../styles/sharedBottomPanel.styled';
import OnDemandCancelFuturePricesConfirm from './confirmationPopup/onDemandCancelFuturePricesConfirm';
import {
  onDemandCancelPricesLoadingSelector,
  onDemandPricingAllItemsSelector,
  onDemandRevertPricesLoadingSelector,
  onDemandSelectedNodesSelector,
} from '../../store/modules/onDemand/onDemandPricing.selector';
import OnDemandRevertWorkingPricesConfirm from './confirmationPopup/onDemandRevertWorkingPricesConfirm';
import OnDemandPreviewWorkingPricesConfirm from './confirmationPopup/onDemandPreviewWorkingPricesConfirm';
import EmployeePermissions from '../../../../../utils/store/permissions';

function PublishOnDemandChangesBottomPanelComponent({
  requestedPermissions,
}: AuthzProps): ReactElement {
  // STATE PROPS
  const selectedNodes = useSelector(onDemandSelectedNodesSelector);
  const allItems = useSelector(onDemandPricingAllItemsSelector);
  const [cancelConfirmationModalOpen, setCancelConfirmationModalOpen] = useState(false);
  const [revertConfirmationModalOpen, setRevertConfirmationModalOpen] = useState(false);
  const cancelLoading = useSelector(onDemandCancelPricesLoadingSelector);
  const revertLoading = useSelector(onDemandRevertPricesLoadingSelector);
  const onDemandPricingRequestIsRunning = cancelLoading || revertLoading;
  const [previewConfirmationModalOpen, setPreviewConfirmationModalOpen] = useState(false);

  const disableFlagRevertChanges =
    !get(requestedPermissions, EmployeePermissions.voyager_on_demand_price_edit, false) ||
    !selectedNodes.filter(node => node.data.workingPrices.length > 0).length ||
    onDemandPricingRequestIsRunning;

  const disableFlagCancelChanges =
    !get(requestedPermissions, EmployeePermissions.voyager_on_demand_price_publish, false) ||
    !selectedNodes.filter(node => node.data.futurePrices.length > 0).length ||
    onDemandPricingRequestIsRunning;

  return (
    <BottomPanelWrapper>
      <Button
        theme={'text'}
        size={'medium'}
        className={cn(
          { 'text-red-80': disableFlagCancelChanges },
          'text-negative btm-panel-btn-space ray-btn-styling-disabled',
        )}
        onClick={() => {
          if (!onDemandPricingRequestIsRunning) {
            setCancelConfirmationModalOpen(true);
          }
        }}
        disabled={!cancelLoading && disableFlagCancelChanges}
        loading={cancelLoading}
      >
        Cancel Future Prices
      </Button>
      <Button
        theme={'text'}
        size={'medium'}
        className={cn(
          { 'text-red-80': disableFlagRevertChanges },
          'text-negative btm-panel-btn-space ray-btn-styling-disabled',
        )}
        onClick={() => {
          if (!onDemandPricingRequestIsRunning) {
            setRevertConfirmationModalOpen(true);
          }
        }}
        disabled={!revertLoading && disableFlagRevertChanges}
        loading={revertLoading}
      >
        Revert Changes
      </Button>
      <Button
        theme={'fill'}
        size={'medium'}
        onClick={() => setPreviewConfirmationModalOpen(true)}
        disabled={
          !allItems.filter(node => node.workingPrices.length > 0).length ||
          onDemandPricingRequestIsRunning
        }
      >
        Preview
      </Button>
      <OnDemandCancelFuturePricesConfirm
        closeModal={() => setCancelConfirmationModalOpen(false)}
        isOpen={cancelConfirmationModalOpen}
      />
      <OnDemandRevertWorkingPricesConfirm
        closeModal={() => setRevertConfirmationModalOpen(false)}
        isOpen={revertConfirmationModalOpen}
      />
      <OnDemandPreviewWorkingPricesConfirm
        closeModal={() => setPreviewConfirmationModalOpen(false)}
        isOpen={previewConfirmationModalOpen}
      />
    </BottomPanelWrapper>
  );
}

export default withRequestedAuthz<AuthzProps>({
  permissions: [
    EmployeePermissions.voyager_on_demand_price_publish,
    EmployeePermissions.voyager_on_demand_price_edit,
  ],
})(PublishOnDemandChangesBottomPanelComponent);
