import * as React from 'react';
import { ReactElement, useState } from 'react';
import cn from 'classnames';
import { Button } from '@wework/ray2';
import { SemanticCOLORS, Tag } from '@wework/dieter-ui';
import { isEqual, unset } from 'lodash';
import { addDays, endOfDay, startOfDay, subDays } from 'date-fns';
import {
  ActiveFilterValues,
  FilterBody,
  FilterHeader,
  FilterItemOption,
  PanelFilterItem,
} from '../../../../styles/app.styled';
import { PromotionFilterProps } from './promotionTableFilterBar';
import { PromotionTableFilter } from '../../store/entities/promotionItem';
import { trackAnalytics } from '../../../../utils/analytics/helpers';

interface QuickFilter {
  filter: Partial<PromotionTableFilter>;
  color: SemanticCOLORS;
  text: string;
}

const DEACTIVATED_FILTER: Partial<PromotionTableFilter> = {
  enabled: false,
  startDateOnOrAfter: undefined,
  startDateOnOrBefore: undefined,
  endDateOnOrAfter: undefined,
  endDateOnOrBefore: undefined,
};

const EXPIRED_FILTER: Partial<PromotionTableFilter> = {
  enabled: true,
  startDateOnOrAfter: undefined,
  startDateOnOrBefore: undefined,
  endDateOnOrAfter: undefined,
  endDateOnOrBefore: endOfDay(subDays(new Date(), 1)),
};

const NOT_ACTIVE_FILTER: Partial<PromotionTableFilter> = {
  enabled: true,
  startDateOnOrAfter: startOfDay(addDays(new Date(), 1)),
  startDateOnOrBefore: undefined,
  endDateOnOrAfter: undefined,
  endDateOnOrBefore: undefined,
};

const ACTIVE_FILTER: Partial<PromotionTableFilter> = {
  enabled: true,
  startDateOnOrAfter: undefined,
  startDateOnOrBefore: endOfDay(new Date()),
  endDateOnOrAfter: startOfDay(new Date()),
  endDateOnOrBefore: undefined,
};

const QUICK_FILTERS: QuickFilter[] = [
  { filter: DEACTIVATED_FILTER, color: 'red', text: 'Deactivated' },
  { filter: EXPIRED_FILTER, color: 'yellow', text: 'Expired' },
  { filter: NOT_ACTIVE_FILTER, color: 'blue', text: 'Not Active' },
  { filter: ACTIVE_FILTER, color: 'green', text: 'Active' },
];

const QUICK_FILTER_FIELDS: Array<keyof PromotionTableFilter> = [
  'enabled',
  'startDateOnOrAfter',
  'startDateOnOrBefore',
  'endDateOnOrAfter',
  'endDateOnOrBefore',
];

function PromotionQuickFilter({ filter, setFilter }: PromotionFilterProps): ReactElement {
  const [accordionOpen, setAccordionOpen] = useState<boolean>(true);

  const isQuickFilterEqual = (other: Partial<PromotionTableFilter>): boolean =>
    QUICK_FILTER_FIELDS.every(field => isEqual(filter[field], other[field]));

  const getFilterWithoutStatusFields = (): PromotionTableFilter => {
    const newFilter = { ...filter };
    QUICK_FILTER_FIELDS.forEach(field => unset(newFilter, field));
    return newFilter;
  };

  const trackQuickFilterPromotions = (filterName: string) =>
    trackAnalytics(`Promotion - Quick Filter Promotion used`, {
      workflow: `Promotion - Filter promotion`,
      object_type: 'button',
      object_name: `Quick Filter promotion`,
      object_value: filterName,
    });

  return (
    <PanelFilterItem className={cn({ open: accordionOpen, closed: !accordionOpen })}>
      <FilterHeader className="filter-header">
        <button type="button" onClick={() => setAccordionOpen(!accordionOpen)}>
          <span>{'Quick filters'}</span>
          <i className="toggle-accordion-icon" />
        </button>
        {!accordionOpen &&
          QUICK_FILTERS.map(
            quickFilter =>
              isQuickFilterEqual(quickFilter.filter) && (
                <ActiveFilterValues>
                  <Tag color={quickFilter.color}>{quickFilter.text}</Tag>
                </ActiveFilterValues>
              ),
          )}
      </FilterHeader>
      <FilterBody className="filter-body">
        <FilterItemOption>
          {QUICK_FILTERS.map(quickFilter => (
            <Button
              key={quickFilter.text}
              theme={'text'}
              onClick={() => {
                if (isQuickFilterEqual(quickFilter.filter)) {
                  setFilter(getFilterWithoutStatusFields());
                } else {
                  setFilter({ ...filter, ...quickFilter.filter });
                  trackQuickFilterPromotions(quickFilter.text);
                }
              }}
            >
              <Tag
                size={'large'}
                color={isQuickFilterEqual(quickFilter.filter) ? quickFilter.color : 'grey'}
              >
                {quickFilter.text}
              </Tag>
            </Button>
          ))}
        </FilterItemOption>
      </FilterBody>
    </PanelFilterItem>
  );
}

export default PromotionQuickFilter;
