import { Action, handleActions } from 'redux-actions';
import { findIndex, sortBy, uniqBy } from 'lodash';
import { RowNode } from 'ag-grid-community';
import initialState, {
  initialStandardPricingItems,
} from '../../../../../../utils/store/initialState';
import {
  StandardPricingItemsSubset,
  StandardPricingPagedItem,
} from '../../entities/standardPricingItems';
import { standardPricingItemsSubsetSelector } from './standardPricingItems.selector';
import { createRDXConstant } from '../../../../../../utils/store/store.constants';
import {
  WorkingPrice,
  WorkingPriceInput,
  WorkingPriceResetResult,
} from '../../../../../../generated/voyager/graphql';

// Action Constants
export const FETCH_STANDARD_PRICING_ITEMS = createRDXConstant('FETCH_STANDARD_PRICING_ITEMS');
export const STOP_FETCH_STANDARD_PRICING_ITEMS = createRDXConstant(
  'STOP_FETCH_STANDARD_PRICING_ITEMS',
);
export const FETCH_STANDARD_PRICING_ITEMS_SUCCESS = createRDXConstant(
  'FETCH_STANDARD_PRICING_ITEMS_SUCCESS',
);
export const START_STANDARD_PRICING_BATCH_FETCH = createRDXConstant(
  'START_STANDARD_PRICING_BATCH_FETCH',
);
export const END_STANDARD_PRICING_BATCH_FETCH = createRDXConstant(
  'END_STANDARD_PRICING_BATCH_FETCH',
);
export const CLEAR_STANDARD_PRICING_STORE = createRDXConstant('CLEAR_STANDARD_PRICING_STORE');
export const ENABLE_EDIT_MODE_STANDARD_PRICING = createRDXConstant(
  'ENABLE_EDIT_MODE_STANDARD_PRICING',
);
export const DISABLE_EDIT_MODE_STANDARD_PRICING = createRDXConstant(
  'DISABLE_EDIT_MODE_STANDARD_PRICING',
);
export const SET_SELECTED_NODES_STANDARD_PRICING = createRDXConstant(
  'SET_SELECTED_NODES_STANDARD_PRICING',
);

export const CANCEL_FUTURE_PRICES = createRDXConstant('CANCEL_FUTURE_PRICES');
export const CANCEL_FUTURE_PRICES_SUCCESS = createRDXConstant('CANCEL_FUTURE_PRICES_SUCCESS');

export const REVERT_WORKING_PRICES = createRDXConstant('REVERT_WORKING_PRICES');
export const REVERT_WORKING_PRICES_UPDATE_ITEMS = createRDXConstant(
  'REVERT_WORKING_PRICES_UPDATE_ITEMS',
);
export const REVERT_WORKING_PRICES_SUCCESS = createRDXConstant('REVERT_WORKING_PRICES_SUCCESS');

// Action - for saving current working prices.
export const CREATE_STANDARD_WORKING_PRICES = createRDXConstant('CREATE_STANDARD_WORKING_PRICES');
// Action - working price change success update.
export const CREATE_STANDARD_WORKING_PRICE_SUCCESS = createRDXConstant(
  'CREATE_STANDARD_WORKING_PRICE_SUCCESS',
);
export const CREATE_STANDARD_WORKING_PRICE_ERROR = createRDXConstant(
  'CREATE_STANDARD_WORKING_PRICE_ERROR',
);

export const SET_LIST_PRICE_MODE_STANDARD_PRICING = createRDXConstant(
  'SET_LIST_PRICE_MODE_STANDARD_PRICING',
);

const workingPriceModifierHelper = (
  allItems: StandardPricingPagedItem[],
  workingPrices: WorkingPriceInput[],
): StandardPricingPagedItem[] => {
  workingPrices.forEach((eachWorkingPrice: WorkingPriceInput) => {
    const idx = findIndex(
      allItems,
      (eachItem: StandardPricingPagedItem) => eachItem?.id === eachWorkingPrice.priceableItemId,
    );

    if (idx > -1) {
      const workingPrice = {
        ...allItems[idx]?.workingPrice,
        price: eachWorkingPrice.price,
        lastChangeReason: eachWorkingPrice.lastChangeReason,
      } as WorkingPrice;

      allItems.splice(idx, 1, {
        ...allItems[idx],
        workingPrice,
      });
    }
  });

  return allItems;
};

// Reducer
export const standardPricingItemsReducer = handleActions<StandardPricingItemsSubset, any>(
  {
    // Reducer for fetching all the priceable items in batches.
    [FETCH_STANDARD_PRICING_ITEMS]: (state: StandardPricingItemsSubset) => ({
      ...state,
    }),
    [STOP_FETCH_STANDARD_PRICING_ITEMS]: (state: StandardPricingItemsSubset) => ({
      ...state,
    }),
    [FETCH_STANDARD_PRICING_ITEMS_SUCCESS]: (
      state: StandardPricingItemsSubset,
      action: Action<StandardPricingPagedItem[]>,
    ) => {
      const oldItems = standardPricingItemsSubsetSelector(state);
      const items = [...oldItems, ...action.payload];

      return {
        ...state,
        allItems: sortBy(uniqBy(items, 'id'), 'page'),
      };
    },

    // Reducer for batch fetch working prices from gateway.
    [START_STANDARD_PRICING_BATCH_FETCH]: (state: StandardPricingItemsSubset) => ({
      ...state,
      batchFetch: true,
    }),
    [END_STANDARD_PRICING_BATCH_FETCH]: (state: StandardPricingItemsSubset) => ({
      ...state,
      batchFetch: false,
    }),

    [CLEAR_STANDARD_PRICING_STORE]: (state: StandardPricingItemsSubset) => ({
      ...state,
      ...initialStandardPricingItems,
      selectedNodes: [...state.selectedNodes],
    }),

    [ENABLE_EDIT_MODE_STANDARD_PRICING]: (state: StandardPricingItemsSubset) => ({
      ...state,
      editMode: true,
    }),
    [DISABLE_EDIT_MODE_STANDARD_PRICING]: (state: StandardPricingItemsSubset) => ({
      ...state,
      editMode: false,
    }),

    [SET_SELECTED_NODES_STANDARD_PRICING]: (
      state: StandardPricingItemsSubset,
      action: Action<RowNode[]>,
    ) => ({
      ...state,
      selectedNodes: action.payload,
    }),

    [CANCEL_FUTURE_PRICES]: (state: StandardPricingItemsSubset) => ({
      ...state,
      cancelFuturePricesLoading: true,
    }),
    [CANCEL_FUTURE_PRICES_SUCCESS]: (
      state: StandardPricingItemsSubset,
      action: Action<string[]>,
    ) => ({
      ...state,
      allItems: standardPricingItemsSubsetSelector(state).map(item =>
        action.payload.includes(item.id)
          ? {
              ...item,
              prices: [],
            }
          : item,
      ),
      cancelFuturePricesLoading: false,
    }),

    [REVERT_WORKING_PRICES]: (state: StandardPricingItemsSubset) => ({
      ...state,
      revertWorkingPricesLoading: true,
    }),
    [REVERT_WORKING_PRICES_UPDATE_ITEMS]: (
      state: StandardPricingItemsSubset,
      action: Action<WorkingPriceResetResult[]>,
    ) => ({
      ...state,
      allItems: standardPricingItemsSubsetSelector(state).map(item => ({
        ...item,
        workingPrice: action.payload.find(
          result => result.priceableItemId === item.id && result.isReset,
        )
          ? null
          : item.workingPrice,
      })),
    }),
    [REVERT_WORKING_PRICES_SUCCESS]: (state: StandardPricingItemsSubset) => ({
      ...state,
      revertWorkingPricesLoading: false,
    }),

    // Reducer for saving current working price batch.
    [CREATE_STANDARD_WORKING_PRICES]: (state: StandardPricingItemsSubset) => ({
      ...state,
      batchEditLoading: true,
    }),

    // Reducer for saving current working price batch.
    [CREATE_STANDARD_WORKING_PRICE_SUCCESS]: (
      state: StandardPricingItemsSubset,
      action: Action<WorkingPriceInput[]>,
    ) => {
      let standardPriceableItems = standardPricingItemsSubsetSelector(state) ?? [];
      standardPriceableItems = workingPriceModifierHelper(standardPriceableItems, action.payload);

      return {
        ...state,
        allItems: [...standardPriceableItems],
        batchEditLoading: false,
      };
    },
    [CREATE_STANDARD_WORKING_PRICE_ERROR]: (state: StandardPricingItemsSubset) => ({
      ...state,
      batchEditLoading: false,
    }),
    [SET_LIST_PRICE_MODE_STANDARD_PRICING]: (
      state: StandardPricingItemsSubset,
      action: Action<boolean>,
    ) => ({
      ...state,
      isListPriceMode: action.payload,
    }),
  },
  initialState.standardPricingItems,
);
