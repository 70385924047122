const ROUTES = {
  ROOT: '/',
  LOGIN: '/login',
  LOGOUT: '/logout',
  CALLBACK: '/callback',
  HOME: '/home',
  PRICING: '/pricing',
  UPLOAD_PRICING_FROM_FILE: '/pricing/upload',
  ON_DEMAND_PRICING: '/pricing/ondemand',
  DISCOUNT: '/discount',
  UPLOAD_DISCOUNTS_FROM_FILE: '/discount/upload',
  BREAKEVEN: '/breakeven',
  STANDARD_PRICING: '/pricing/standard',
  DISCOUNT_TABLEVIEW: '/discount/tableview',
  DISCOUNT_CURVE_MANAGEMENT: '/discount/curve',
  PUBLISH_SUMMARY: '/pricing/publishsummary',
  PUBLISH_DISCOUNT_SUMMARY: '/discount/publishdiscountsummary',
  PUBLISH_ON_DEMAND_SUMMARY: '/ondemand/publishondemandsummary',
  BREAKEVEN_TABLEVIEW: '/breakeven/tableview',
  BREAKEVEN_SUMMARY: '/breakeven/publishsummary',
  ERROR: '/error',
  ACCESS_DENIED: '/access-denied',
  ACCESS_DENIED_PAGE: '/no-permission',
  ACTION_ITEMS: '/action-items',
  ADMIN_PANEL: '/admin-panel',
  INVENTORY_RECONCILIATION: '/admin-panel/inventory-reconciliation',
  INVENTORY_RECONCILIATION_BY_LOCATION: '/admin-panel/inventory-reconciliation-by-location',
  LOCATION_RECONCILIATION: '/admin-panel/location-reconciliation',
  GEO_HIERARCHY_RECONCILIATION: '/admin-panel/geo-hierarchy-reconciliation',
  RESET_BREAKEVEN: '/admin-panel/reset-breakeven',
  SNOWFLAKE_SYNC_INITIALIZATION: '/admin-panel/snowflake-sync-initialization',
  SPACEMAN_PRICES_CONSOLIDATION: '/admin-panel/spaceman-prices-consolidation',
  PRICE_SYNC_ATTEMPTS: '/admin-panel/price-sync-attempts',
  DISCOUNT_SYNC_ATTEMPTS: '/admin-panel/discount-sync-attempts',
  PROMOTION_TABLEVIEW: '/promotion',
  PROMOTION_VIEW_PAGE: '/promotion/view',
  PROMOTION_CREATE_PAGE: '/promotion/create',
  PROMOTION_UPDATE_PAGE: '/promotion/update',
};

export default ROUTES;
