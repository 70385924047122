import styled from 'styled-components';
import { blue } from '../../styles/colors';

const TextLink = styled.span`
  line-height: 12px;
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  color: ${blue};
  text-decoration: underline;
  margin-bottom: 8px;
  margin-top: 2px;
  &.margin-left-space {
    margin-left: 5px;
  }
  &.modified-size {
    line-height: 32px;
    font-size: 12px;
  }
  &.margin-left {
    float: right;
    margin-left: 25px;
  }
  &.hide {
    display: none;
  }
  &.sample {
    line-height: 14px;
    padding-top: 10px;
  }
  &.on-demand-margin {
    margin-left: 25px;
    margin-top: 20px;
  }
  &.no-margin {
    margin: 0;
  }
`;

export default TextLink;
