import { RowNode } from 'ag-grid-community';
import { StoreInterface } from '../../../../../utils/store/store.interface';
import { PageInfo } from '../../../../../generated/voyager/graphql';
import { DiscountSyncAttempt, DiscountSyncAttemptFilter } from '../../entities/discountSyncAttempt';

const discountSyncAttemptsSelector = (state: StoreInterface) => state?.discountRePublishing;

export const discountSyncAttemptsFilterSelector = (
  state: StoreInterface,
): DiscountSyncAttemptFilter => discountSyncAttemptsSelector(state)?.discountSyncAttemptFilter;

export const discountSyncAttemptsPageInfoSelector = (state: StoreInterface): PageInfo =>
  discountSyncAttemptsSelector(state)?.pageInfo;

export const discountSyncAttemptItemsSelector = (
  state: StoreInterface,
): DiscountSyncAttempt[] | undefined => discountSyncAttemptsSelector(state)?.allDiscountSyncAttempt;

export const discountSyncAttemptIsLoadingSelector = (state: StoreInterface): boolean =>
  discountSyncAttemptsSelector(state)?.isLoading;

export const discountSyncAttemptSelectedNodesSelector = (state: StoreInterface): RowNode[] =>
  discountSyncAttemptsSelector(state)?.selectedNodes;
