import React, { ReactElement } from 'react';
import { Popup } from 'semantic-ui-react';
import { InfoTooltipContent } from '../../../../sharedComponents/tooltip/infoTooltip.styled';
import { toTitleCase } from '../../../../utils/helpers';

interface PromotionEditHistoryCreatedByProps {
  createdBy: string;
}

function PromotionEditHistoryCreatedBy({
  createdBy,
}: PromotionEditHistoryCreatedByProps): ReactElement {
  return (
    <Popup
      basic
      trigger={<span>{toTitleCase(createdBy)}</span>}
      on="hover"
      className={`info-tooltip`}
    >
      <Popup.Content>
        <InfoTooltipContent>{createdBy}</InfoTooltipContent>
      </Popup.Content>
    </Popup>
  );
}

export default PromotionEditHistoryCreatedBy;
