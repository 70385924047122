import { RowNode } from 'ag-grid-community';
import { StoreInterface } from '../../../../../utils/store/store.interface';
import { PriceSyncAttempt, PriceSyncAttemptFilter } from '../../entities/priceSyncAttempt';
import { PageInfo } from '../../../../../generated/voyager/graphql';

const priceSyncAttemptsSelector = (state: StoreInterface) => state?.priceRePublishing;

export const priceSyncAttemptsFilterSelector = (state: StoreInterface): PriceSyncAttemptFilter =>
  priceSyncAttemptsSelector(state)?.priceSyncAttemptFilter;

export const priceSyncAttemptsPageInfoSelector = (state: StoreInterface): PageInfo =>
  priceSyncAttemptsSelector(state)?.pageInfo;

export const priceSyncAttemptItemsSelector = (
  state: StoreInterface,
): PriceSyncAttempt[] | undefined => priceSyncAttemptsSelector(state)?.allPriceSyncAttempt;

export const priceSyncAttemptIsLoadingSelector = (state: StoreInterface): boolean =>
  priceSyncAttemptsSelector(state)?.isLoading;

export const priceSyncAttemptSelectedNodesSelector = (state: StoreInterface): RowNode[] =>
  priceSyncAttemptsSelector(state)?.selectedNodes;
